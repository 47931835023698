import axios from 'axios';
import auth from '@/api/services/auth';
import router from '@/router/';
import jwtDecode from 'jwt-decode';
import i18n from '@/i18n';
import config from '@/config';
import { setItemToLocalStorage } from '@/helpers';
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem('token') || null,
    error: null,
    rememberMe: null,
    user: null,
    isLoading: true,
    currencies: localStorage.getItem('currencies') ? JSON.parse(localStorage.getItem('currencies')) : null,
  },
  getters: {
    isLogged(state) {
      return !!state.token;
    },
    userType(state) {
      //  none | standard | creator | admin
      if (state.user) {
        return state.user.role;
      }
      if (localStorage.getItem('userType')) {
        return localStorage.getItem('userType');
      }
      return 'none';
    },
    isDataFilled({ user }) {
      const standardFields = [user.email, user.firstName, user.lastName, user.street, user.postalCode, user.city, user.country, user.birthday];
      const companyFields = [user.companyName, user.companyCity, user.companyStreet, user.companyPostalCode, user.companyCountry, user.companyTaxId];
      return [...standardFields, ...(user.isCompany ? companyFields : [])].every(Boolean);
    },
  },
  mutations: {
    SIGN_IN(state, payload) {
      state.token = payload.token;
      state.rememberMe = payload.rememberMe;
      router.push('/dashboard');

      if (state.rememberMe) {
        setItemToLocalStorage('token', payload.token);
      }
    },
    SIGN_UP(state, payload) {
      state.token = payload.token;
      setItemToLocalStorage('token', payload.token);
      router.push('/dashboard');
    },
    SET_USER_DATA(state, payload) {
      setItemToLocalStorage('userType', payload.role);
      state.user = payload;
    },
    LOGOUT(state) {
      router.push('/');
      state.user = null;
      state.token = null;
      localStorage.removeItem('token');
      localStorage.removeItem('userType');
    },
    SET_CURRENCIES(state, payload) {
      state.currencies = payload;
      if (localStorage.getItem('currencies')) {
        const { success } = JSON.parse(localStorage.getItem('currencies'));
        if (!success) {
          localStorage.removeItem('currencies');
        }
      }
      if (payload.success) {
        setItemToLocalStorage('currencies', JSON.stringify(payload));
      }
    },
  },
  actions: {
    async signIn({ commit, dispatch }, { email, password, rememberMe }) {
      try {
        const { data } = await auth.login({ email, password });

        commit('SIGN_IN', {
          token: data.token,
          rememberMe,
        });

        dispatch('fetchUserData');
        dispatch('notification/spawn', {
          type: 'success',
          title: i18n.t('auth.success.title'),
          text: i18n.t('auth.success.text'),
        }, { root: true });

        return true;
      } catch (err) {
        dispatch('notification/spawn', {
          errorMessage: err.response.data.message,
          type: 'error',
        }, { root: true });

        throw new Error('error!');
      }
    },
    async signUp({ commit, dispatch }, payload) {
      try {
        const { data } = await auth.register(payload);

        commit('SIGN_UP', data);
        dispatch('fetchUserData');
        Vue.analytics.fbq.event('CompleteRegistration');
        dispatch('notification/spawn', {
          type: 'success',
          title: i18n.t('auth.success.title'),
          text: i18n.t('auth.success.text'),
        }, { root: true });

        return true;
      } catch (err) {
        dispatch('notification/spawn', {
          errorMessage: err.response.data.message,
          type: 'error',
        }, { root: true });

        throw new Error('error!');
      }
    },
    async fetchUserData({ commit, state }) {
      state.isLoading = true;
      try {
        const { data } = await auth.getUserData();
        commit('SET_USER_DATA', { ...data, birthday: data.birthday === '0001-01-01T00:00:00Z' ? '' : data.birthday });
      } catch (err) {
        console.log(err);
      } finally {
        state.isLoading = false;
      }
    },
    async updateUserData({ commit, dispatch }, payload) {
      try {
        const { data } = await auth.updateUserData(payload);
        dispatch('notification/spawn', {
          type: 'success',
          title: i18n.t('accountCreation.form.success'),
        }, { root: true });
        commit('SET_USER_DATA', data);
        return { isError: false };
      } catch (err) {
        dispatch('notification/spawn', {
          errorMessage: err.response.data.message,
          type: 'error',
        }, { root: true });
        return { isError: true };
      }
    },
    logout({ commit, dispatch }) {
      commit('LOGOUT');
      dispatch('notification/spawn', {
        type: 'success',
        title: i18n.t('auth.logout.title'),
        text: i18n.t('auth.logout.text'),
      }, { root: true });
    },
    checkSession({ commit, state }) {
      const decodedToken = jwtDecode(state.token);
      const currentTimestamp = Math.floor(Date.now() / 1000);

      if (currentTimestamp >= decodedToken.exp) {
        commit('LOGOUT');
      }
    },
    async calculateCurrencies({ commit }) {
      try {
        const { data } = await axios.get(`${config.apiServerURL}exchange-rates`);
        if (!data.success) {
          commit('SET_CURRENCIES', { rates: { EUR: 1 } });
          return;
        }
        commit('SET_CURRENCIES', { ...data, rates: { ...data.rates, [data.base]: 1 } });
      } catch (err) {
        console.log(err);
      }
    },
    async resetPassword({ state, dispatch }, payload) {
      try {
        await auth.resetUserPassword(payload);
        state.error = null;
      } catch (err) {
        state.error = err;
        dispatch('notification/spawn', {
          type: 'error',
          title: i18n.t('resetPassword.sendError.title'),
          text: i18n.t('resetPassword.sendError.text'),
        }, { root: true });
      }
    },
    async setNewPassword({ state, dispatch }, payload) {
      try {
        await auth.setUserPassword(payload);
        state.error = null;
      } catch (err) {
        state.error = err;
        dispatch('notification/spawn', {
          type: 'error',
          title: i18n.t('resetPassword.setError.title'),
          text: i18n.t('resetPassword.setError.text'),
        }, { root: true });
      }
    },
    async setNewUserPassword({ state, dispatch }, payload) {
      try {
        await auth.setNewUserPassword(payload);
        state.error = null;
      } catch (err) {
        state.error = err;
        dispatch('notification/spawn', {
          type: 'error',
          title: i18n.t('resetPassword.setError.title'),
          text: i18n.t('resetPassword.setError.text'),
        }, { root: true });
      }
    },
  },
};
