<template>
  <div class="video-loader">
    <div class="video-loader__inner">
      <loader />
      <p class="video-loader__text">
        {{ $t('loading.text') }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoLoader',
};
</script>

<style lang="scss" src="./VideoLoader.scss" />
