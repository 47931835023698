import { render, staticRenderFns } from "./CheckoutItems.vue?vue&type=template&id=4f7caf79&scoped=true&"
import script from "./CheckoutItems.vue?vue&type=script&lang=js&"
export * from "./CheckoutItems.vue?vue&type=script&lang=js&"
import style0 from "./CheckoutItems.scss?vue&type=style&index=0&id=4f7caf79&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.10.1_cache-loader@4.1.0_css-loader@3.6.0_webpack@4.46.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f7caf79",
  null
  
)

export default component.exports