<template>
  <li
    class="lesson-tile"
    :class="[
      isActive ? 'lesson-tile--active' : false,
    ]"
  >
    <svg
      class="lesson-tile__icon"
      v-if="isLocked"
      xmlns="http://www.w3.org/2000/svg"
      width="13.67"
      height="16.919"
      viewBox="0 0 13.67 16.919"
    >
      <g id="Group_36" data-name="Group 36" transform="translate(0.8 0.8)">
        <rect id="Rectangle_28" data-name="Rectangle 28" width="12.07" height="10.213" rx="2" transform="translate(0 5.106)" fill="rgba(0,0,0,0)" stroke="#858585" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"/>
        <path id="Rectangle_29" data-name="Rectangle 29" d="M2.553,0h0A2.553,2.553,0,0,1,5.106,2.553V5.106a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V2.553A2.553,2.553,0,0,1,2.553,0Z" transform="translate(3.482)" fill="rgba(0,0,0,0)" stroke="#858585" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"/>
        <line id="Line_7" data-name="Line 7" y2="1.393" transform="translate(6.035 9.516)" fill="none" stroke="#858585" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"/>
      </g>
    </svg>

    <play-button
      size="small"
      class="lesson-tile__play"
      border
      v-else
      @click="setLesson()"
    />

    <h6 class="lesson-tile__name">
      {{ lesson.name }}

     <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.217"
      height="6.861"
      viewBox="0 0 9.217 6.861"
      class="lesson-tile__name-icon"
      v-if="lesson.isCompleted"
    >
      <path
        id="comment-verify"
        d="M18.418,13.765,14.062,18.13l-1.7-1.713a.925.925,0,0,0-1.308,1.308l2.358,2.358a.921.921,0,0,0,1.308,0l5.01-5.01a.925.925,0,1,0-1.308-1.308Z"
        transform="translate(-10.78 -13.494)"
        fill="#48efa7"
      />
    </svg>

    </h6>

    <span class="lesson-tile__line" />

    <p class="lesson-tile__length">
      {{ videoLength }}
    </p>
  </li>
</template>

<script>
import { secondsToTime } from '@/helpers/time';

export default {
  name: 'LessonTile',
  props: {
    isLocked: {
      type: Boolean,
      required: true,
    },
    lesson: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    videoLength() {
      return secondsToTime(this.lesson.videoDuration, true);
    },
  },
  methods: {
    setLesson() {
      this.$emit('setLesson', this.lesson);
    },
  },
};
</script>

<style lang="scss" src="./LessonTile.scss" />
