<template>
  <div
    class="base-tabs"
    :class="type === 'filled' && 'base-tabs--filled'"
    role="tablist"
    :aria-label="label"
  >
    <button
      class="base-tabs__item"
      :class="[
        isActive(tab) && 'base-tabs__item--active',
        type === 'filled' ? 'base-tabs__item--filled' : 'base-tabs__item--normal'
      ]"
      role="tab"
      ref="tabs"
      :aria-selected="isActive(tab)"
      :tabindex="isActive(tab) || !value ? 0 : -1"
      v-for="tab in tabs"
      :key="tab.key"
      @click="setTab(tab)"
      @focus="setTab(tab)"
      @keydown="switchTab"
    >
      {{ tab.label }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseTabs',
  props: {
    value: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: 'true',
      default: '',
    },
    tabs: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    setTab(tab) {
      this.$emit('input', tab.key);
    },
    isActive(tab) {
      return tab.key === this.value;
    },
    switchTab(event) {
      const keys = ['ArrowLeft', 'ArrowRight', 'End', 'Home'];
      if (!keys.includes(event.key)) {
        return;
      }
      event.preventDefault();
      let index = this.tabs.findIndex((tab) => tab.key === this.value);
      switch (event.key) {
        case 'ArrowLeft':
          if (index > 0) {
            index -= 1;
          }
          break;
        case 'ArrowRight':
          if (index < this.tabs.length - 1) {
            index += 1;
          }
          break;
        case 'End':
          index = this.tabs.length - 1;
          break;
        case 'Home':
          index = 0;
          break;

        default:
          break;
      }
      this.setTab(this.tabs[index]);
      this.$refs.tabs[index].focus();
    },
  },
  computed: {},
  created() {},
};
</script>

<style lang="scss" src="./BaseTabs.scss" />
