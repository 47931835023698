import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import cart from '@/store/modules/cart';
import language from '@/store/modules/language';
import ui from '@/store/modules/ui';
import wishlist from '@/store/modules/wishlist';
import auth from '@/store/modules/auth';
import notification from '@/store/modules/notification';
import payment from '@/store/modules/payment';
import privacy from '@/store/modules/privacy';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    cart,
    language,
    ui,
    wishlist,
    auth,
    notification,
    privacy,
    payment,
  },
});
