<template>
  <section class="checkout-items">
    <base-card>
      <transition-group class="checkout-items__list" tag="ul"
                        name="moving-fade-alerts" v-if="!isCartEmpty">
        <checkout-item
          v-for="(item, index) in cartList"
          :key="item.id"
          :item="{ ...item, index }"
          :promoCode="promoCode"
          class="checkout-items__item"
        />
      </transition-group>
      <div v-else class="checkout-items__empty">
        <p class="checkout-items__empty-text">{{ $t("checkout.emptyCart") }}</p>
        <base-button to="/courses"> {{
            $t("checkout.discoverCourses")
          }}
        </base-button>
      </div>
    </base-card>
    <base-button
      type="text"
      class="checkout-items__clear"
      @click="$store.dispatch('cart/clearCart')"
      v-if="!isCartEmpty"
    >
      {{ $t("checkout.clearCart") }}
    </base-button>
    <base-card v-if="!isCartEmpty && extraList.length && !extraList.every(v => cartList.some(j => j.id === v.id))">
      <transition-group class="checkout-items__list" tag="ul"
                          name="moving-fade-alerts" >
          <extra-item
            v-for="(item, index) in extraList.filter(v => !cartList.map(o => o.id).includes(v.id))"
            :key="item.id"
            :item="{ ...item, index }"
            :cartList=cartList
            class="checkout-items__item"
          />
        </transition-group>
    </base-card>
  </section>
</template>

<script>

export default {
  name: 'CheckoutItems',
  props: {
    cartList: {
      type: Array,
      required: true,
    },
    extraList: {
      type: Array,
      required: true,
    },
    isCartEmpty: {
      type: Boolean,
      required: true,
    },
    promoCode: {
      type: String,
      required: false,
    },
  },
};
</script>
<style lang="scss" scoped src="./CheckoutItems.scss"/>
