<template>
  <div class="add-step-info">
    <add-step-language
      class="add-step-info__language"
      :course="course"
      @update-data="updateData($event)"
      :disabled="disabled"
    />

    <validation-observer
      class="add-step-info"
      tag="form"
      ref="form"
      @submit.prevent="validateForm"
    >
      <base-card class="add-step-info__card">
        <h2 class="add-step-info__heading">
          {{ $t("addCourse.course_info") }}
        </h2>
        <div class="add-step-info__basics">
          <upload-thumbnail
            :image="course.imageId"
            @upload="updateData({ imageId: $event })"
            :label="$t('addCourse.image')"
            :name="$t('addCourse.image')"
            :placeholder="$t('addCourse.image')"
            :disabled="disabled"
            rules="required"
            class="add-step-info__upload"
            :requirements="[
              $t('requirements.format', { format: '.jpg, .png' })
            ]"
            courseThumbnail
          />
          <base-input
            :value="course.name"
            :disabled="disabled"
            @input="updateData({ name: $event })"
            :label="$t('addCourse.title')"
            rules="required"
            name="title"
            :placeholder="$t('addCourse.title')"
            class="add-step-info__input"
            thin
          />
          <base-input
            :value="course.price"
            @input="updateData({ price: $event })"
            :label="`${$t('addCourse.price')} (EUR)`"
            rules="required|max_value:10000000|min_value:5"
            name="price"
            type="number"
            placeholder="00.00 EUR"
            class="add-step-info__input"
            thin
          />
          <base-select
            :helpfulText="$t('addCourse.select_level')"
            :text="$t('addCourse.select_level')"
            ref="select"
            :value="course.difficulty"
            :disabled="disabled"
            @change="updateData({ difficulty: $event })"
            :name="$t('addCourse.forms.lvl')"
            rules="required"
            :label="$t('addCourse.select_level')"
            class="add-step-info__input"
            thin
          >
            <template v-slot:items>
              <base-option
                v-for="option in levels"
                :key="option.key"
                :value="option.key"
                :label="option.label"
              >
                {{ option.label }}
              </base-option>
            </template>
          </base-select>
        </div>
        <div class="add-step-info__field">
          <h3 class="add-step-info__field-label">
            {{ $t("addCourse.categories") }}
          </h3>

          <base-accordion
            v-model="accordions"
            class="add-step-info__accordion"
            separated
            :disabled="disabled"
          >
            <base-accordion-item
              name="methods"
              class="add-step-info__accordion-item"
            >
              <div slot="header" class="add-step-info__accordion-header">
                <p class="add-step-info__accordion-header-label">
                  {{ $t("addCourse.method") }}
                </p>
                <span class="add-step-info__accordion-header-picked">
                  {{ methodsLabel }}
                </span>
              </div>
              <categories-selection
                :course="course"
                :categories="categoriesMethods.list"
                categoryName="manicureMethods"
                :value="course.manicureMethods"
                @input="updateData({ manicureMethods: $event })"
                class="add-step-info__accordion-content"
              />
            </base-accordion-item>

            <base-accordion-item
              name="shape"
              class="add-step-info__accordion-item"
            >
              <div slot="header" class="add-step-info__accordion-header">
                <p class="add-step-info__accordion-header-label">
                  {{ $t("addCourse.shape") }}
                </p>
                <span class="add-step-info__accordion-header-picked">
                  {{ shapeLabel }}
                </span>
              </div>
              <categories-selection
                :course="course"
                :categories="categoriesShapes.list"
                categoryName="manicureShapes"
                :value="course.manicureShapes"
                @input="updateData({ manicureShapes: $event })"
                class="add-step-info__accordion-content"
              />
            </base-accordion-item>

            <base-accordion-item
              name="art"
              class="add-step-info__accordion-item"
            >
              <div slot="header" class="add-step-info__accordion-header">
                <p class="add-step-info__accordion-header-label">
                  {{ $t("addCourse.art") }}
                </p>
                <span class="add-step-info__accordion-header-picked">
                  {{ artLabel }}
                </span>
              </div>
              <categories-selection
                :course="course"
                :categories="categoriesArt.list"
                categoryName="manicureNailArts"
                :value="course.manicureNailArts"
                @input="updateData({ manicureNailArts: $event })"
                class="add-step-info__accordion-content"
              />
            </base-accordion-item>
          </base-accordion>
        </div>

        <base-textarea
          :value="course.description"
          @input="updateData({ description: $event })"
          :label="$t('addCourse.description')"
          :name="$t('addCourse.description')"
          :disabled="disabled"
          :placeholder="$t('addCourse.description')"
          rules="required"
          class="add-step-info__textarea"
        />

        <div class="add-step-info__field">
          <h3 class="add-step-info__field-label">
            {{ $t("addCourse.will_learn") }}
          </h3>
          <course-features
            color="primary"
            :initFeatures="course.willLearn"
            :value="course.willLearn"
            :disabled="disabled"
            @input="updateData({ willLearn: $event })"
          />
        </div>

        <div class="add-step-info__field">
          <h3 class="add-step-info__field-label">
            {{ $t("addCourse.will_need") }}
          </h3>
          <course-features
            :initFeatures="course.needs"
            color="secondary"
            :value="course.needs"
            :disabled="disabled"
            @input="updateData({ needs: $event })"
          />
        </div>
      </base-card>
    </validation-observer>
  </div>
</template>

<script>
import Categories from '@/mixins/Categories';

export default {
  name: 'AddStepInfo',
  mixins: [Categories()],
  data: () => ({
    accordions: ['methods', 'shape', 'art'],
  }),
  props: {
    course: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    methodsLabel() {
      return `(${this.$t('addCourse.selected')} : ${
        this.course.manicureMethods.length
      })`;
    },
    shapeLabel() {
      return `(${this.$t('addCourse.selected')} : ${
        this.course.manicureShapes.length
      })`;
    },
    artLabel() {
      return `(${this.$t('addCourse.selected')} : ${
        this.course.manicureNailArts.length
      })`;
    },
    levels() {
      return [
        {
          key: 1,
          label: this.$t('levels.1'),
        },
        {
          key: 2,
          label: this.$t('levels.2'),
        },
        {
          key: 3,
          label: this.$t('levels.3'),
        },
        {
          key: 4,
          label: this.$t('levels.4'),
        },
      ];
    },
  },
  methods: {
    updateData(data) {
      this.$emit('update-data', data);
    },
    async validateForm() {
      const isValid = await this.$refs.form.validate();
      return isValid;
    },
  },
  mounted() {
    this.$emit('mount');
  },
};
</script>

<style lang="scss" src="./AddStepInfo.scss" />
