<template>
  <img
    class="user-avatar"
    :class="size && `user-avatar--${size}`"
    :src="localImage || imageLink"
    alt=""
    v-if="user.avatarId"
  />
  <div
    class="user-avatar user-avatar--placeholder"
    :class="size && `user-avatar--${size}`"
    v-else
  >
    {{ initials }}
  </div>
</template>

<script>
import { getImageLink } from '@/helpers/index';

export default {
  name: 'UserAvatar',
  props: {
    user: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: '',
    },
    localImage: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    initials() {
      return (
        this.user.firstName.charAt(0).toUpperCase()
        + this.user.lastName.charAt(0).toUpperCase()
      );
    },
    imageLink() {
      return getImageLink(this.user.avatarId);
    },
  },
};
</script>
<style lang="scss" scoped src="./UserAvatar.scss" />
