<template>
  <h2
    class="base-heading"
    :class="[
      align === 'center' ? 'base-heading--center' : false,
      align === 'left' ? 'base-heading--left' : false,
      bold === 'semiBold' ? 'base-heading--semi-bold' : false,
      bold === 'bold' ? 'base-heading--bold' : false,
    ]"
  >
    <slot></slot>
  </h2>
</template>

<script>
export default {
  name: 'BaseHeading',
  props: {
    align: {
      type: String,
      required: false,
      default: 'left',
    },
    bold: {
      type: String,
      required: false,
      default: 'normal',
    },
  },
};
</script>

<style lang="scss" src="./BaseHeading.scss" />
