export default {
  apiServerURL: process.env.VUE_APP_API_URL,
  assetServerURL: process.env.VUE_APP_ASSETS_URL,
  checkoutURL: process.env.VUE_APP_CHECKOUT_URL,
  zendeskURL: process.env.VUE_APP_ZENDESK_URL,
  baseURL: process.env.VUE_APP_BASE_URL,
  facebookPixelId: process.env.VUE_APP_FACEBOOK_PIXEL_ID,
  sentryDNS: process.env.VUE_APP_SENTRY_DNS,
  sentryEnvironment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
  commissionValue: 39,
  commissionFraction: 0.61,
  maxFilterPrice: 150,
  exchangeKEY: '3f22cc85476fda8c853b9d72f3ed9c2c',
};
