<template>
  <base-card class="checkout-summary">
    <h2 class="checkout-summary__heading">
      {{ $t('checkout.summary') }}
    </h2>
    <div class="checkout-summary__row">
      <p class="checkout-summary__value-label">
        {{ $t('checkout.cartValue') }}
      </p>
      <base-price :price="cartTotal"/>
    </div>
    <p class="checkout-summary__total-label">
      {{ $t('checkout.totalPayment') }}
    </p>
    <base-price v-if="promoCode==null" :price="cartTotal"
                class="checkout-summary__total-price"
                :setPromoCode="setPromoCode"/>
    <base-price v-if="promoCode!=null&&promoCode.toLowerCase()!=='edunails10'&&promoCode.toLowerCase()!=='marcelina50'"
                :price="cartTotal" :promoPrice="cartTotal*0.95"
                class="checkout-summary__total-price"
                :setPromoCode="setPromoCode"/>
    <base-price v-if="promoCode!=null&&promoCode.toLowerCase()==='edunails10'"
                :price="cartTotal" :promoPrice="cartTotal*0.9"
                class="checkout-summary__total-price"
                :setPromoCode="setPromoCode"/>
    <checkout-promo :setPromoCode="setPromoCodeLocal"/>
    <validation-observer slim v-slot="{ handleSubmit }">
      <form class="sign-up-form" @submit.prevent="handleSubmit(onSubmit)">
        <base-checkbox
          required
          v-model="isAgreement"
          class="checkout-summary__checkbox"
          :name="$t('signUp.formFields.agreements')"
        >
          <p>
            {{ $t('checkout.agreement') }}
            <base-button
              type="link"
              to="/returns-policy"
              class="checkout-summary__link"
            >
              {{ $t('checkout.link') }}
            </base-button>
          </p>
        </base-checkbox>
      </form>
    </validation-observer>
    <!-- <img
      src="/assets/icons/sucure-online-payment.png"
      alt="Secure Online Payment"
      class="checkout-summary__secure-badge"
    /> -->
    <div id="paypal-button-container"></div>
    <!-- <paypal-buttons :on-approve="onApprove" :create-order="createOrder"></paypal-buttons> -->
  </base-card>
</template>

<script>
import { mapGetters } from 'vuex';
import * as Sentry from '@sentry/vue';
import { loadScript } from '@paypal/paypal-js';

export default {
  name: 'CheckoutSummary',
  data: () => ({
    isAgreement: false,
    promoCode: null,
    transactionId: '',
    currency: 'EUR',
  }),
  computed: {
    ...mapGetters({
      cartTotal: 'cart/cartTotal',
    }),
  },
  props: {
    isCartEmpty: {
      type: Boolean,
      required: true,
    },
    setPromoCode: {},
  },
  async mounted() {
    const clientID = process.env.VUE_APP_PAYPAL_ID;

    await loadScript({
      'client-id': clientID,
      'disable-funding': 'p24,blik,giropay,ideal,mercadopago,mybank,sofort,venmo',
      currency: this.currency,
    })
      .then((paypal) => {
        paypal
          .Buttons({
            createOrder: this.createOrder,
            onApprove: this.onApprove,
            onError: this.onButtonsError,
          })
          .render('#paypal-button-container')
          .catch((error) => {
            Sentry.captureException(error);
          });
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  },
  methods: {
    setTransactionId(id) {
      this.transactionId = id;
    },
    onSubmit() {
      this.$emit('click');
    },
    setPromoCodeLocal(code) {
      this.promoCode = code;
      this.$store.dispatch('cart/setPromoCode', code);
      this.setPromoCode(code);
    },
    async createOrder() {
      this.$emit('click');
      await this.waitForTransactionId();
      return this.transactionId;
    },
    onApprove() {
      window.location.href = `/payment-status?token=${this.transactionId}`;
    },
    onButtonsError(error) {
      Sentry.captureException(error);
    },
    async waitForTransactionId() {
      return new Promise((resolve) => {
        const timer = setInterval(() => {
          if (this.transactionId.length > 0) {
            resolve();
            clearInterval(timer);
          }
        }, 100);
      });
    },
  },
};
</script>
<style lang="scss" scoped src="./CheckoutSummary.scss"/>
