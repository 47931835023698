<template>
  <button
    class="base-search-icon"
    aria-label="Open Search"
    title="Open Search"
    @click="openSearch()"
  >
    <img
      class="base-search-icon__image"
      alt=""
      src="/assets/icons/search-dark.svg"
    />
  </button>
</template>

<script>

export default {
  name: 'BaseSearchIcon',
  methods: {
    openSearch() {
      this.$store.dispatch('ui/setSearch', true);
    },
  },
};
</script>

<style lang="scss" src="./BaseSearchIcon.scss" />
