<template>
  <base-button type="text" class="like-button" @click="likeCourse">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12.409 13.431"
      class="like-button__icon"
    >
      <g id="Group_52" data-name="Group 52" transform="translate(0.75 0.802)">
        <rect
          id="Rectangle_40"
          data-name="Rectangle 40"
          width="2.785"
          height="7.59"
          rx="1.393"
          transform="translate(0 4.005)"
          stroke-width="1.5"
          stroke="#fc8c9d"
          stroke-linecap="round"
          stroke-linejoin="round"
          :fill="hasLike ? '#fc8c9d' : 'rgba(0,0,0,0)'"
        />
        <path
          id="Path_39"
          data-name="Path 39"
          d="M365.839,657.3a1.255,1.255,0,0,0,.81.429l3.871.444a1.864,1.864,0,0,0,2.055-1.577l.877-4.367a1.276,1.276,0,0,0-1.2-1.526l-1.691-.062a.36.36,0,0,1-.308-.521l1.052-2.1a1.178,1.178,0,0,0-.482-1.558h0a1.177,1.177,0,0,0-1.448.245l-4.014,4.481V656a1.985,1.985,0,0,0,.481,1.3Z"
          transform="translate(-362.573 -646.317)"
          :fill="hasLike ? '#fc8c9d' : 'rgba(0,0,0,0)'"
          stroke="#fc8c9d"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
      </g>
    </svg>
    <span class="like-button__text">
      {{ hasLike ? $t("dashboard.user.liked") : $t("dashboard.user.like") }}
    </span>
  </base-button>
</template>

<script>
import course from '@/api/services/course';

export default {
  name: 'LikeButton',
  data: () => ({
    hasLike: false,
  }),
  props: {
    courseId: {
      type: String,
      required: true,
    },
    likes: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    async likeCourse() {
      this.hasLike = !this.hasLike;
      await course.setLike(this.courseId, this.hasLike);
    },
  },

  created() {
    this.hasLike = this.likes;
  },
};
</script>
<style lang="scss" scoped src="./LikeButton.scss" />
