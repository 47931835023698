<template>
  <button
    class="base-close-icon"
    :aria-label="$t('close')"
    :title="$t('close')"
    @click="$emit('click')"
    @focus="$emit('focus')"
  >
    <img
      class="base-close-icon__image"
      alt=""
      src="/assets/icons/close-big.svg"
    />
  </button>
</template>

<script>

export default {
  name: 'BaseChartIcon',
};
</script>

<style lang="scss" src="./BaseCloseIcon.scss" />
