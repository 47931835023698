<template>
  <validation-provider
    v-slot="{ errors, valid }"
    :rules="rules"
    :name="name"
    tag="div"
    class="base-date-picker"
    :vid="vid"
    mode="eager"
    ref="provider"
  >
    <div class="base-date-picker__wrapper">
      <template>
        <div>
          <div
            class="base-date-picker__label-wrapper"
            :class="
              centeredLabel ? 'base-date-picker__label-wrapper--center' : ''
            "
          >
            <label v-if="label" class="base-date-picker__label" :for="name">
              {{ label }}
            </label>
            <p v-if="rules === ''" class="base-date-picker__optional">
              {{ $t("optional") }}
            </p>
          </div>
          <date-picker
            :input-class="`base-date-picker__inner ${errors[0] ? 'base-date-picker__inner--error' : ''}`"
            :value="value"
            @input="changeDate"
            :disabled-date="adultField ? adultDate : undefined"
            :default-value="adultField ? day18yearsAgo : ''"
            format="DD-MM-YYYY"
            value-type="YYYY-MM-DD"
          >
            <template slot="icon-calendar">
              <transition name="page-fade" mode="out-in">
                <img
                  src="/assets/icons/exclamation.svg"
                  :alt="$t('warning')"
                  class="base-date-picker__icon"
                  v-if="errors[0]"
                />
                <svg-tick
                  fill="#48EFA7"
                  class="base-date-picker__icon base-date-picker__icon--tick"
                  v-else-if="valid && rules"
                />
              </transition>
            </template>
          </date-picker>

          <transition
            name="page-fade"
            @enter="$emit('error', name)"
            @leave="$emit('clear-error', name)"
          >
            <span class="base-date-picker__error" v-if="errors[0]">
              {{ errors[0] }}
            </span>
          </transition>
        </div>
      </template>
    </div>
  </validation-provider>
</template>

<script>
import dayjs from 'dayjs';
import { mapState } from 'vuex';

export default {
  name: 'BaseDatePicker',
  data() {
    return {
      date: '',
      inputIcon: `/assets/icons/${this.icon}-slash.svg`,
    };
  },
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    rules: {
      type: [String, Object],
      required: false,
      default: '',
    },
    name: {
      type: String,
      required: false,
      default: 'Field',
    },
    adultField: {
      type: Boolean,
      required: false,
      default: true,
    },
    value: {
      type: [String, Number, Date],
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    placeholder: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
    vid: {
      type: String,
      required: false,
      default: '',
    },
    centeredLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState({
      currentLocale: (state) => state.language.currentLanguage,
    }),
    day18yearsAgo() {
      return dayjs()
        .subtract(18, 'year')
        .format('YYYY-MM-DD');
    },
  },
  methods: {
    changeDate(date) {
      this.$emit('input', date);
    },
    openDatePicker(e, toggle) {
      toggle({ ref: e.target });
    },
    adultDate(date) {
      return date > new Date(this.day18yearsAgo);
    },
  },
  watch: {
    async currentLocale(locale) {
      await require(`vue2-datepicker/locale/${locale.key}`);
    },
  },
  async created() {
    await require(`vue2-datepicker/locale/${this.currentLocale.key}`);
  },
};
</script>

<style lang="scss" src="./BaseDatePicker.scss" />
