<template>
  <section class="base-video-player">
    <video
      class="base-video-player__inner video-js vjs-16-9"
      ref="videoPlayer"
      id="video"
      :poster="poster ? poster : false"
    />
  </section>
</template>

<script>
import 'video.js/dist/video-js.min.css';
import videojs from 'video.js';

export default {
  name: 'BaseVideoPlayer',
  data: () => ({
    options: {
      controls: true,
      textTrackSettings: false,
    },
  }),
  props: {
    poster: {
      type: String,
      required: false,
    },
    source: {
      type: String,
      required: true,
    },
    rounded: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  mounted() {
    this.options.sources = [{
      label: 'Default',
      src: this.source,
      type: 'video/mp4',
    }];

    this.player = videojs(this.$refs.videoPlayer, this.options);

    // Set events for watching player
    this.player.on('loadstart', () => {
      this.$emit('loadstart');
    });

    this.player.on('ended', () => {
      this.$emit('ended');
    });
  },
};
</script>

<style lang="scss" src="./BaseVideoPlayer.scss" />
