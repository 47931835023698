<template>
  <div class="dashboard-banner" :class="`dashboard-banner--${userType}`">
    <div class="dashboard-banner__left">
      <h2 class="dashboard-banner__heading">
        {{
          userType === "creator"
            ? $t("dashboard.author.addCourse.text")
            : $t("dashboard.user.banner.text")
        }}
        <strong>{{
          userType === "creator"
            ? $t("dashboard.author.addCourse.strong")
            : $t("dashboard.user.banner.strong")
        }}</strong>
      </h2>
      <base-button
        type="contrasting"
        :to="userType === 'creator' ? '/add-course' : '/courses'"
      >
        {{
          userType === "creator"
            ? $t("dashboard.author.addCourseButton")
            : $t("dashboard.user.discover")
        }}
      </base-button>
    </div>
    <img
      src="/assets/images/dashboard-author.png"
      alt=""
      class="dashboard-banner__image"
      v-if="userType === 'creator'"
    />
    <img
      src="/assets/images/dashboard-user.png"
      alt=""
      class="dashboard-banner__image dashboard-banner__image--standard"
      v-else
    />
    <img
      src="/assets/images/dots-white.svg"
      alt=""
      class="dashboard-banner__dots"
      v-if="userType === 'creator'"
    />
    <img
      src="/assets/images/dots.svg"
      alt=""
      class="dashboard-banner__dots dashboard-banner__dots--standard"
      v-else
    />
  </div>
</template>

<script>
export default {
  name: 'DashboardBanner',
  props: {
    userType: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped src="./DashboardBanner.scss" />
