<template>
  <div class="main-percentage-loading">
    <div class="main-percentage-loading__inner">
      <transition name="page-fade" mode="out-in">
        <progress-bar
          :percent="percent"
          v-if="percent < 100"
          class="main-percentage-loading__bar"
          big
        />
        <div v-else>
          <loader />
          <p class="main-percentage-loading__text">
            {{ $t("loading.finish") }}
          </p>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainPercentageLoading',
  data: () => ({}),
  props: {
    percent: {
      type: Number,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped src="./MainPercentageLoading.scss" />
