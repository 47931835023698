var render = function render(){var _vm=this,_c=_vm._self._c;return _c('upload-file-logic',{attrs:{"image":_vm.image,"rules":_vm.rules,"name":_vm.name},on:{"upload":function($event){return _vm.$emit('upload', $event)},"error":function($event){return _vm.$emit('error')},"clear-error":function($event){return _vm.$emit('clear-error')}},scopedSlots:_vm._u([{key:"default",fn:function({
    handleUpload,
    isDragOver,
    computedImage,
    handleDelete,
    errors,
    isError
  }){return [_c('div',{staticClass:"upload-file",class:[_vm.rectangle ? 'upload-file--rectangle' : '']},[_c('label',{staticClass:"upload-file__label",class:[
        computedImage ? 'upload-file__label--uploaded' : '',
        isDragOver ? 'upload-file__label--is-over' : '',
        _vm.rectangle ? 'upload-file__label--rectangle' : ''
      ],attrs:{"for":"image","title":_vm.helpfulText}},[(!computedImage)?_c('div',{staticClass:"upload-file__icon"},[_c('img',{staticClass:"upload-file__icon-inner",attrs:{"src":'/assets/icons/upload-alt.svg',"alt":_vm.$t('uploadFile.addFiles'),"title":_vm.$t('uploadFile.addFiles')}})]):_vm._e(),_c('input',{staticClass:"upload-file__upload",attrs:{"type":"file","id":"image","name":"image","accept":"image/png, image/jpeg","disabled":_vm.disabled},on:{"change":function($event){return handleUpload($event.target)}}}),(!computedImage)?_c('p',{staticClass:"upload-file__help",class:isDragOver ? 'upload-file__help--is-over' : ''},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()]),_c('transition',{attrs:{"mode":"out-in","name":"page-fade"}},[(computedImage)?_c('img',{staticClass:"upload-file__image",attrs:{"src":computedImage,"alt":""}}):_vm._e()]),_c('transition',{attrs:{"mode":"out-in","name":"page-fade"}},[(computedImage && !_vm.disabled)?_c('base-button',{staticClass:"upload-file__delete",attrs:{"type":"text"},on:{"click":handleDelete}},[_vm._v(" "+_vm._s(_vm.$t("uploadFile.label"))+" ")]):_vm._e()],1),_c('transition',{attrs:{"mode":"out-in","name":"page-fade"}},[(isError)?_c('p',{staticClass:"upload-file__error"},[_vm._v(" "+_vm._s(_vm.$t("uploadFile.error"))+" ")]):_vm._e()]),(!_vm.disabled)?_c('div',{staticClass:"upload-file__requirements"},_vm._l((_vm.requirements),function(requirement){return _c('p',{key:requirement,staticClass:"upload-file__requirement"},[_vm._v(" "+_vm._s(requirement)+" ")])}),0):_vm._e(),_c('transition',{attrs:{"name":"page-fade"},on:{"enter":function($event){return _vm.$emit('error', _vm.name)},"leave":function($event){return _vm.$emit('clear-error', _vm.name)}}},[(errors[0])?_c('span',{staticClass:"base-input__error upload-file__required"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }