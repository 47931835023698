<template>
  <img class="base-image" v-lazy="src" :alt="alt" v-if="lazyLoading" />
  <img class="base-image" :src="src" :alt="alt" v-else />
</template>

<script>
export default {
  name: 'BaseImage',
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: false,
      default: '',
    },
    lazyLoading: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped src="./BaseImage.scss" />
