import payment from '@/api/services/payment';

export default {
  namespaced: true,
  state: {
    status: '',
  },

  mutations: {
    SET_STATUS(state, payload) {
      state.status = payload;
    },
  },
  actions: {
    async checkStatus({ commit }, payload) {
      let status;
      try {
        status = await payment.checkStatus(payload);
      } catch (err) {
        status = 'error';
      }
      commit('SET_STATUS', status);
    },
  },
};
