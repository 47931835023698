<template>
  <validation-observer slim v-slot="{ handleSubmit }">
    <form class="sign-up-form" @submit.prevent="handleSubmit(onSubmit)">
      <div class="sign-up-form__name">
        <div>
          <base-input
            class="sign-up-form__input sign-up-form__input-name"
            v-model="user.firstName"
            :label="firstName.label"
            :placeholder="firstName.placeholder"
            rules="required"
            :name="firstName.label"
          />
        </div>

        <div>
          <base-input
            class="sign-up-form__input sign-up-form__input-name"
            v-model="user.lastName"
            :label="lastName.label"
            :placeholder="lastName.placeholder"
            rules="required"
            :name="lastName.label"
          />
        </div>
      </div>

      <base-input
        class="sign-up-form__input"
        v-model="user.email"
        :label="email.label"
        :placeholder="email.placeholder"
        rules="email|required|confirmed:confirmation"
        :name="email.label"
      />

      <base-input
        class="sign-up-form__input"
        v-model="user.reenterEmail"
        :label="reenterEmail.label"
        :placeholder="reenterEmail.placeholder"
        :name="reenterEmail.label"
        vid="confirmation"
      />

      <base-input
        class="sign-up-form__input"
        v-model="user.password"
        :label="password.label"
        :placeholder="password.placeholder"
        rules="required|min:8"
        :name="password.label"
        type="password"
        icon="eye"
      />

      <base-checkbox
        required
        v-model="isChecked"
        :name="agreements"
        center
        class="sign-up-form__checkbox"
      >
        <p>
          {{ terms[0] }}
          <a
            href="/terms-and-conditions-edunails.pdf"
            target="_blank"
            rel="noreferrer noopener"
            class="sign-up-form__native-link"
            >{{ privacyPolicy }}</a
          >
          {{ terms[1] }}
        </p>
      </base-checkbox>

      <base-checkbox v-model="isNewsletter" class="sign-up-form__checkbox">
        <p class="sign-up-form__newsletter">
          {{ newsletter }}
          <a
            href="/privacy-policy-edunails.pdf"
            target="_blank"
            rel="noreferrer noopener"
            class="sign-up-form__native-link"
          >
            {{ link }}
          </a>
        </p>
      </base-checkbox>

      <base-button
        nativeType="submit"
        class="sign-up-form__submit"
        :class="centerButton && 'sign-up-form__submit--center'"
        :is-loading="isLoading"
      >
        {{ submit }}
      </base-button>
    </form>
  </validation-observer>
</template>

<script>
import marketing from '@/api/services/marketing';

export default {
  name: 'SignUpForm',
  data: () => ({
    user: {
      firstName: '',
      lastName: '',
      email: '',
      reenterEmail: '',
      password: '',
    },
    isChecked: false,
    isNewsletter: false,
    isLoading: false,
  }),
  props: {
    firstName: {
      type: Object,
      required: true,
    },
    lastName: {
      type: Object,
      required: true,
    },
    email: {
      type: Object,
      required: true,
    },
    reenterEmail: {
      type: Object,
      required: true,
    },
    password: {
      type: Object,
      required: true,
    },
    terms: {
      type: Array,
      required: true,
    },
    adult: {
      type: String,
      required: true,
    },
    age: {
      type: String,
      required: true,
    },
    agreements: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    newsletter: {
      type: String,
      required: true,
    },
    submit: {
      type: String,
      required: true,
    },
    privacyPolicy: {
      type: String,
      required: true,
    },
    centerButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;

      try {
        await this.$store.dispatch('auth/signUp', {
          ...this.user,
          email: this.user.email.toLowerCase(),
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoading = false;
      }

      if (this.isChecked) {
        marketing.subscribe(this.user.email.toLowerCase());
      }
    },
  },
};
</script>
<style lang="scss" scoped src="./SignUpForm.scss" />
