<template>
  <div id="app">
    <!-- Navigation components -->
    <main-navigation @toggle-navbar="isNavigationVisible = $event"/>
    <mobile-navigation/>

    <transition name="page-fade" mode="out-in">
      <mobile-menu v-if="mobileMenuStatus"/>
    </transition>

    <!-- Main ROOT  -->
    <main
      class="app__page"
      :class="[
        isDashboard && 'app__page--dashboard',
        hasEbookBanner && 'app__page--with-ebook',
      ]"
    >
      <transition
        name="page-fade"
        mode="out-in"
        @before-enter="beforeEnter"
        appear
      >
        <router-view/>
      </transition>
    </main>

    <!-- Main modules -->
    <transition name="page-fade" mode="out-in">
      <main-cart v-if="cartStatus"/>
    </transition>
    <transition name="page-fade" mode="out-in">
      <main-wishlist v-if="wishlistStatus"/>
    </transition>
    <transition name="page-fade" mode="out-in">
      <base-overlay v-if="overlayStatus"/>
    </transition>
    <transition name="page-fade" mode="out-in">
      <main-preloader v-if="preloaderStatus"/>
    </transition>
    <transition name="page-fade" mode="out-in">
      <main-lightbox v-if="lightboxStatus"/>
    </transition>

    <!-- Sub modules -->
    <main-notifications/>
    <main-search/>
    <main-footer/>
    <main-cookies/>
    <portal-target name="modal"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { isDashboard } from '@/helpers/';

export default {
  name: 'DefaultAppLayout',
  metaInfo: {
    title: 'Edunails Training Platform',
    htmlAttrs: {
      lang: localStorage.getItem('localeKey') ? localStorage.getItem('localeKey') : 'en',
    },
    meta: [
      {
        name: 'description',
        content: 'International Training Platform for Nail Stylists. Innovative, smart online learning.',
      },
    ],
  },
  data: () => ({
    isNavigationVisible: true,
  }),
  provide() {
    return {
      isNavigationVisible: () => this.isNavigationVisible,
    };
  },
  computed: {
    ...mapState({
      cartStatus: (state) => state.ui.cartStatus,
      mobileMenuStatus: (state) => state.ui.mobileMenuStatus,
      wishlistStatus: (state) => state.ui.wishlistStatus,
      overlayStatus: (state) => state.ui.overlayStatus,
      preloaderStatus: (state) => state.ui.preloaderStatus,
      isLogged: (state) => state.auth.token,
      hasOpenedEbook: (state) => state.privacy.hasOpenedEbook,
      lightboxStatus: (state) => state.ui.lightboxStatus,
    }),
    isDashboard() {
      return isDashboard(this.$route.path);
    },
    hasEbookBanner() {
      return !this.hasOpenedEbook && this.isLogged;
    },
  },
  methods: {
    beforeEnter(view) {
      // Courses page has own scroll logic
      if (view.classList.contains('courses')) {
        return;
      }
      this.$root.$emit('scrollBeforeEnter');
    },
  },
  created() {
    const locale = navigator.language || navigator.userLanguage;
    this.$store.dispatch('language/autoDetectLanguage', locale);
    this.$store.dispatch('cart/getCartItems');
    this.$store.dispatch('wishlist/getWishlistItems');
    this.$store.dispatch('auth/calculateCurrencies');

    // Fetch user data when user is logged & token is correct
    if (this.isLogged) {
      this.$store.dispatch('auth/checkSession');
      this.$store.dispatch('auth/fetchUserData');
    }
  },
};
</script>
