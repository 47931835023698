<template>
  <base-card
    class="base-notification-box"
    :class="[
      size === 'medium' ? 'base-notification-box--medium' : false,
      size === 'big' ? 'base-notification-box--big' : false,
      size === 'fluid' ? 'base-notification-box--fluid' : false,
      center ? 'base-notification-box--center' : false,
      withoutShadow ? 'base-notification-box--without-shadow' : false
    ]"
  >
    <div class="base-notification-box__inner">
      <img
        class="base-notification-box__icon"
        alt=""
        :src="`/assets/icons/notification-${mode}.svg`"
      />
      <h4 class="base-notification-box__heading" v-html="heading" />
      <p class="base-notification-box__text">
        {{ text }}
      </p>
      <div class="base-notification-box__content">
        <slot></slot>
      </div>
      <slot name="actions"></slot>
    </div>
  </base-card>
</template>

<script>
export default {
  name: 'BaseNotificationBox',
  props: {
    mode: {
      type: String,
      required: false,
      default: 'success',
    },
    size: {
      type: String,
      required: false,
      default: 'medium',
    },
    heading: {
      type: [String, Number],
      required: false,
    },
    text: {
      type: [String, Number],
      required: false,
    },
    center: {
      type: Boolean,
      required: false,
      default: true,
    },
    withoutShadow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" src="./BaseNotificationBox.scss" />
