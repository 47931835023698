import Vue from 'vue';
import i18n from '@/i18n';

// Main core libraries setup
import {
  ValidationProvider,
  ValidationObserver,
  extend,
} from 'vee-validate/dist/vee-validate.full';
import PortalVue from 'portal-vue';
import DatePicker from 'vue2-datepicker';
import VueLazyload from 'vue-lazyload';
import VueMeta from 'vue-meta';
import Draggable from 'vuedraggable';
import VueFacebookPixel from 'vue-analytics-facebook-pixel';
import config from '@/config';
import PrismicVue from '@prismicio/vue';
import * as Sentry from '@sentry/vue';
import VueCarousel from 'vue-carousel';
import '@/helpers/filters';
import 'vue2-datepicker/index.css';

Vue.use(VueCarousel);
Vue.use(VueMeta);
Vue.use(VueLazyload, {
  preLoad: 2,
});
Vue.use(PortalVue);
Vue.use(VueFacebookPixel);
Vue.use(PrismicVue, {
  endpoint: 'https://edunails.cdn.prismic.io/api/v2',
});
Vue.analytics.fbq.init(config.facebookPixelId);
Vue.analytics.fbq.event('PageView');

// Sentry
Sentry.init({
  Vue,
  dsn: config.sentryDNS,
  tracesSampleRate: 0.05,
  environment: config.sentryEnvironment,
  ignoreErrors: [/^Cannot read properties of undefined/, /^null is not an object/, /^Cannot read properties of null/, /^AbortError/, /^AJAX {"message":"token_invalid"}/],
  beforeSend(event) {
    return null;
  }
});

// Dynamic import of our base components
const components = require.context('@/components/', true);
components.keys().map((component) => {
  if (!component.endsWith('.vue') && !component.startsWith('Base')) {
    return;
  }

  const componentName = component.split('/').pop().replace(/\.vue$/, '');
  Vue.component(componentName, components(component).default);
});

// Components from libraries
Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);
Vue.component('date-picker', DatePicker);
Vue.component('draggable', Draggable);

const dateFormat = {
  message: (field) => i18n.t('dateFormat', { field }),
  validate: (value) => /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(value),
};

extend('date_format', dateFormat);

// Check for promo code in link
if (window.location.pathname.toString().startsWith('/a/')) {
  const promoCode = window.location.pathname.toString().replace('/a/', '');
  localStorage.setItem('promo_code', promoCode);
  window.location.href = '/';
}
