<template>
  <div class="main-search">
    <base-modal
      v-model="searchStatus"
      size="big"
      :footer="false"
      :title="$t('nav.search-heading')"
    >
      <div slot="content">
        <validation-observer
          slot="content"
          class="main-search__content"
          tag="form"
          ref="form"
          @submit.prevent="validateForm()"
        >
          <base-input
            v-model="query"
            rules="required"
            name="search"
            :placeholder="$t('nav.search')"
            class="main-search__content-input"
          />
          <base-button
            type="icon"
            icon="search"
            class="main-search__content-button"
            @click="validateForm()"
          />
        </validation-observer>
      </div>
    </base-modal>
  </div>
</template>

<script>
export default {
  name: 'MainSearch',
  data: () => ({
    query: '',
  }),
  methods: {
    async validateForm() {
      const status = await this.$refs.form.validate();
      status ? this.makeSearch() : false;
    },
    makeSearch() {
      this.$router.push(`/courses?q=${this.query}`);
      this.$store.dispatch('ui/setSearch', false);
      this.query = '';
    },
  },
  computed: {
    searchStatus: {
      get() {
        return this.$store.state.ui.searchStatus;
      },
      set(value) {
        this.$store.dispatch('ui/setSearch', value);
        !value ? this.query = '' : false;
      },
    },
  },
};
</script>

<style lang="scss" src="./MainSearch.scss" />
