<template>
  <div
    class="lesson-playlist"
    :class="lessons.length === 0 && 'lesson-playlist--empty'"
  >
    <button
      v-if="lessons.length === 0"
      class="lesson-playlist__button"
      @click="$emit('add-lesson')"
      type="button"
    >
      <img src="/assets/icons/plus.svg" alt="" class="lesson-playlist__icon"/>
      <p class="lesson-playlist__text">
        {{ $t("addCourse.add-video.addLessons") }}
      </p>
    </button>
    <div v-else>
      <h3 class="lesson-playlist__heading">{{ $t("addCourse.playlist") }}</h3>
      <draggable
        v-model="lessons"
        v-bind="dragOptions"
        @start="drag = true"
        @end="drag = false"
      >
        <transition-group
          type="transition"
          class="edit-profile__portfolio"
          tag="ul"
          :name="!drag ? 'edit-profile__flip-list' : null"
          style="display: block;"
        >
          <li
            v-for="lesson in lessons"
            :key="lesson.id"
            class="lesson-playlist__lesson"
          >
            <button
              type="button"
              class="lesson-playlist__lesson-button"
              :class="
                activeLesson &&
                  activeLesson.id === lesson.id &&
                  'lesson-playlist__lesson-button--active'
              "
              @click="$emit('change-active', lesson)"
            >
              <img
                src="/assets/icons/list.svg"
                alt=""
                class="lesson-playlist__drag-handler"
              />
              <p class="lesson-playlist__lesson-name">
                {{ lesson.name || $t("addCourse.untitledLesson") }}
              </p>
              <div class="lesson-playlist__lesson-icons">
                <button
                  type="button"
                  class="lesson-playlist__lesson-delete"
                  @click.stop="lessonToDelete = lesson"
                  v-if="!disabled"
                >
                  <img
                    src="/assets/icons/trash.svg"
                    alt=""
                    class="lesson-playlist__lesson-delete-icon"
                  />
                </button>
                <img
                  src="/assets/icons/pencil-new.svg"
                  alt=""
                  class="lesson-playlist__lesson-pencil"
                />
              </div>
            </button>
          </li>
        </transition-group>
      </draggable>
      <base-button
        type="text"
        class="lesson-playlist__next-lesson"
        @click="$emit('add-lesson')"
      >
        <span>{{ $t("addCourse.add-video.addNextLesson") }}</span>
        <svg
          viewBox=" 0 0 46 46"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="lesson-playlist__next-lesson-icon"
        >
          <path
            d="M44.083 21.083H24.917V1.917A1.917 1.917 0 0023 0a1.917 1.917 0 00-1.917 1.917v19.166H1.917A1.917 1.917 0 000 23a1.917 1.917 0 001.917 1.917h19.166v19.166a1.917 1.917 0 003.834 0V24.917h19.166a1.917 1.917 0 000-3.834z"
            fill="#474BFF"
          />
        </svg>
      </base-button>
    </div>
    <base-modal
      :value="!!lessonToDelete"
      @input="lessonToDelete = null"
      @accept="deleteLesson"
      :title="$t('deleteLesson.modal.title')"
    >
      <div slot="content">
        <p>
          {{ $t("deleteLesson.modal.text") }}
        </p>
      </div>
    </base-modal>
  </div>
</template>

<script>
export default {
  name: 'LessonPlaylist',
  data: () => ({
    drag: false,
    lessonToDelete: null,
  }),
  props: {
    course: {
      type: Object,
      required: true,
    },
    activeLesson: {
      type: Object,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 300,
        handle: '.lesson-playlist__drag-handler',
      };
    },
    lessons: {
      get() {
        return this.course.lessons;
      },
      set(newValue) {
        this.$emit('update-data', newValue);
      },
    },
  },
  methods: {
    deleteLesson() {
      this.$emit('delete-lesson', this.lessonToDelete);
      this.lessonToDelete = null;
    },
  },
};
</script>
<style lang="scss" scoped src="./LessonPlaylist.scss"/>
