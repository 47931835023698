<template>
  <base-modal
    size="small"
    :value="isCurrentModalOpen"
    :footer="false"
    :title="$t('cookies.title')"
    corner="bottom-left"
    @input="handleSetCookies"
    class="main__cookies"
  >
    <template slot="content">
      <p class="main-cookies__content">{{ $t("cookies.content") }}</p>
    </template>
    <template slot="footer">
      <div>
        <base-button
          to="/privacy-settings"
          type="outline-reverse"
          class="main-cookies__link main-cookies__button"
          @click.native="handleCloseModal"
        >
          {{ $t("cookies.link") }}
        </base-button>
        <base-button @click="handleSetCookies" class="main-cookies__button">
          {{ $t("cookies.button") }}
        </base-button>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'MainCookies',
  data: () => ({
    isCurrentModalOpen: false,
  }),
  computed: {
    ...mapState({
      isModalOpen: (state) => state.privacy.isModalOpen,
    }),
  },
  methods: {
    handleSetCookies() {
      this.$store.dispatch('privacy/setCookiesAgreement', true);
    },
    handleCloseModal() {
      this.$store.dispatch('privacy/setCookiesAgreement', false);
    },
  },
  created() {
    if (this.isModalOpen) {
      setTimeout(() => {
        this.isCurrentModalOpen = true;
      }, 3000);
    }
  },
  watch: {
    isModalOpen() {
      this.isCurrentModalOpen = this.isModalOpen;
    },
  },
};
</script>
<style lang="scss" scoped src="./MainCookies.scss" />
