<template>
  <nav
    class="main-navigation"
    :class="[
      !showNavbar ? 'main-navigation--hidden' : false,
      hasScrolled ? 'main-navigation--scrolled' : false,
      isCoursePage ? 'main-navigation--course-page' : false
    ]"
  >
    <a
      href="/ebook.pdf"
      class="main-navigation__notification"
      :title="$t('ebook')"
      :aria-label="$t('ebook')"
      target="_blank"
      @click="openEbook()"
      v-if="!hasOpenedEbook && user"
    >
      {{ $t('ebook') }}
    </a>

    <div class="container container--big">
      <header class="main-navigation__top">
        <!-- Language switch test -->
        <base-select
          helpfulText="Select Method"
          text="Language"
          v-model="language"
          mode="picker"
          @change="setLanguage($event)"
          ref="select"
          v-if="language && languagesList"
        >
          <template v-slot:header>
            <country-label :countryKey="language.value">
              {{ language.label }}
            </country-label>
          </template>
          <template v-slot:items>
            <base-option
              v-for="option in languagesList"
              :key="option.key"
              :value="option.key"
              :label="option.label"
            >
              <country-label :countryKey="option.key">
                {{ option.label }}
              </country-label>
            </base-option>
          </template>
        </base-select>
      </header>

      <div class="main-navigation__content">
        <router-link
          to="/"
          aria-label="Edunails"
          title="Edunails"
          class="main-navigation__logo-link"
        >
          <img src="/logo.svg" alt="Edunails" class="main-navigation__logo"/>
        </router-link>

        <div class="main-navigation__actions">
          <div class="main-navigation__actions-links">
            <base-button
              to="/shop"
              type="text"
              class="main-navigation__actions-sign-in"
            >
              {{ $t('shop.label') }}
            </base-button>

            <base-button
              to="/tutorial"
              type="text"
              class="main-navigation__actions-sign-in"
            >
              Tutorial
            </base-button>
          </div>

          <div class="main-navigation__actions-links" v-if="!isLogged">
            <base-button
              to="/auth/sign-in"
              type="text"
              class="main-navigation__actions-sign-in"
            >
              {{ $t('nav.sign-in') }}
            </base-button>
            <base-button to="/auth/sign-up" type="default">
              {{ $t('nav.sign-up') }}
            </base-button>
          </div>

          <div class="main-navigation__actions-user" v-else>
            <base-dropdown
              class="main-navigation__user-dropdown"
              v-if="user"
              ref="userDropdown"
            >
              <div class="main-navigation-user" slot="header">
                <user-avatar :user="user"/>
                <p class="main-navigation-user__text">
                  {{ `${$t('nav.hi')} ${user.firstName}!` }}
                </p>
              </div>
              <template slot="items">
                <base-dropdown-item>
                  <router-link
                    to="/dashboard"
                    :aria-label="$t('nav.profile')"
                    :title="$t('nav.profile')"
                    class="main-navigation-user__link"
                  >
                    {{ $t('nav.profile') }}
                  </router-link>
                </base-dropdown-item>

                <base-dropdown-item v-if="userType !== 'admin'">
                  <router-link
                    to="/my-courses"
                    :aria-label="$t('nav.courses')"
                    :title="$t('nav.courses')"
                    class="main-navigation-user__link"
                  >
                    {{ $t('nav.courses') }}
                  </router-link>
                </base-dropdown-item>

                <base-dropdown-item v-if="userType === 'creator'">
                  <router-link
                    to="/added-courses"
                    :aria-label="$t('nav.courses')"
                    :title="$t('nav.courses')"
                    class="main-navigation-user__link"
                  >
                    {{ $t('nav.added courses') }}
                  </router-link>
                </base-dropdown-item>

                <base-dropdown-item v-if="userType === 'creator'">
                  <router-link
                    to="/sales-history"
                    :aria-label="$t('dashboardNavigation.sales')"
                    :title="$t('dashboardNavigation.sales')"
                    class="main-navigation-user__link"
                  >
                    {{ $t('dashboardNavigation.sales') }}
                  </router-link>
                </base-dropdown-item>

                <base-dropdown-item v-if="userType !== 'admin'">
                  <router-link
                    to="/my-payments"
                    :aria-label="$t('nav.payments')"
                    :title="$t('nav.payments')"
                    class="main-navigation-user__link"
                  >
                    {{ $t('nav.payments') }}
                  </router-link>
                </base-dropdown-item>
                <base-dropdown-item>
                  <router-link
                    to="/settings"
                    :aria-label="$t('nav.settings')"
                    :title="$t('nav.settings')"
                    class="main-navigation-user__link"
                  >
                    {{ $t('nav.settings') }}
                  </router-link>
                </base-dropdown-item>

                <base-dropdown-item divinder>
                  <a
                    href="#"
                    @click="logout()"
                    class="main-navigation-user__link"
                    :aria-label="$t('nav.settings')"
                    :title="$t('nav.settings')"
                  >
                    {{ $t('nav.logout') }}
                  </a>
                </base-dropdown-item>
              </template>
            </base-dropdown>
          </div>

          <base-wishlist-icon style="margin: 0 0 0 1.2rem"/>
          <base-chart-icon/>
          <base-search-icon/>
        </div>

        <div class="main-navigation__mobile-switcher">
          <div>
            <base-button
              to="/tutorial"
              type="text"
              class="main-navigation__actions-sign-in"
              style="float: left; padding: 0"
            >
              Tutorial
            </base-button>

            <base-select
              helpfulText="Select Method"
              text="Language"
              v-model="mobileLanguage"
              mode="picker"
              @change="setMobileLanguage($event)"
              v-if="mobileLanguage && languagesList"
              style="float: left"
            >
              <template v-slot:header>
                <country-label :countryKey="mobileLanguage.value">
                  {{ mobileLanguage.label }}
                </country-label>
              </template>
              <template v-slot:items>
                <base-option
                  v-for="option in languagesList"
                  :key="option.key"
                  :value="option.key"
                  :label="option.label"
                  class="main-navigation__mobile-switcher-option"
                >
                  <country-label :countryKey="option.key">
                    {{ option.label }}
                  </country-label>
                </base-option>
              </template>
            </base-select>
          </div>
        </div>
      </div>
    </div>

    <transition mode="out-in" name="page-fade">
      <div class="container container--big" v-if="isDashboard">
        <dashboard-navigation/>
      </div>
    </transition>
  </nav>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { isDashboard, getImageLink } from '@/helpers/index';

export default {
  name: 'MainNavigation',
  data: () => ({
    language: {},
    mobileLanguage: {},
    showNavbar: true,
    lastScrollPosition: 0,
    currentScrollPosition: 0,
    hasScrolled: false,
    creatorInterval: null,
  }),
  computed: {
    ...mapState({
      currentLanguage: (state) => state.language.currentLanguage,
      languagesList: (state) => state.language.languagesList,
      user: (state) => state.auth.user,
      hasOpenedEbook: (state) => state.privacy.hasOpenedEbook,
    }),
    ...mapGetters({
      isLogged: 'auth/isLogged',
      userType: 'auth/userType',
    }),
    imageLink() {
      return getImageLink(this.user.avatarId);
    },
    isDashboard() {
      return isDashboard(this.$route.path);
    },
    isCoursePage() {
      return this.$route.path.includes('/watch-course');
    },
  },
  methods: {
    openEbook() {
      this.$store.dispatch('privacy/setEbookOpenStatus', true);
    },
    setLanguage(payload) {
      this.$store.dispatch('language/changeLanguage', payload.value);
    },
    setMobileLanguage(payload) {
      this.$store.dispatch('language/changeLanguage', payload.value);
    },
    onScroll() {
      this.currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;

      this.$refs.userDropdown
        ? (this.$refs.userDropdown.$data.isActive = false)
        : false;

      !this.showNavbar ? (this.$refs.select.$data.isActive = false) : false;
      this.isMenuOpen ? (this.showNavbar = true) : false;

      this.currentScrollPosition > 0
        ? (this.hasScrolled = true)
        : (this.hasScrolled = false);

      if (this.currentScrollPosition < 0) return;

      if (
        Math.abs(this.currentScrollPosition - this.lastScrollPosition) < 200
      ) {
        return;
      }

      this.showNavbar = this.currentScrollPosition < this.lastScrollPosition;
      this.lastScrollPosition = this.currentScrollPosition;
    },
    logout() {
      this.$store.dispatch('auth/logout');
    },
  },
  watch: {
    showNavbar() {
      this.$emit('toggle-navbar', this.showNavbar);
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll, { passive: true });
    // if user is not an author, ask once per minute if it changed
    if (this.isLogged && this.userType !== 'creator') {
      this.creatorInterval = setInterval(() => {
        this.$store.dispatch('auth/fetchUserData');
        if (this.userType === 'creator' && this.userType !== null) {
          clearInterval(this.creatorInterval);
        }
      }, 60000);
    }
  },
  beforeDestroy() {
    clearInterval(this.creatorInterval);
    window.removeEventListener('scroll', this.onScroll);
  },
  created() {
    this.language.value = this.currentLanguage.key;
    this.language.label = this.currentLanguage.label;

    this.mobileLanguage.value = this.currentLanguage.key;
    this.mobileLanguage.label = this.currentLanguage.label;
  },
};
</script>

<style lang="scss" src="./MainNavigation.scss"/>
