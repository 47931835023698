<template>
  <upload-file-logic
    v-slot="{
      handleUpload,
      isDragOver,
      computedImage,
      handleDelete,
      errors,
      isUploading
    }"
    :image="image"
    :rules="rules"
    :name="name"
    @upload="$emit('upload', $event)"
    @uploaded="$emit('uploaded')"
    @error="$emit('error')"
    @clear-error="$emit('clear-error')"
    :veeValidate="veeValidate"
  >
    <div class="upload-portfolio-image">
      <label
        :for="name"
        class="upload-portfolio-image__label"
        :class="[
          computedImage ? 'upload-portfolio-image__label--uploaded' : '',
          isDragOver ? 'upload-portfolio-image__label--is-over' : ''
        ]"
        :title="helpfulText"
      >
        <img
          :src="'/assets/icons/image.svg'"
          :alt="$t('uploadFile.addFiles')"
          :title="$t('uploadFile.addFiles')"
          class="upload-portfolio-image__icon"
          v-if="!isUploading"
        />
        <loader color="gray" v-else class="upload-portfolio-image__icon" />
        <input
          type="file"
          :id="name"
          :name="name"
          accept="image/png, image/jpeg"
          class="upload-portfolio-image__upload"
          @change="handleUpload($event.target)"
        />
        <p
          class="upload-portfolio-image__help"
          :class="isDragOver ? 'upload-portfolio-image__help--is-over' : ''"
          v-if="!computedImage"
        >
          {{ isDragOver ? dropText : label }}
        </p>
      </label>
      <transition mode="out-in" name="page-fade">
        <img
          :src="computedImage"
          alt=""
          class="upload-portfolio-image__image"
          v-if="computedImage"
        />
      </transition>
      <transition mode="out-in" name="page-fade">
        <base-button
          type="text"
          class="upload-portfolio-image__delete"
          @click="handleDelete"
          v-if="computedImage"
        >
          <span>{{ $t("uploadFile.label") }}</span>
          <svg-trash
            fill="#A0A4A8"
            class="upload-portfolio-image__delete-icon"
          />
        </base-button>
      </transition>
      <transition
        name="page-fade"
        @enter="$emit('error', name)"
        @leave="$emit('clear-error', name)"
      >
        <span
          class="base-input__error upload-portfolio-image__error"
          v-if="errors[0]"
        >
          {{ errors[0] }}
        </span>
      </transition>
    </div>
  </upload-file-logic>
</template>

<script>
export default {
  name: 'UploadPortfolioImage',
  props: {
    image: {
      type: String,
      required: false,
      default: null,
    },
    rules: {
      type: [String, Object],
      required: false,
      default: '',
    },
    helpfulText: {
      type: String,
      required: false,
      default: '',
    },
    dropText: {
      type: String,
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
      default: 'Field',
    },
    veeValidate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped src="./UploadPortfolioImage.scss" />
