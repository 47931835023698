import Cookies from 'js-cookie';

export default {
  namespaced: true,
  state: {
    isModalOpen: !Cookies.get('cookiesAgreement'),
    hasOpenedEbook: !!Cookies.get('hasOpenedEbook'),
    hasWatchedInstructionalVideo: !!Cookies.get('hasWatchedInstructionalVideo'),
  },
  mutations: {
    SET_COOKIES_AGREEMENT(state, payload) {
      if (payload) {
        Cookies.set('cookiesAgreement', JSON.stringify(payload), { expires: 365 });
      }
      state.isModalOpen = false;
    },
    CLEAR_ALL_COOKIES() {
      Object.keys(Cookies.get()).forEach((cookieName) => {
        Cookies.remove(cookieName);
      });
    },
    CLEAR_LOCAL_STORAGE() {
      localStorage.clear();
    },
    SET_EBOOK_OPEN_STATUS(state, payload) {
      state.hasOpenedEbook = true;
      Cookies.set('hasOpenedEbook', payload, { expires: 365 });
    },
    SET_INSTRUCTIONAL_VIDEO_STATUS(state, payload) {
      state.hasWatchedInstructionalVideo = payload;
      Cookies.set('hasWatchedInstructionalVideo', payload, { expires: 365 });
    },
  },
  actions: {
    setCookiesAgreement({ commit }, payload) {
      if (payload) {
        commit('SET_COOKIES_AGREEMENT', payload);
      } else {
        commit('CLEAR_ALL_COOKIES', payload);
        commit('SET_COOKIES_AGREEMENT', payload);
      }
    },
    setEbookOpenStatus({ commit }, payload = true) {
      commit('SET_EBOOK_OPEN_STATUS', payload);
    },
    setInstructionalVideo({ commit }, payload = true) {
      commit('SET_INSTRUCTIONAL_VIDEO_STATUS', payload);
    },
    clearAllCookies({ commit }) {
      commit('CLEAR_LOCAL_STORAGE');
    },
    clearLocalStorage({ commit }) {
      commit('CLEAR_LOCAL_STORAGE');
    },
  },
};
