<template>
  <div
    class="base-card"
    :class="[
      padding === 'big' ? 'base-card--big-padding' : false,
      padding === 'small' ? 'base-card--small-padding' : false,
      disabled ? 'base-card--disabled' : false
    ]"
  >
    <header class="base-card__header" >
      <div class="base-card__header-column">
        <h6 class="base-card__header-title" v-if="title && !hasHeaderLeft">
          {{ title }}
        </h6>

        <template v-if="hasHeaderLeft">
          <slot name="header-left"></slot>
        </template>

        <template v-if="hasHeaderRight">
          <slot name="header-right"></slot>
        </template>
      </div>

      <div class="base-card__header-column">
        <slot name="header-right"></slot>
      </div>
    </header>
    <div class="base-card__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseCard',
  props: {
    title: {
      type: [String, Number],
      required: false,
    },
    padding: {
      type: String,
      required: false,
      default: 'medium',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    hasHeaderLeft() {
      return !!this.$slots['header-left'];
    },
    hasHeaderRight() {
      return !!this.$slots['header-right'];
    },
  },
};
</script>

<style lang="scss" src="./BaseCard.scss" />
