<template>
  <base-card>
    <validation-observer slim ref="observer">
      <form class="account-details-form" @submit.prevent="handleSubmit">
        <section>
          <div class="account-details-form__row">
            <h3
              class="account-details-form__section-heading account-details-form__section-heading--main"
            >
              {{ $t('accountSettings.navigationLinks[0]') }}
            </h3>

            <base-switch
              class="account-details-form__switch"
              :options="[$t('privatePerson'), $t('company')]"
              :state="userData.isCompany ? 1 : 0"
              @switch="updateData({ isCompany: !userData.isCompany })"
            />
          </div>
          <div class="account-details-form__basic-data">
            <upload-avatar
              class="account-details-form__upload"
              :image="userData.avatarId"
              @upload="updateData({ avatarId: $event })"
              :label="profile.addPhoto"
              :helpfulText="profile.addPhoto"
              :user="userData"
            />
            <div class="account-details-form__inputs">
              <!-- Base Data -->
              <div
                v-for="fieldName in basicFieldNames"
                :key="fieldName"
                class="account-details-form__input"
              >
                <base-input
                  v-if="fieldName !== 'birthday'"
                  :value="userData[fieldName]"
                  @input="updateData({ [fieldName]: $event })"
                  :label="basicData[fieldName]"
                  :name="basicData[fieldName]"
                  :rules="fieldName === 'email' ? 'required|email' : 'required'"
                  ref="basicInputs"
                  @error="handleAddError"
                  @clear-error="handleClearError"
                  :autocomplete="autocompleteValues[fieldName]"
                  :inputmode="fieldName === 'email' ? 'email' : ''"
                  @blur="$emit('blur', $event)"
                />
                <base-date-picker
                  v-if="fieldName === 'birthday'"
                  :label="basicData[fieldName]"
                  :name="basicData[fieldName]"
                  @error="handleAddError"
                  @clear-error="handleClearError"
                  @blur="$emit('blur', $event)"
                  rules="required|date_format"
                  type="date"
                  :value="userData[fieldName]"
                  @input="updateData({ [fieldName]: $event })"
                  ref="basicInputs"
                  :max="adultDate"
                  autocomplete="bday"
                />
              </div>
            </div>
          </div>
        </section>

        <!-- Address -->
        <section>
          <h4 class="account-details-form__section-heading">
            {{ addressData.heading }}
          </h4>

          <div class="account-details-form__inputs">
            <div
              class="account-details-form__input"
              v-for="fieldName in addressFieldNames"
              :key="fieldName"
            >
              <base-input
                v-if="fieldName !== 'country'"
                class="account-details-form__input"
                :value="userData[fieldName]"
                @input="updateData({ [fieldName]: $event })"
                :label="addressData[fieldName]"
                :name="addressData[fieldName]"
                rules="required"
                ref="addressInputs"
                @blur="$emit('blur', $event)"
                @error="handleAddError"
                @clear-error="handleClearError"
                :autocomplete="autocompleteValues[fieldName]"
                :inputmode="
                  fieldName === 'postalCode' && language.key !== 'en'
                    ? 'numeric'
                    : ''
                "
              />

              <base-select
                v-if="fieldName === 'country'"
                :value="userData[fieldName]"
                :label="addressData[fieldName]"
                :name="addressData[fieldName]"
                autocomplete="country-name"
                rules="required"
                @blur="$emit('blur', $event)"
                @error="handleAddError"
                @clear-error="handleClearError"
                mode="search"
                @change="updateData({ [fieldName]: $event.value })"
                ref="select"
                class="account-details-form__select"
              >
                <template v-slot:items>
                  <base-option
                    v-for="option in countriesList"
                    :key="option.code"
                    :value="option.code"
                    :label="option.name"
                  >
                    {{ option.name }}
                  </base-option>
                </template>
              </base-select>
            </div>
          </div>
        </section>

        <!-- Company -->
        <section v-if="userData.isCompany">
          <h4 class="account-details-form__section-heading">
            {{ companyData.heading }}
          </h4>

          <div class="account-details-form__inputs">
            <div
              class="account-details-form__input"
              v-for="fieldName in companyFieldNames"
              :key="fieldName"
            >
              <base-input
                v-if="fieldName !== 'companyCountry'"
                class="account-details-form__input"
                :value="userData[fieldName]"
                @input="updateData({ [fieldName]: $event })"
                :label="companyData[fieldName]"
                :name="companyData[fieldName]"
                :autocomplete="autocompleteValues[fieldName]"
                rules="required"
                ref="companyInputs"
                @blur="$emit('blur', $event)"
                @error="handleAddError"
                @clear-error="handleClearError"
                :inputmode="
                  fieldName === 'postalCode' && language.key !== 'en'
                    ? 'numeric'
                    : ''
                "
              />

              <base-select
                v-if="fieldName === 'companyCountry'"
                :value="userData[fieldName]"
                @change="updateData({ [fieldName]: $event.value })"
                :label="companyData[fieldName]"
                :name="companyData[fieldName]"
                rules="required"
                @blur="$emit('blur', $event)"
                @error="handleAddError"
                @clear-error="handleClearError"
                autocomplete="country"
                mode="search"
                ref="companySelect"
                class="account-details-form__select"
              >
                <template v-slot:items>
                  <base-option
                    v-for="option in countriesList"
                    :key="option.code"
                    :value="option.code"
                    :label="option.name"
                  >
                    {{ option.name }}
                  </base-option>
                </template>
              </base-select>
            </div>
          </div>
        </section>

        <section v-for="section in additionalSections" :key="section">
          <h4 class="account-details-form__section-heading">
            {{ section }}
          </h4>
          <slot :name="section"></slot>
        </section>

        <section style="margin-top: 32px;"
                 v-if="userData.affiliationNick!=null">
          <h4 class="account-details-form__section-heading">
            Edu Designers
          </h4>
          <progress-bar :percent="Math.floor(userData.affiliationBalance/30)"
                        :text="$t('affiliation.remainingValue') + ' ('+(userData.affiliationBalance/100)+' EUR / 30 EUR)'"/>
          <p style="margin-top: 8px;">{{ $t('affiliation.payoutMessage') }}</p>
          <p v-if="affiliationDetails!=null" style="margin-top: 8px;">
            {{ $t('affiliation.totalUsages') }}:
            {{ affiliationDetails.total_usages }}</p>
          <h5 class="account-details-form__section-subheading"
              style="margin-top: 8px;">
            {{ $t('affiliation.yourCode') }}: <span
            style="font-weight: 900">{{ userData.affiliationNick }} (https://edunails.com/a/{{
              userData.affiliationNick
            }})</span>
          </h5>
        </section>

        <footer class="account-details-form__footer">
          <slot></slot>

          <div class="account-details-form__buttons">
            <base-button type="text" @click="$emit('go-back')">
              {{ backButton }}
            </base-button>

            <base-button nativeType="submit" :is-loading="isLoading">
              {{ submitButton }}
            </base-button>
          </div>
        </footer>
      </form>
    </validation-observer>
  </base-card>
</template>

<script>
import { mapState } from 'vuex';
import { countries } from '@/data/countries';
import dayjs from 'dayjs';
import ProgressBar from '@/components/reusable/ProgressBar/ProgressBar.vue';
import payment from '@/api/services/payment';

export default {
  name: 'AccountDetailsForm',
  components: { ProgressBar },
  data() {
    return {
      errorsArray: [],
      affiliationDetails: null,
    };
  },
  props: {
    forAuthorForm: {
      type: Boolean,
      required: false,
      default: false,
    },
    basicData: {
      type: Object,
      required: true,
    },
    addressData: {
      type: Object,
      required: true,
    },
    profile: {
      type: Object,
      required: true,
    },
    companyData: {
      type: Object,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
    additionalSections: {
      type: Array,
      required: false,
      default: () => [],
    },
    backButton: {
      type: String,
      required: false,
      default() {
        return this.$t('back');
      },
    },
    submitButton: {
      type: String,
      required: false,
      default() {
        return this.$t('next');
      },
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    adultDate() {
      return dayjs()
        .subtract(18, 'year')
        .format('YYYY-MM-DD');
    },
    basicFieldNames() {
      return ['firstName', 'lastName', 'birthday', 'email'];
    },
    addressFieldNames() {
      return ['street', 'postalCode', 'city', 'country'];
    },
    companyFieldNames() {
      return [
        'companyName',
        'companyTaxId',
        'companyCity',
        'companyStreet',
        'companyPostalCode',
        'companyCountry',
      ];
    },
    ...mapState({
      language: (state) => state.language.currentLanguage,
    }),
    countriesList() {
      return countries;
    },
    autocompleteValues() {
      return {
        firstName: 'given-name',
        lastName: 'family-name',
        birthday: 'bday',
        email: 'email',
        street: 'address-line1',
        postalCode: 'postal-code',
        city: 'address-level2',
        country: 'country-name',
        companyName: 'organization',
        companyTaxId: '',
        companyCity: 'address-level2',
        companyStreet: 'address-line1',
        companyPostalCode: 'postal-code',
        companyCountry: 'country-name',
      };
    },
  },
  methods: {
    async handleSubmit() {
      const isValidate = await this.$refs.observer.validate();
      const payPalInput = this.$slots.PayPal
        && this.$slots.PayPal.find((vNode) => vNode.tag.includes('BaseInput'));
      const uploadImageInput = this.$slots[this.$t('become-author.certificate.label')]
        && this.$slots[this.$t('become-author.certificate.label')].find((vNode) => vNode.tag.includes('UploadFile'),
        );
      if (isValidate) {
        this.$emit('submit');
        return;
      }
      const companyInputs = this.userData.isCompany
        ? [this.$refs.companyInputs, this.$refs.companySelect]
        : [];
      const invalidInputs = [
        ...this.$refs.basicInputs,
        ...this.$refs.addressInputs,
        ...this.$refs.select,
        ...companyInputs,
        uploadImageInput?.componentInstance,
        payPalInput?.componentInstance,
      ].filter((input) => this.errorsArray.includes(input.name));

      if (invalidInputs.length === 0) {
        return;
      }
      invalidInputs[0].$el.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      if (invalidInputs[0].$options.name === 'UploadFile') {
        return;
      }
      // check if it is select or base input
      if (invalidInputs[0].$options.name === 'BaseSelect') {
        invalidInputs[0].$refs.provider.$el
          .getElementsByTagName('input')[1]
          .focus({ preventScroll: true });
      } else {
        invalidInputs[0].$refs.input.focus({ preventScroll: true });
      }
    },
    updateData(data) {
      this.$emit('update-data', data);
      this.setCountrySelect(this.userData.country, 'select');
      this.setCountrySelect(this.userData.companyCountry, 'companySelect');
    },
    handleAddError(event) {
      this.errorsArray.push(event);
    },
    handleClearError(event) {
      this.errorsArray = this.errorsArray.filter((error) => error !== event);
    },
    setCountrySelect(countryID, type) {
      const countryLabel = this.countriesList.find(
        (country) => country.code === countryID,
      );
      if (!countryLabel || !this.$refs[type]) {
        return;
      }
      this.$refs[type][0].selected = {
        label: countryLabel.name,
        value: this.userData.country,
      };
    },
  },
  async mounted() {
    // Bind Select country
    this.setCountrySelect(this.userData.country, 'select');
    this.setCountrySelect(this.userData.companyCountry, 'companySelect');
    this.affiliationDetails = (await payment.getAffiliationDetails()).data;
  },
};
</script>
<style lang="scss" scoped src="./AccountDetailsForm.scss"/>
