<template>
  <section class="add-step-language">
    <base-card class="add-step-language__card">
      <h3 class="add-step-language__heading">
        {{ $t("headerSearch.select.language") }}
      </h3>
      <validation-observer tag="form" ref="form" @submit.prevent="">
        <base-select
          :helpfulText="$t('addCourse.select_language')"
          :text="$t('addCourse.select_language')"
          :name="$t('addCourse.forms.language')"
          :label="$t('addCourse.select_language')"
          :disabled="disabled"
          v-model="language"
          ref="select"
          class="add-step-language__select"
          rules="required"
        >
          <template v-slot:header>
            <country-label :countryKey="language.value" v-if="language" />
            <span v-else>{{ $t("addCourse.select_language") }}</span>
          </template>
          <template v-slot:items>
            <base-option
              v-for="option in languagesList.filter(
                language => language.key !== 'us'
              )"
              :key="option.key"
              :value="option.key"
              :label="$t(`languages.${option.key}`)"
            >
              <country-label :countryKey="option.key">
                {{ $t(`languages.${option.key}`) }}
              </country-label>
            </base-option>
          </template>
        </base-select>
      </validation-observer>
    </base-card>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AddStepLanguage',
  props: {
    course: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState({
      languagesList: (state) => state.language.languagesList,
      currentLanguage: (state) => state.language.currentLanguage,
    }),
    language: {
      get() {
        return this.course.language;
      },
      set(newValue) {
        this.$emit('update-data', { language: newValue });
      },
    },
  },
  created() {
    if (!this.language) {
      this.language = {
        value: this.currentLanguage.key,
        label: this.currentLanguage.label,
      };
    }
  },
};
</script>

<style lang="scss" src="./AddStepLanguage.scss" />
