<template>
  <div class="course-thumbnail" ref="wrapper">
    <router-link
      :to="`${link.url}/${link.slug}`"
      :aria-label="name"
      :title="name"
      class="course-thumbnail__image-link"
    >
      <img
        :src="imageLink"
        alt
        class="course-thumbnail__image"
        v-if="imageId"
      />
      <span v-else class="course-thumbnail__image" />
    </router-link>
    <div class="course-thumbnail__info">
      <router-link
        :to="`${link.url}/${link.slug}`"
        :aria-label="name"
        :title="name"
        class="course-thumbnail__name"
        :style="{ width: `calc(${elementWidth}px - 5rem)` }"
      >
        {{ name }}
      </router-link>
      <p class="course-thumbnail__additional">{{ additionalDetails }}</p>
    </div>
  </div>
</template>

<script>
import { getImageLink } from '@/helpers';

export default {
  name: 'CourseThumbnail',
  data: () => ({
    elementWidth: 0,
  }),
  props: {
    imageId: {
      type: String,
      required: false,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    additionalDetails: {
      type: String,
      required: false,
      default: '',
    },
    id: {
      type: [String, Number],
      required: true,
    },
    slug: {
      type: [String, Number],
      required: true,
    },
    mode: {
      type: String,
      required: false,
      default: 'check',
    },
    courseLink: {
      type: String,
      required: false,
    },
  },
  computed: {
    imageLink() {
      return getImageLink(this.imageId);
    },
    link() {
      return this.mode === 'watch'
        ? { url: '/watch-course', slug: this.id }
        : { url: '/courses', slug: this.slug };
    },
  },
  methods: {
    handleResize() {
      this.elementWidth = this.$refs.wrapper.offsetWidth;
    },
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
<style lang="scss" src="./CourseThumbnail.scss" />
