var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.value)?_c('portal',{attrs:{"to":"modal"}},[_c('transition',{attrs:{"name":"page-fade","mode":"out-in","appear":""}},[_c('div',{staticClass:"base-modal__overlay",class:_vm.corner ? `base-modal__overlay--${_vm.corner}` : '',on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.closeModal()}}},[_c('div',{staticClass:"base-modal",class:[
          _vm.size === 'small' ? 'base-modal--small' : false,
          _vm.size === 'medium' ? 'base-modal--medium' : false,
          _vm.size === 'big' ? 'base-modal--big' : false,
          _vm.frameless === true ? 'base-modal--frameless' : false,
          _vm.overflowHidden ? 'base-modal--overflow-hidden' : false,
        ]},[_c('header',{staticClass:"base-modal__header"},[_c('h4',{staticClass:"base-modal__header-title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('base-close-icon',{staticClass:"base-modal__header-close",on:{"click":function($event){return _vm.closeModal()}}})],1),_c('div',{staticClass:"base-modal__content"},[_vm._t("content")],2),_c('footer',{staticClass:"base-modal__footer"},[_vm._t("footer",function(){return [(_vm.footer)?_c('div',{staticClass:"base-modal__actions"},[_c('base-button',{staticClass:"base-modal__actions-button",attrs:{"type":"text","title":_vm.$t('modal.close')},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" "+_vm._s(_vm.$t('modal.close'))+" ")]),_c('base-button',{staticClass:"base-modal__actions-button",attrs:{"type":"default","title":_vm.$t('modal.accept')},on:{"click":function($event){return _vm.confirm()}}},[_vm._v(" "+_vm._s(_vm.$t('modal.accept'))+" ")])],1):_vm._e()]})],2)])])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }