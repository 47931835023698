<template>
  <div
    class="base-overlay"
    @click="closeOverlay()"
  >

  </div>
</template>

<script>
export default {
  name: 'BaseOverlay',
  methods: {
    closeOverlay() {
      this.$store.dispatch('ui/closeAll');
    },
  },
};
</script>

<style lang="scss" src="./BaseOverlay.scss" />
