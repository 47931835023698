<template>
  <validation-provider
    :rules="rules"
    :name="name"
    ref="provider"
    mode="eager"
    tag="div"
    class="upload-video"
  >
    <label
      v-if="!video"
      class="upload-video__label"
      :class="[isDragOver ? 'upload-video__label--is-over' : false]"
      for="video"
      @drop.prevent="handleUpload($event.dataTransfer)"
      @dragover.prevent="handleDragOver"
      @dragleave="isDragOver = false"
    >
      <img src="/assets/icons/player.svg" alt="" class="upload-video__icon"/>

      <p v-if="!disabled" class="upload-video__text">
        <span v-if="!disabled">{{ $t("uploadVideo.drag") }}</span>
        {{ $t("uploadVideo.or") }}
        <strong class="upload-video__text-strong">
          {{ $t("uploadFile.browse") }}
        </strong>
      </p>

      <input
        name="video"
        :disabled="disabled"
        @submit="checkFileSize"
        type="file"
        id="video"
        :accept="formats"
        @change="handleUpload($event.target)"
        class="upload-video__upload"
      />

      <div class="upload-video__requirements">
        <p
          class="upload-video__requirement"
          v-for="requirement in requirements"
          :key="requirement"
        >
          {{ requirement }}
        </p>

        <transition mode="out-in" name="page-fade">
          <p class="upload-video__error" v-if="isError">
            {{ errorMessage }}
          </p>
        </transition>
      </div>
    </label>

    <div
      class="upload-video__player"
      v-else-if="videoFile && status !== 'errored'"
    >
      <video :src="videoFile" controls class="upload-video__player-video"/>
    </div>

    <div class="upload-video__status" v-else>
      <img
        class="upload_video__icon-status base-notification__icon-status"
        alt=""
        :src="statusIcon"
      />
      <base-heading bold="bold" class="upload-video__heading" align="center">
        {{ statusHeading }}
      </base-heading>
    </div>

    <delete-button
      @click="handleShowModal"
      v-if="video && !disabled"
      class="upload-video__delete"
    >
      <p class="upload-video__delete-button-text">
        {{ $t("uploadVideo.delete.title") }}
      </p>
    </delete-button>

    <base-modal
      :value="deleteModal"
      @input="deleteModal = false"
      @accept="handleDelete"
      :title="$t('uploadVideo.delete.title')"
    >
      <div slot="content">
        <p>
          {{ $t("uploadVideo.delete.text") }}
        </p>
      </div>
    </base-modal>
  </validation-provider>
</template>

<script>
import video from '@/api/upload/video';

export default {
  name: 'UploadVideo',
  data: () => ({
    isDragOver: false,
    isError: false,
    deleteModal: false,
    errorMessage: '',
    videoFile: null,
    localMode: false,
  }),
  props: {
    video: {
      type: String,
      required: true,
    },
    videoURL: {
      type: String,
      required: false,
      default: '',
    },
    status: {
      type: String,
      required: false,
      default: '',
    },
    requirements: {
      type: Array,
      required: false,
    },
    name: {
      type: String,
      required: false,
      default: 'Field',
    },
    rules: {
      type: [String, Object],
      required: false,
      default: '',
    },
    vid: {
      type: String,
      required: false,
      default: '',
    },
    formats: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    courseId: {
      type: String,
      required: false,
    },
    lessonId: {
      type: String,
      required: false,
    },
  },
  computed: {
    statusIcon() {
      return this.status === 'errored'
        ? '/assets/icons/notification-error.svg'
        : '/assets/icons/notification-success.svg';
    },
    statusHeading() {
      return this.status === 'errored'
        ? this.$t('uploadVideo.error')
        : this.$t('uploadVideo.success');
    },
  },
  methods: {
    checkFileSize() {
      const input = document.getElementById('video');
      if (input.files && input.files.length === 1) {
        if (input.files[0].size > 629145600) {
          return false;
        }
      }
      return true;
    },
    async handleUpload(source) {
      if (!this.video) {
        const file = source.files[0];

        if (file) {
          const validFormats = this.formats.split(',');
          const isValidFile = () => validFormats.includes(file.type);

          if (file.size > 629145600) {
            return false;
          }
          if (isValidFile()) {
            const duration = await this.setVideoDuration(file);
            const videoURL = window.URL.createObjectURL(file);

            this.localMode = true;
            this.videoFile = videoURL;
            this.isError = false;
            this.$emit('upload', { file, duration, videoURL });
          } else {
            this.errorMessage = this.$t('uploadVideo.invalidFile');
            this.isError = true;
          }
        }
      }
    },
    setVideoDuration(file) {
      const newVideo = document.createElement('video');
      newVideo.preload = 'metadata';

      return new Promise((resolve) => {
        newVideo.onloadedmetadata = () => {
          window.URL.revokeObjectURL(newVideo.src);
          const { duration } = newVideo;
          resolve(Math.round(duration));
        };

        newVideo.src = URL.createObjectURL(file);
      });
    },
    handleDragOver() {
      this.isDragOver = true;
    },
    handleShowModal() {
      this.deleteModal = true;
    },
    handleDelete() {
      this.deleteModal = false;
      this.isError = false;
      this.$emit('delete');
    },
    async getVideoSource() {
      const { data } = await video.getAllVideoSources({
        courseID: this.courseId,
        lessonID: this.lessonId,
      });

      this.videoFile = data.Link_1080p;
    },
  },
  watch: {
    video() {
      if (this.videoURL || !this.video) {
        return;
      }
      this.$refs.provider.validate(this.video);
      this.getVideoSource();
    },
    videoURL() {
      this.videoFile = this.videoURL;
    },
  },
  mounted() {
    if (this.videoURL) {
      this.videoFile = this.videoURL;
      return;
    }
    if (this.video) {
      this.$refs.provider.validate(this.video);
      this.getVideoSource();
    }
  },
  created() {
    this.errorMessage = this.$t('uploadVideo.error');
  },
};
</script>

<style lang="scss" scoped src="./UploadVideo.scss"/>
