<template>
  <base-button
    type="text"
    class="delete-button"
    @click="$emit('click')"
  >
    <slot></slot>
    <svg-trash fill="#A0A4A8" class="delete-button__icon" />
  </base-button>
</template>

<script>
export default {
  name: 'DeleteButton',
};
</script>
<style lang="scss" scoped src="./DeleteButton.scss" />
