<template>
  <div
    class="base-tooltip"
    @mouseenter="isShown = !isShown"
    @mouseleave="isShown = !isShown"
  >
    <slot name="element"></slot>
    <transition name="page-fade">
      <div
        class="base-tooltip__inner"
        :class="`base-tooltip__inner--${direction}`"
        role="tooltip"
        :aria-hidden="!isShown"
        v-if="isShown"
      >
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'BaseTooltip',
  data: () => ({
    isShown: false,
  }),
  props: {
    direction: {
      type: String,
      required: false,
      default: 'top',
    },
  },
};
</script>
<style lang="scss" scoped src="./BaseTooltip.scss" />
