<template>
  <section class="section-info">
    <div
      class="container container--big section-info__container--big"
      :class="
        purpose === 'user'
          ? 'section-info__container--user'
          : 'section-info__container--author'
      "
    >
      <div class="container section-info__container">
        <div class="section-info__description">
          <base-sub-heading class="section-info__header-sub-heading">
            <span v-html="subTitle" />
          </base-sub-heading>
          <base-heading class="section-info__header-heading" bold="bold">
            <span v-html="title" />
          </base-heading>
          <ul>
            <li
              class="section-info__list-item"
              v-for="item in list"
              :key="item.header"
            >
              <h4 class="section-info__item-header">{{ item.header }}</h4>
              <p class="section-info__item-content">{{ item.content }}</p>
            </li>
          </ul>
          <div class="section-info__buttons">
            <base-button to="/auth/sign-up">
              {{ buttonText }}
            </base-button>
            <base-button
              type="text"
              to="/about-us"
              icon="arrow-right"
              class="featured-courses__bottom-button"
            >
              {{ $t("learnMore") }}
            </base-button>
          </div>
        </div>
        <div class="section-info__user">
          <img
            :src="image.src"
            :alt="image.alt"
            class="section-info__user-image"
          />
          <template v-if="purpose === 'user'">
            <!-- <div class="section-info__verified">
              <svg-tick class="section-info__verified-tick"/>
              <p>{{ $t("verifiedUser") }}</p>
            </div> -->
            <!-- <user-card class="section-info__user-card">
              <div class="section-info__user-stats">
                <p>
                  <strong>19</strong>
                  <span v-html="$t('sectionInfo.courses')"></span>
                </p>
                <span class="section-info__user-line" />
                <p>
                  <strong>
                    78
                    <img src="/assets/icons/likes.svg" :alt="$t('likes')" />
                  </strong>
                  <span v-html="$t('sectionInfo.likes')"></span>
                </p>
              </div>
            </user-card> -->
          </template>
          <!-- <div class="section-info__courses" v-else-if="purpose === 'author'">
            <div class="section-info__courses-header">
              <p>{{ $t("sectionInfo.addedCourses") }}</p>
              <p>{{ $t("sectionInfo.commission") }}</p>
            </div>
            <span class="section-info__line" />
            <div class="section-info__course">
              <div class="section-info__wrapper">
                <img
                  src="/assets/images/example-course-1.jpg"
                  :alt="$t('sectionInfo.course1')"
                  class="section-info__image"
                />
                <h5>
                  <p class="section-info__name">{{ $t("sectionInfo.course1") }}</p>
                  <p class="section-info__level">{{ $t("sectionInfo.level1") }}</p>
                </h5>
              </div>
              <div>
                <div class="section-info__profit">
                  <img src="/assets/icons/arrow-up-right.svg" :alt="$t('arrow')" />
                  +27.6%
                </div>
                <p class="section-info__price">{{ $t("sectionInfo.price1") }}</p>
              </div>
            </div>
            <span class="section-info__line" />
            <div class="section-info__course">
              <div class="section-info__wrapper">
                <img
                  src="/assets/images/example-course-2.jpg"
                  :alt="$t('sectionInfo.course2')"
                  class="section-info__image"
                />
                <h5>
                  <p class="section-info__name">{{ $t("sectionInfo.course2") }}</p>
                  <p class="section-info__level">{{ $t("sectionInfo.level2") }}</p>
                </h5>
              </div>
              <div>
                <p class="section-info__price">{{ $t("sectionInfo.price2") }}</p>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionInfo',
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
    list: {
      type: Array,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    purpose: {
      type: String,
      required: true,
    },
  },
  computed: {
    link() {
      return this.purpose === 'user' ? 'user' : 'author';
    },
    image() {
      return this.purpose === 'user'
        ? { src: '/assets/images/user.jpg', alt: this.$t('user') }
        : { src: '/assets/images/author.jpg', alt: this.$t('author') };
    },
  },
};
</script>
<style lang="scss" scoped src="./SectionInfo.scss" />
