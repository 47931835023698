<template>
  <div class="reset-password-email">
    <base-heading bold="bold" align="center">
      {{ $t(`resetPassword.${type}.heading`) }}
    </base-heading>
    <validation-observer slim v-slot="{ handleSubmit }">
      <form
        @submit.prevent="handleSubmit(onSubmit)"
        class="reset-password-email__form"
      >
        <base-input
          :rules="type === 'email' ? 'required|email' : 'required|min:8'"
          :type="type === 'password' ? 'password' : ''"
          :icon="type === 'password' ? 'eye' : ''"
          :label="$t(`resetPassword.${type}.label`)"
          @input="$emit('input', $event)"
          :value="value"
        />
        <base-button
          nativeType="submit"
          class="reset-password-email__submit"
          :is-loading="isLoading"
      >
          {{ $t(`resetPassword.${type}.submit`) }}
        </base-button>
      </form>
    </validation-observer>
  </div>
</template>

<script>
export default {
  name: 'ResetPasswordInput',
  props: {
    value: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onSubmit() {
      this.$emit('submit');
    },
  },
};
</script>
<style lang="scss" scoped src="./ResetPasswordInput.scss" />
