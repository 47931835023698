<template>
  <base-modal
    :value="true"
    @input="$router.go(-1)"
    :title="$t('dashboard.author.addCourseButton')"
    class="add-course-modal"
  >
    <div slot="content">
      <validation-observer
        @submit.prevent="handleSubmit"
        tag="form"
        ref="addForm"
        class="add-course-modal__form"
      >
        <base-input
          class="add-course-modal__input"
          rules="required"
          v-model="courseName"
          :label="$t('addCourse.title')"
          autofocus
        />
      </validation-observer>
    </div>

    <div class="add-course-modal__actions" slot="footer">
      <base-button
        type="text"
        @click="$router.go(-1)"
        :title="$t('modal.close')"
        class="add-course-modal__actions-button"
      >
        {{ $t("modal.close") }}
      </base-button>

      <base-button
        type="default"
        @click="handleSubmit()"
        :title="$t('modal.accept')"
        :is-loading="isAdding"
        class="add-course-modal__actions-button"
      >
        {{ $t("modal.accept") }}
      </base-button>
    </div>
  </base-modal>
</template>

<script>
import course from '@/api/services/course';

export default {
  name: 'AddCourseModal',
  data: () => ({
    courseName: '',
    isAdding: false,
  }),
  methods: {
    async createCourse() {
      this.isAdding = true;

      try {
        const { data } = await course.addCourse({ name: this.courseName });
        this.$emit('update-data', data);
        this.$router.push(`/manage-course/${data.id}`);
      } catch (err) {
        this.$store.dispatch('notification/spawn', {
          errorMessage: err.response.data.message,
          type: 'error',
        });

        this.courseName = '';
      } finally {
        this.isAdding = false;
      }
    },
    async handleSubmit() {
      const status = await this.$refs.addForm.validate();
      status ? this.createCourse() : false;
    },
  },
};
</script>
<style lang="scss" scoped src="./AddCourseModal.scss" />
