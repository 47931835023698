<template>
  <div class="base-switch" :class="disabled && 'base-switch--disabled'">
    <label
      class="base-switch__label"
      :class="!state ? 'base-switch__label--active' : ''"
      for="base-switch"
      :title="options[0]"
    >
      {{ options[0] }}
    </label>
    <button
      type="button"
      id="base-switch"
      @click="handleSwitch"
      class="base-switch__button"
      :disabled="disabled"
    >
      <div
        class="base-switch__dot"
        :class="state ? 'base-switch__dot--right' : ''"
      />
    </button>
    <label
      class="base-switch__label"
      :class="state ? 'base-switch__label--active' : ''"
      for="base-switch"
      :title="options[1]"
    >
      {{ options[1] }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'BaseSwitch',
  props: {
    state: {
      type: [Number, Boolean],
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    handleSwitch() {
      this.$emit('switch', !this.state);
    },
  },
};
</script>
<style lang="scss" scoped src="./BaseSwitch.scss" />
