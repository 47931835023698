<template>
  <div class="progress-bar" :class="big ? 'progress-bar--big' : ''">
    <div class="progress-bar__top">
      <p class="progress-bar__text">
        <strong>{{ percent }}%</strong>
        {{ text }}
      </p>
      <p class="progress-bar__additional-info" v-if="additionalInfo">
        {{ additionalInfo }}
      </p>
    </div>
    <span class="progress-bar__line">
      <span
        class="progress-bar__line progress-bar__line--inner"
        :style="{ width: `${percent}%` }"
      />
    </span>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    percent: {
      type: Number,
      required: false,
      default: 0,
    },
    text: {
      type: String,
      required: false,
      default() {
        return this.$t('boughtCourse.completed');
      },
    },
    big: {
      type: Boolean,
      required: false,
      default: false,
    },
    additionalInfo: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>
<style lang="scss" scoped src="./ProgressBar.scss" />
