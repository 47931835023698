<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.153"
    height="19.68"
    viewBox="0 0 23.153 19.68"
  >
    <path
      d="M23.995,11.6H22.837V10.446a1.158,1.158,0,1,0-2.315,0V11.6H19.364a1.158,1.158,0,1,0,0,2.315h1.158v1.158a1.158,1.158,0,1,0,2.315,0V13.919h1.158a1.158,1.158,0,1,0,0-2.315Zm-8.914,1.991a5.7,5.7,0,0,0,1.968-4.306,5.788,5.788,0,1,0-11.576,0,5.7,5.7,0,0,0,1.968,4.306A9.261,9.261,0,0,0,2,22.022a1.158,1.158,0,1,0,2.315,0,6.946,6.946,0,1,1,13.892,0,1.158,1.158,0,0,0,2.315,0,9.261,9.261,0,0,0-5.441-8.428Zm-3.82-.833a3.473,3.473,0,1,1,3.473-3.473A3.473,3.473,0,0,1,11.261,12.761Z"
      transform="translate(-2 -3.5)"
      fill="#858585"
      class="dashboard-navigation__icon"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgUserPlus',
};
</script>
