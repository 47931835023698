<template>
  <component
    :is="componentType"
    :disabled="disabled"
    :class="classes"
    :to="to ? to : false"
    :title="title"
    :aria-label="title"
    :type="nativeType"
    @click="$emit('click')"
    @focus="$emit('focus')"
    class="base-button"
  >
    <p
      class="base-button__text"
      :class="small ? 'base-button__text--small' : ''"
      v-if="type !== 'icon'"
    >
      <slot></slot>
    </p>
    <img
      v-if="type === 'text' && icon"
      class="base-button__icon-text"
      alt=""
      :src="`/assets/icons/${icon}.svg`"
    />
    <img
      v-if="icon && type === 'icon'"
      class="base-button__icon"
      alt=""
      :src="`/assets/icons/${icon}.svg`"
    />
    <transition name="fade" mode="out-in">
      <div class="base-button__loader-overlay" v-if="isLoading">
        <loader class="base-button__loader" color="white" />
      </div>
    </transition>
  </component>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    to: {
      type: String,
      required: false,
      default: null,
    },
    nativeLink: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: [String],
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: 'default',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    nativeType: {
      type: String,
      required: false,
      default: 'button',
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    thin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    componentType() {
      if (this.to && this.nativeLink) {
        return 'a';
      }

      if (this.to && !this.nativeLink) {
        return 'router-link';
      }

      if (!this.to && !this.nativeLink) {
        return 'button';
      }

      return 'button';
    },
    classes() {
      return [
        this.type === 'default' ? 'base-button--default' : '',
        this.type === 'text' ? 'base-button--text' : '',
        this.type === 'link' ? 'base-button--link' : '',
        this.type === 'icon' ? 'base-button--icon' : '',
        this.type === 'outline' ? 'base-button--outline' : '',
        this.type === 'contrasting' ? 'base-button--contrasting' : '',
        this.type === 'outline-reverse'
          ? 'base-button--outline base-button--outline-reverse'
          : '',
        this.type === 'outline-disabled'
          ? 'base-button--outline base-button--outline-disabled'
          : '',
        this.type === 'outline-dangerous'
          ? 'base-button--outline base-button--outline-dangerous'
          : '',
        this.type === 'outline-gray'
          ? 'base-button--outline base-button--outline-gray'
          : '',
        this.disabled ? 'base-button--disabled' : '',
        this.isLoading ? 'base-button--loading' : '',
        this.thin ? 'base-button--thin' : '',
      ];
    },
  },
};
</script>

<style lang="scss" src="./BaseButton.scss" />
