var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-card',{staticClass:"base-notification-box",class:[
    _vm.size === 'medium' ? 'base-notification-box--medium' : false,
    _vm.size === 'big' ? 'base-notification-box--big' : false,
    _vm.size === 'fluid' ? 'base-notification-box--fluid' : false,
    _vm.center ? 'base-notification-box--center' : false,
    _vm.withoutShadow ? 'base-notification-box--without-shadow' : false
  ]},[_c('div',{staticClass:"base-notification-box__inner"},[_c('img',{staticClass:"base-notification-box__icon",attrs:{"alt":"","src":`/assets/icons/notification-${_vm.mode}.svg`}}),_c('h4',{staticClass:"base-notification-box__heading",domProps:{"innerHTML":_vm._s(_vm.heading)}}),_c('p',{staticClass:"base-notification-box__text"},[_vm._v(" "+_vm._s(_vm.text)+" ")]),_c('div',{staticClass:"base-notification-box__content"},[_vm._t("default")],2),_vm._t("actions")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }