<template>
  <div>
    <div class="promo__layout">
      <div>
        <base-input :placeholder="$t('affiliation.promoCode')" :trim="true"
                    v-model="promoCode" :disabled="submitted"/>
      </div>
      <base-button :small="true" :thin="true" class="promo__apply_button"
                   @click="submitPromoCode">{{ buttonMessage }}
      </base-button>

    </div>
    <div style="padding-bottom: 12px;">
      <small v-if="error" style="color: red; ">{{
          $t('affiliation.invalidCode')
        }}</small>
      <small v-if="!error && promoCode?.toLowerCase()==='marcelina50'">Promo --50% on Marcelina's courses</small>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/base/BaseInput/BaseInput.vue';
import BaseButton from '@/components/base/BaseButton/BaseButton.vue';
import payment from '@/api/services/payment';

export default {
  name: 'CheckoutPromo',
  components: { BaseButton, BaseInput },
  data() {
    return {
      promoCode: '',
      submitted: false,
      buttonMessage: this.$t('affiliation.accept'),
      affiliator: null,
      error: false,
    };
  },
  props: {
    setPromoCode: {
      required: false,
    },
  },
  mounted() {
    if (localStorage.getItem('promo_code') !== null) {
      this.promoCode = localStorage.getItem('promo_code');
      this.submitPromoCode();
    }
  },
  methods: {
    async submitPromoCode() {
      if (!this.submitted) {
        if (this.promoCode.length === 0) {
          this.affiliator = null;
          this.error = false;
          return;
        }
        try {
          const { data } = await payment.validatePromoCode(this.promoCode);
          this.submitted = true;
          this.buttonMessage = this.$t('affiliation.change');
          this.setPromoCode(this.promoCode);
          this.affiliator = data;
          this.error = false;
        } catch (err) {
          this.error = true;
          this.setPromoCode(null);
        }
      } else {
        localStorage.removeItem('promo_code');
        this.buttonMessage = this.$t('affiliation.accept');
        this.submitted = false;
        this.error = false;
        this.setPromoCode(null);
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./CheckoutPromo.scss"/>
