<template>
  <svg viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M44.083 21.083H24.917V1.917A1.917 1.917 0 0023 0a1.917 1.917 0 00-1.917 1.917v19.166H1.917A1.917 1.917 0 000 23a1.917 1.917 0 001.917 1.917h19.166v19.166a1.917 1.917 0 003.834 0V24.917h19.166a1.917 1.917 0 000-3.834z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgPlus',
  props: {
    fill: {
      type: String,
      required: false,
      default: '#CACCCF',
    },
  },
};
</script>
