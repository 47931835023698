<template>
  <li class="checkout-item">
    <div class="checkout-item__left">
      <p class="checkout-item__no">{{ item.index + 1 }}.</p>
      <course-thumbnail
        v-bind="item"
        :additionalDetails="`${$t('level')} ${item.difficulty}`"
        class="checkout-item__thumbnail"
      />
    </div>
    <div class="checkout-item__right">
      <base-price :price="item.price" :currency="item.currency"
                  class="checkout-item__price"
                  :promoPrice="item.price * (100-item.promotionValue) / 100"
                  v-if="promotionDuration!=null && !item.extra"/>
      <base-price :price="item.price" :currency="item.currency"
                  class="checkout-item__price"
                  :promoPrice="item.extraPrice"
                  v-if="item.extra && cartList.filter((e) => !e.extra).length > 0"/>
      <base-price :price="item.price" :currency="item.currency"
                  class="checkout-item__price" v-if="promotionDuration==null && (!item.extra || cartList.filter((e) => !e.extra).length === 0)"/>
      <base-price :price="item.price" 
                  :currency="item.currency"
                  :promoPrice="item.price * (50) / 100"
                  class="checkout-item__price" 
                  v-if="promoCode?.toLowerCase()==='marcelina50' && item.authorId==='629c573ed9f77f0001227afc' && !item.extra"/>
      <button
        class="cart-course__delete"
        :title="$t('cart.delete')"
        :aria-label="$t('cart.delete')"
        @click="deleteFromCart()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          class="cart-course__delete-icon"
        >
          <g
            id="Group_2220"
            data-name="Group 2220"
            transform="translate(-964.994 -2432.519)"
          >
            <circle
              id="Ellipse_33"
              data-name="Ellipse 33"
              cx="12.5"
              cy="12.5"
              r="12.5"
              transform="translate(964.994 2432.519)"
              fill="#f2f2f2"
            />
            <g
              id="Group_2221"
              data-name="Group 2221"
              transform="translate(971.907 2438.915)"
            >
              <g id="Group_46" data-name="Group 46">
                <path
                  id="Path_33"
                  data-name="Path 33"
                  d="M194.734,648.843H190.3v8.509a2.329,2.329,0,0,0,2.329,2.329h4.21a2.329,2.329,0,0,0,2.329-2.329v-8.509Z"
                  transform="translate(-189.316 -646.873)"
                  fill="rgba(0,0,0,0)"
                  stroke="#b4b4b4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.3"
                />
                <line
                  id="Line_9"
                  data-name="Line 9"
                  y2="4.926"
                  transform="translate(3.941 4.926)"
                  fill="none"
                  stroke="#b4b4b4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.3"
                />
                <line
                  id="Line_10"
                  data-name="Line 10"
                  y2="4.926"
                  transform="translate(6.897 4.926)"
                  fill="none"
                  stroke="#b4b4b4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.3"
                />
                <line
                  id="Line_11"
                  data-name="Line 11"
                  x2="10.838"
                  transform="translate(0 1.97)"
                  fill="none"
                  stroke="#b4b4b4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.3"
                />
                <path
                  id="Path_34"
                  data-name="Path 34"
                  d="M197.765,648.275l-.579-.927a2.22,2.22,0,0,0-1.882-1.043h0a2.219,2.219,0,0,0-1.882,1.043l-.58.927"
                  transform="translate(-189.883 -646.305)"
                  fill="rgba(0,0,0,0)"
                  stroke="#b4b4b4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.3"
                />
              </g>
            </g>
          </g>
        </svg>
      </button>
    </div>
  </li>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CheckoutItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    promoCode: {
      type: String,
      required: false,
    },
  },
  methods: {
    deleteFromCart() {
      this.$store.dispatch('cart/deleteCartItem', this.item.id);
    },
  },
  computed: {
    ...mapState({
      cartList: (state) => state.cart.cartList,
    }),
    promotionDuration() {
      try {
        if (this.item.promotionEnd == null || this.item.promotionValue == null || this.item.promotionValue === 0) return null;
        const local = new Date();
        const now = new Date(local.getTime() + local.getTimezoneOffset() * 60000);
        const diff = (Date.parse(this.item.promotionEnd) - now);
        const hours = Math.floor(diff / (1000 * 60 * 60));
        const minutes = Math.floor((diff - hours * 1000 * 60 * 60) / (1000 * 60));
        const seconds = Math.floor((diff - hours * 1000 * 60 * 60 - minutes * 1000 * 60) / (1000));
        if (diff <= 0) return null;
        return `${hours}h ${minutes} min ${seconds} s`;
      } catch (err) {
        console.log(err);
        return null;
      }
    },
  },
};
</script>
<style lang="scss" scoped src="./CheckoutItem.scss"/>
