<template>
  <div class="dashboard-stat-box" :class="`dashboard-stat-box--${color}`">
    <div class="dashboard-stat-box__content">
      <div class="dashboard-stat-box__inner">
        <p class="dashboard-stat-box__name">
          {{ name }}
        </p>

        <p class="dashboard-stat-box__value" v-if="type !== 'income'">
          {{ value }}
        </p>

        <base-price
          class="dashboard-stat-box__value"
          v-else
          :price="value"
        />
      </div>
      <!-- <router-link
        :to="link"
        class="dashboard-stat-box__action"
      >
        +
      </router-link> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardStatBox',
  data: () => ({
    period: 'day',
  }),
  props: {
    link: {
      type: String,
      required: false,
      default: '/add-course',
    },
    name: {
      type: String,
      required: false,
    },
    value: {
      type: [String, Number],
      required: false,
    },
    color: {
      type: String,
      required: false,
      default: 'blue',
    },
    type: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped src="./DashboardStatBox.scss" />
