import axios from 'axios';
import store from '@/store/index';
import appConfig from '@/config';

// Base configuration of Axios instance
const request = axios.create({
  baseURL: appConfig.assetServerURL,
  validateStatus: (status) => {
    return status >= 200 && status < 300;
  },
});

request.interceptors.request.use(
  (config) => {
    store.state.auth.token ? config.headers.Authorization = `Bearer ${store.state.auth.token}` : false;
    return config;
  },
  (err) => console.log(err),
);

// Set HTTP header with token
export default request;
