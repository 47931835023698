<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 6h18M8 6V4a2 2 0 012-2h4a2 2 0 012 2v2m3 0v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6h14zM10 11v6M14 11v6" stroke="#A0A4A8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
</template>

<script>
export default {
  name: 'SvgTrash',
  props: {
    fill: {
      type: String,
      required: false,
      default: '#b4b4b4',
    },
  },
};
</script>
