import { setItemToLocalStorage } from '@/helpers';
import i18n from '@/i18n';

export default {
  namespaced: true,
  state: {
    list: [],
  },
  getters: {
    lengthOfWishlist(state) {
      return state.list.length ? state.list.length : 0;
    },
  },
  mutations: {
    ADD_TO_WISHLIST(state, payload) {
      if (state.list.length === 0 && Array.isArray(state.list)) {
        state.list.push(payload);
        setItemToLocalStorage('wishlistItems', JSON.stringify(state.list));
        return;
      }

      !state.list.includes(payload) ? state.list.push(payload) : false;
      setItemToLocalStorage('wishlistItems', JSON.stringify(state.list));
    },
    SET_WISHLIST(state) {
      const cartItems = JSON.parse(localStorage.getItem('wishlistItems')) ? JSON.parse(localStorage.getItem('wishlistItems')) : [];
      state.list = cartItems;
    },
    CLEAR_WISHLIST(state) {
      state.list = [];
      setItemToLocalStorage('wishlistItems', null);
    },
    DELETE_WISHLIST_ITEM(state, payload) {
      state.list = state.list.filter((item) => item.id !== payload);
      setItemToLocalStorage('wishlistItems', JSON.stringify(state.list));
    },
  },
  actions: {
    addToWishlist({ commit, dispatch }, payload) {
      commit('ADD_TO_WISHLIST', payload);

      dispatch('notification/spawn', {
        type: 'success',
        title: i18n.t('apiHandler.success.title'),
        text: i18n.t('wishlist.added'),
      }, { root: true });
    },
    getWishlistItems({ commit }) {
      commit('SET_WISHLIST');
    },
    clearWishlist({ commit }) {
      commit('CLEAR_WISHLIST');
    },
    deleteWishlistItem({ commit }, payload) {
      commit('DELETE_WISHLIST_ITEM', payload);
    },
    moveToCart({ dispatch, commit }, payload) {
      const isArray = Array.isArray(payload);

      if (isArray) {
        payload.forEach((item) => {
          dispatch('cart/addToCart', item, { root: true });
          commit('CLEAR_WISHLIST');
        });
      } else {
        dispatch('cart/addToCart', payload, { root: true });
        commit('DELETE_WISHLIST_ITEM', payload.id);
      }
    },
  },
};
