<template>
  <section class="course-video-player">
    <video
      class="course-video-player__inner video-js vjs-16-9"
      crossorigin="anonymous"
      ref="videoPlayer"
      id="video"
      :poster="videoPoster"
    />
  </section>
</template>

<script>
/* eslint-disable prefer-const */
import videojs from 'video.js';
import course from '@/api/services/course';
import config from '@/config';

import { uuid } from '@/helpers';
import { mapState } from 'vuex';

import 'video.js/dist/video-js.min.css';

export default {
  name: 'CourseVideoPlayer',
  data: () => ({
    player: null,
    options: {
      controls: true,
      textTrackSettings: false,
    },
    activeCaptions: [],
    interval: false,
    watchedTime: 0,
  }),
  props: {
    sources: {
      type: Array,
      required: true,
    },
    captions: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    videoPoster: {
      type: String,
      required: true,
    },
    videoID: {
      type: String,
      required: false,
    },
    courseID: {
      type: String,
      required: false,
    },
    lessonID: {
      type: String,
      required: false,
    },
    mode: {
      type: String,
      default: 'normal',
    },
  },
  watch: {
    captions() {
      this.captions.length > 0 ? this.getCaptions() : false;
    },
  },
  computed: {
    ...mapState({
      token: (state) => state.auth.token,
      locales: (state) => state.language.languagesList,
      currentLanguage: (state) => state.language.currentLanguage,
    }),
    currentVideoTime() {
      return this.player.currentTime();
    },
    videoDuration() {
      return this.player.duration();
    },
  },
  methods: {
    async setAsWatched() {
      try {
        await course.setAsWatchedLesson({
          courseId: this.courseID,
          lessonId: this.lessonID,
        });

        this.$emit('lesson-completed', { lessonId: this.lessonID });
      } catch (err) {
        console.log(err);
      }
    },
    getCaptions() {
      setTimeout(() => {
        this.captions.forEach((caption) => {
          this.player.addRemoteTextTrack({
            src: `${config.assetServerURL}/assets/${this.courseID}/${this.lessonID}/subtitles/${caption.locale}?jwt=${this.token}`,
            label: caption.locale,
            kind: 'subtitles',
            srclang: caption.locale,
          }, false);

          this.activeCaptions.push({
            src: `${config.assetServerURL}/assets/${this.courseID}/${this.lessonID}/subtitles/${caption.locale}?jwt=${this.token}`,
            label: caption.locale,
            kind: 'subtitles',
            srclang: caption.locale,
          });
          for (let i = 0; i < this.player.textTracks().length; i += 1) {
            const track = this.player.textTracks()[i];
            if (track.language.toLowerCase() === this.currentLanguage.key.toString().toLowerCase()) {
              track.mode = 'showing';
              break;
            }
          }
        });
      }, 1000);
    },
    destroyVideo() {
      this.player.dispose();
      this.player = null;
    },
    renderVideo() {
      // Create new DOM element
      const id = uuid();
      const wrapper = document.querySelector('.video-player');
      const newVideoHTML = `
        <video
          class="video-player__inner video-js vjs-16-9"
          id="${id}"
        />
      `;

      // Insert new element to wrapper
      wrapper.innerHTML = newVideoHTML;
      const element = document.getElementById(id);

      // Initiation of video.js
      this.player = videojs(element, this.options);
    },
  },
  mounted() {
    this.options.sources = this.sources;

    setTimeout(() => {
      this.player = videojs(this.$refs.videoPlayer, this.options);
      // this.player.controlBar.addChild('QualitySelector');

      // Set events for watching player
      this.player.on('loadstart', () => {
        this.getCaptions();
      });

      this.player.on('ended', () => {
        this.setAsWatched();
      });
    }, 500);
  },
  beforeDestroy() {
    this.player ? this.player.dispose() : false;
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" src="./CourseVideoPlayer.scss"/>
