import { uuid } from '@/helpers/';
import i18n from '@/i18n';

export default {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    CLOSE_NOTIFICATION(state, payload) {
      state.list = state.list.filter((item) => item.id !== payload);
    },
    ADD_NOTIFICATION(state, payload) {
      state.list.push({
        id: uuid(),
        ...payload,
      });
    },
  },
  actions: {
    close({ commit }, payload) {
      commit('CLOSE_NOTIFICATION', payload);
    },
    spawn({ commit }, payload) {
      const setNotification = () => {
        switch (payload.errorMessage) {
          case 'course_already_bought':
            return { title: i18n.t('apiHandler.courseBought.title'), text: i18n.t('apiHandler.courseBought.text') };
          case 'course_already_paid':
            return { title: i18n.t('apiHandler.courseBought.title'), text: i18n.t('apiHandler.coursePaid.text') };
          case 'user_already_exists':
            return { title: i18n.t('apiHandler.userExists.title'), text: i18n.t('apiHandler.userExists.text') };
          case 'email_already_exists':
            return { title: i18n.t('apiHandler.userExists.title'), text: i18n.t('apiHandler.userExists.text') };
          case 'invalid_email':
            return { title: i18n.t('apiHandler.invalidEmail.title'), text: i18n.t('apiHandler.invalidEmail.text') };
          case 'payment_error':
            return { title: i18n.t('apiHandler.payment.title'), text: i18n.t('apiHandler.payment.text') };
          case 'not_processed_yet':
            return { title: i18n.t('apiHandler.notProcessed.title'), text: i18n.t('apiHandler.notProcessed.text') };
          case 'not_enough_lessons':
            return { title: i18n.t('apiHandler.notEnoughLessons.title'), text: i18n.t('apiHandler.notEnoughLessons.text') };
          case 'wrong_password':
            return { title: i18n.t('apiHandler.error.title'), text: i18n.t('apiHandler.password.text') };
          case 'invalid_encoded_image':
            return { title: i18n.t('apiHandler.invalidImage.title'), text: i18n.t('apiHandler.invalidImage.text') };
          case 'no_user':
            return { title: i18n.t('apiHandler.noUser.title'), text: i18n.t('apiHandler.noUser.text') };
          case 'token_invalid':
            return { title: i18n.t('apiHandler.token.title'), text: i18n.t('apiHandler.token.text') };
          case 'paypal_receiver_error':
            return { title: i18n.t('apiHandler.payPal.title'), text: i18n.t('apiHandler.payPal.text') };
          case 'old_password_incorrect':
            return { title: i18n.t('apiHandler.wrongOldPassword.title'), text: i18n.t('apiHandler.wrongOldPassword.text') };
          default:
            return { title: i18n.t('apiHandler.error.title'), text: `${i18n.t('apiHandler.error.text')}${payload.errorMessage && `: ${payload.errorMessage}`}` };
        }
      };
      const notification = payload.type === 'error' ? { ...payload, ...setNotification() } : payload;
      commit('ADD_NOTIFICATION', notification);
    },
  },
};
