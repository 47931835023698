<template>
  <footer class="main-footer" v-show="!isLoading">
    <div class="container container--big">
      <div class="main-footer__inner">
        <div class="main-footer__content">
          <img class="main-footer__logo" alt="Edunails" src="/logo.svg" />
        </div>
        <nav class="main-footer__menu">
          <div
            class="main-footer-column"
            v-for="item in menu"
            :key="item.heading"
          >
            <h6 class="main-footer-column__heading">
              {{ item.heading }}
            </h6>
            <ul class="main-footer-column__list">
              <li
                class="main-footer-column__list-item"
                v-for="subItem in item.subMenu"
                :key="subItem.name"
              >
                <a
                  v-if="subItem.isNormalLink"
                  :href="subItem.link"
                  :title="subItem.name"
                  :aria-label="subItem.name"
                  class="main-footer-column__list-link main-footer-column__list-link--with-icon"
                  :target="subItem.inNewTab ? '_blank' : ''"
                  :rel="subItem.inNewTab ? 'noopener noreferrer' : ''"
                >
                  <img
                    class="main-footer-column__list-icon"
                    alt
                    :src="`/assets/icons/${subItem.icon}.svg`"
                    v-if="subItem.icon"
                  />
                  {{ subItem.name }}
                </a>
                <router-link
                  v-else
                  :to="subItem.link"
                  :title="subItem.name"
                  :aria-label="subItem.name"
                  class="main-footer-column__list-link"
                >
                  {{ subItem.name }}
                </router-link>
              </li>
            </ul>
          </div>
        </nav>
        <div class="main-footer__additional">
          <ul class="main-footer__socials">
            <li
              class="main-footer__socials-item"
              v-for="item in socials"
              :key="item.name"
            >
              <a
                class="main-footer__socials-link"
                :aria-label="item.name"
                :title="item.name"
                :href="item.link"
              >
                <img
                  class="main-footer__socials-icon"
                  alt
                  :src="`/assets/socials/${item.icon}.svg`"
                />
              </a>
            </li>
          </ul>
          <p class="main-footer__copyright">
            © 2020 Edunails. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'MainFooter',
  computed: {
    isLoading() {
      return this.$store.state.ui.preloaderStatus;
    },
    socials() {
      return [
        {
          name: 'Facebook',
          icon: 'fb',
          link: 'https://www.facebook.com/Edunailsofficial-101086191589499/',
        },
        {
          name: 'Instagram',
          icon: 'ig',
          link: 'https://www.instagram.com/edunails.official/',
        },
      ];
    },
    menu() {
      return [
        {
          heading: this.$t('footer.menu'),
          subMenu: [
            {
              name: this.$t('footer.home'),
              link: '/',
              icon: null,
              isNormalLink: false,
            },
            {
              name: this.$t('footer.courses'),
              link: '/courses',
              icon: null,
              isNormalLink: false,
            },
            {
              name: this.$t('shop.label'),
              link: '/shop',
              icon: null,
              isNormalLink: false,
            },
            {
              name: 'Tutorial',
              link: '/tutorial',
              icon: null,
              isNormalLink: false,
            },
            {
              name: this.$t('footer.for users'),
              link: '/#for-users',
              icon: null,
              isNormalLink: false,
            },
            {
              name: this.$t('footer.for authors'),
              link: '/#for-authors',
              icon: null,
              isNormalLink: false,
            },
            {
              name: 'FAQ',
              link: '/faq',
              icon: null,
              isNormalLink: false,
            },
            {
              name: this.$t('footer.blog'),
              link: '/blog',
              icon: null,
              isNormalLink: false,
            },
          ],
        },
        {
          heading: this.$t('footer.edunails'),
          subMenu: [
            {
              name: this.$t('footer.about'),
              link: '/about-us',
              icon: null,
              isNormalLink: false,
            },
            {
              name: this.$t('footer.contact'),
              link: '/contact',
              icon: null,
              isNormalLink: false,
            },
            {
              name: this.$t('footer.privacy'),
              link: '/privacy-policy-edunails.pdf',
              icon: null,
              isNormalLink: true,
              inNewTab: true,
            },
            {
              name: this.$t('footer.rules'),
              link: '/terms.pdf',
              icon: null,
              isNormalLink: true,
              inNewTab: true,
            },
            {
              name: this.$t('footer.returns'),
              link: '/returns-policy.pdf',
              icon: null,
              isNormalLink: true,
              inNewTab: true,
            },
          ],
        },
        {
          heading: this.$t('footer.contact'),
          subMenu: [
            {
              name: 'info@edunails.com',
              link: 'mailto:info@edunails.com',
              icon: 'mail',
              isNormalLink: true,
            },
          ],
        },
      ];
    },
  },
};
</script>

<style lang="scss" src="./MainFooter.scss" />
