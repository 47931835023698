var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isFiltered),expression:"isFiltered"}],staticClass:"base-option",class:[
    ..._vm.classes,
    _vm.divinder ? 'base-option--divinder' : '',
  ]},[_c('button',{staticClass:"base-option__inner",class:[
      _vm.isSelected ? 'base-option--selected' : '',
      _vm.disabled ? 'base-option--disabled' : '',
      _vm.isPickerMode ? 'base-option--picker' : '',
    ],attrs:{"type":"button"},on:{"click":function($event){_vm.$emit('click'), _vm.handleClick()},"blur":function($event){return _vm.$emit('blur')}}},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }