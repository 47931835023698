<template>
  <div
    class="base-accordion-item"
    :class="$parent.separated ? 'base-accordion-item--separated' : ''"
  >
    <div
      class="base-accordion-item__trigger"
      :class="[
        isActive ? 'base-accordion-item__trigger--active' : false,
        $parent.separated ? 'base-accordion-item__trigger--separated' : ''
      ]"
      @click="handleEnterClick()"
      :aria-controls="`base-accordion-item-${name}`"
      :aria-expanded="isActive ? 'true' : 'false'"
      :aria-disabled="accordion.disabled"
    >
      <div class="base-accordion-item__trigger-content" v-show="!hasHeaderSlot">
        <span class="base-accordion-item__trigger-label" v-show="label">
          {{ label }}
        </span>

        <p class="base-accordion-item__trigger-text">
          {{ this.name }}
        </p>
      </div>

      <div class="base-accordion-item__trigger-content" v-show="hasHeaderSlot">
        <slot name="header"></slot>
      </div>

      <div class="base-accordion-item__trigger-right">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18.385"
          height="18.385"
          viewBox="0 0 18.385 18.385"
          v-if="isCompleted"
          class="base-accordion-item__trigger-is-completed"
        >
          <path
            id="Path_108"
            data-name="Path 108"
            d="M9.192,0A9.192,9.192,0,1,1,0,9.192,9.192,9.192,0,0,1,9.192,0Z"
            fill="#48efa7"
          />
          <path
            id="comment-verify"
            d="M18.418,13.765,14.062,18.13l-1.7-1.713a.925.925,0,0,0-1.308,1.308l2.358,2.358a.921.921,0,0,0,1.308,0l5.01-5.01a.925.925,0,1,0-1.308-1.308Z"
            transform="translate(-6.153 -7.456)"
            fill="#fff"
          />
        </svg>
        <button
          class="base-accordion-item__trigger-delete"
          :class="
            disabled ? 'base-accordion-item__trigger-delete--disabled' : ''
          "
          v-if="deleteIcon"
          @click.stop="$emit('delete')"
          type="button"
          :disabled="disabled"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            class="base-accordion-item__trigger-delete-icon"
          >
            <g
              id="Group_2220"
              data-name="Group 2220"
              transform="translate(-964.994 -2432.519)"
            >
              <circle
                id="Ellipse_33"
                data-name="Ellipse 33"
                cx="12.5"
                cy="12.5"
                r="12.5"
                transform="translate(964.994 2432.519)"
                fill="#f2f2f2"
              />
              <g
                id="Group_2221"
                data-name="Group 2221"
                transform="translate(971.907 2438.915)"
              >
                <g id="Group_46" data-name="Group 46">
                  <path
                    id="Path_33"
                    data-name="Path 33"
                    d="M194.734,648.843H190.3v8.509a2.329,2.329,0,0,0,2.329,2.329h4.21a2.329,2.329,0,0,0,2.329-2.329v-8.509Z"
                    transform="translate(-189.316 -646.873)"
                    fill="rgba(0,0,0,0)"
                    stroke="#b4b4b4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.3"
                  />
                  <line
                    id="Line_9"
                    data-name="Line 9"
                    y2="4.926"
                    transform="translate(3.941 4.926)"
                    fill="none"
                    stroke="#b4b4b4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.3"
                  />
                  <line
                    id="Line_10"
                    data-name="Line 10"
                    y2="4.926"
                    transform="translate(6.897 4.926)"
                    fill="none"
                    stroke="#b4b4b4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.3"
                  />
                  <line
                    id="Line_11"
                    data-name="Line 11"
                    x2="10.838"
                    transform="translate(0 1.97)"
                    fill="none"
                    stroke="#b4b4b4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.3"
                  />
                  <path
                    id="Path_34"
                    data-name="Path 34"
                    d="M197.765,648.275l-.579-.927a2.22,2.22,0,0,0-1.882-1.043h0a2.219,2.219,0,0,0-1.882,1.043l-.58.927"
                    transform="translate(-189.883 -646.305)"
                    fill="rgba(0,0,0,0)"
                    stroke="#b4b4b4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.3"
                  />
                </g>
              </g>
            </g>
          </svg>
        </button>
        <svg
          class="base-accordion-item__icon"
          :style="{
            transform: `rotate(${isActive ? 180 : 0}deg)`
          }"
          xmlns="http://www.w3.org/2000/svg"
          width="11.572"
          height="6.894"
          viewBox="0 0 11.572 6.894"
        >
          <path
            id="angle-right"
            d="M15.447,11.76,10.767,7.081A1.1,1.1,0,1,0,9.2,8.637l3.907,3.907L9.2,16.45a1.1,1.1,0,0,0,1.567,1.556l4.679-4.679a1.1,1.1,0,0,0,0-1.567Z"
            transform="translate(-6.754 15.773) rotate(-90)"
            fill="#132b50"
          />
        </svg>
      </div>
    </div>

    <transition
      name="expand"
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave"
    >
      <div
        :id="`base-accordion-item-${name}`"
        class="base-accordion-item__content"
        v-show="isActive"
        :aria-hidden="isActive ? 'false' : 'true'"
      >
        <div class="base-accordion-item__content-inner">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'AccordionElement',
  inject: ['accordion'],
  props: {
    name: {
      type: [String, Number],
      required: true,
    },
    label: {
      type: [String, Number],
      required: false,
    },
    deleteIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isCompleted: {
      type: [Boolean, Number],
      required: false,
      default: false,
    },
  },
  computed: {
    isActive() {
      return this.accordion.activeNames.indexOf(this.name) > -1;
    },
    transitionName() {
      return this.accordion.transitionName;
    },
    hasHeaderSlot() {
      if (this.$slots) {
        if (this.$slots.header) {
          return true;
        }
      }

      return false;
    },
  },
  methods: {
    afterEnter(element) {
      element.style.height = 'auto';
    },
    enter(element) {
      const { width } = getComputedStyle(element);
      element.style.width = width;
      element.style.position = 'absolute';
      element.style.visibility = 'hidden';
      element.style.height = 'auto';

      const { height } = getComputedStyle(element);
      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;
      getComputedStyle(element).height;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    leave(element) {
      const { height } = getComputedStyle(element);
      element.style.height = height;
      getComputedStyle(element).height;

      setTimeout(() => {
        element.style.height = 0;
      });
    },
    handleEnterClick() {
      if (this.accordion.disabled) {
        return;
      }
      this.dispatch('Accordion', 'item-click', this);
    },
    dispatch(componentName, eventName, params) {
      let parent = this.$parent || this.$root;
      let name = parent.$options.componentName;

      while (parent && (!name || name !== componentName)) {
        parent = parent.$parent;
        if (parent) {
          name = parent.$options.componentName;
        }
      }

      if (parent) {
        parent.$emit.apply(parent, [eventName].concat(params));
      }
    },
  },
};
</script>

<style lang="scss" src="./BaseAccordionItem.scss" />
