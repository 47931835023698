import Vue from 'vue';
import store from '@/store';

Vue.filter('price', (value) => {
  return `${value} ${store.state.language.currentLanguage.currency}`;
});

Vue.filter('shortText', (value, limit) => {
  return (value.length <= limit) ? value : `${value.slice(0, limit)}...`;
});
