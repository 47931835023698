<template>
  <h3
    class="base-sub-heading"
    :class="[
      align === 'center' ? 'base-sub-heading--center' : false,
      align === 'left' ? 'base-sub-heading--left' : false,
    ]"
  >
    <slot></slot>
  </h3>
</template>

<script>
export default {
  name: 'BaseSubHeading',
  props: {
    align: {
      type: String,
      required: false,
      default: 'left',
    },
  },
};
</script>

<style lang="scss" src="./BaseSubHeading.scss" />
