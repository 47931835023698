import { render, staticRenderFns } from "./CountryLabel.vue?vue&type=template&id=73e693f1&scoped=true&"
import script from "./CountryLabel.vue?vue&type=script&lang=js&"
export * from "./CountryLabel.vue?vue&type=script&lang=js&"
import style0 from "./CountryLabel.scss?vue&type=style&index=0&id=73e693f1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.1_cache-loader@4.1.0_css-loader@3.6.0_webpack@4.46.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73e693f1",
  null
  
)

export default component.exports