export const escKey = () => ({
  data: () => ({

  }),
  methods: {
    handleEscClick({ key }) {
      key === 'Escape' ? this.$store.dispatch('ui/closeAll') : false;
    },
  },
  mounted() {
    document.addEventListener('keydown', this.handleEscClick);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleEscClick);
  },
});
