<template>
  <svg viewBox="25 25 50 50" class="loader">
    <circle
      cx="50"
      cy="50"
      r="20"
      fill="none"
      class="loader__spinner"
      :class="[`loader__spinner--${color}`, thin && 'loader__spinner--thin']"
    />
  </svg>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    color: {
      type: String,
      required: false,
      default: 'blue',
    },
    thin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped src="./Loader.scss" />
