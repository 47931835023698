<template>
  <button
    class="base-chart-icon"
    :aria-label="$t('cart.open')"
    :title="$t('cart.open')"
    @click="openCart()"
  >
    <img
      class="base-chart-icon__image"
      alt=""
      src="/assets/icons/chart.svg"
    />
    <span class="base-chart-icon__label" v-if="lengthOfCart > 0">
      {{ lengthOfCart }}
    </span>
  </button>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BaseChartIcon',
  computed: {
    ...mapGetters({
      lengthOfCart: 'cart/lengthOfCart',
    }),
  },
  methods: {
    openCart() {
      this.$store.dispatch('ui/setCart', true);
    },
  },
};
</script>

<style lang="scss" src="./BaseChartIcon.scss" />
