<template>
  <validation-provider
    class="base-select"
    @keyup.esc="close()"
    @keydown.down.prevent="handleKeyDown()"
    @keydown.up.prevent="handleKeyDown()"
    ref="provider"
    tag="div"
    :rules="rules"
    v-slot="{ errors }"
  >
    <label class="base-select__label" :for="selectID" v-if="label">
      {{ label }}
    </label>

    <!-- Input for triggering VeeValidate validation -->
    <input
      :disabled="disabled"
      :value="value"
      :name="name"
      type="search"
      :autocomplete="autocomplete"
      class="base-select__hidden-input"
    />

    <!-- Search mode -->
    <div class="base-select__inner-wrapper" v-if="mode === 'search'">
      <input
        :disabled="disabled"
        :id="selectID"
        :placeholder="placeholder"
        :class="[
          isActive ? 'base-select__inner--active' : '',
          placeholderAsText ? 'base-select__inner--selected-placeholder' : '',
          thin ? 'base-select__inner--thin' : ''
        ]"
        class="base-select__inner base-select__inner--input"
        type="search"
        :autocomplete="autocomplete"
        v-model="search"
        @input="makeSearch($event)"
        @blur="handleBlur()"
        @focus="isActive = true"
        @keyup.esc="close()"
        @keydown.down.prevent="handleKeyDown()"
        @keydown.up.prevent="handleKeyDown()"
      />

      <template v-if="hasHeaderSlot">
        <slot name="header"></slot>
      </template>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11.572"
        height="6.894"
        viewBox="0 0 11.572 6.894"
        class="base-select__arrow"
        :style="{ transform }"
      >
        <path
          id="angle-right"
          d="M15.447,11.76,10.767,7.081A1.1,1.1,0,1,0,9.2,8.637l3.907,3.907L9.2,16.45a1.1,1.1,0,0,0,1.567,1.556l4.679-4.679a1.1,1.1,0,0,0,0-1.567Z"
          transform="translate(-6.754 15.773) rotate(-90)"
          fill="#132b50"
        />
      </svg>
    </div>

    <!-- Normal mode -->
    <button
      :disabled="disabled"
      type="button"
      class="base-select__inner"
      :class="[
        isActive ? 'base-select__inner--active' : '',
        value ? 'base-select__inner--selected' : '',
        mode === 'picker' ? 'base-select__inner--picker' : '',
        errors[0] ? 'base-select__inner--error' : '',
        thin ? 'base-select__inner--thin' : ''
      ]"
      @click="handleSelectOpen()"
      aria-controls="base-select__content"
      :aria-label="helpfulText"
      :title="helpfulText"
      :aria-expanded="isActive ? 'true' : 'false'"
      aria-haspopup="listbox"
      :id="selectID"
      v-else
      :value="value"
    >
      <template v-if="hasHeaderSlot">
        <slot name="header"></slot>
      </template>
      <template v-else>
        {{ value ? value.label : placeholder }}
      </template>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11.572"
        height="6.894"
        viewBox="0 0 11.572 6.894"
        class="base-select__arrow"
        :style="{ transform }"
      >
        <path
          id="angle-right"
          d="M15.447,11.76,10.767,7.081A1.1,1.1,0,1,0,9.2,8.637l3.907,3.907L9.2,16.45a1.1,1.1,0,0,0,1.567,1.556l4.679-4.679a1.1,1.1,0,0,0,0-1.567Z"
          transform="translate(-6.754 15.773) rotate(-90)"
          fill="#132b50"
        />
      </svg>
    </button>

    <!-- List of elements -->
    <transition name="dropdown-fade">
      <ul
        class="base-select__content"
        v-if="isActive"
        aria-label="Kontent listy"
        role="listbox"
        tabindex="-1"
      >
        <slot name="items"></slot>
        <li class="base-select__no-results" v-if="isEmpty">
          {{ $t("no results") }}
        </li>
      </ul>
    </transition>
    <transition
      name="page-fade"
      @enter="$emit('error', name)"
      @leave="$emit('clear-error', name)"
    >
      <span class="base-select__error" v-if="errors[0]">
        {{ errors[0] }}
      </span>
    </transition>
  </validation-provider>
</template>

<script>
import { uuid } from '@/helpers';
import debounce from 'lodash.debounce';

export default {
  name: 'BaseSelect',
  provide() {
    return {
      select: this,
    };
  },
  data: () => ({
    isActive: false,
    selected: '',
    search: null,
    options: [],
    filteredOptions: [],
    isEmpty: false,
    error: '',
    placeholderAsText: false,
  }),
  props: {
    rules: {
      type: [String, Object],
      required: false,
      default: '',
    },
    value: {
      type: [String, Object],
      required: false,
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
    helpfulText: {
      type: String,
      required: false,
      default: 'Otwórz listę',
    },
    autocomplete: {
      type: String,
      required: false,
      default: '',
    },
    mode: {
      type: String,
      required: false,
      default: 'default',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    thin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    filteredOptions() {
      this.filteredOptions.length === 0
        ? (this.isEmpty = true)
        : (this.isEmpty = false);
    },
    placeholder() {
      this.placeholderAsText = true;
    },
  },
  computed: {
    selectID() {
      return uuid();
    },
    hasSlot() {
      if (this.$slots.items) {
        return this.$slots.items.length > 0;
      }

      return false;
    },
    hasHeaderSlot() {
      if (this.$slots) {
        if (this.$slots.header) {
          return true;
        }
      }

      return false;
    },
    transform() {
      return this.isActive
        ? 'rotate(180deg) translateY(-50%)'
        : 'rotate(0deg) translateY(-50%)';
    },
    placeholder() {
      if (this.selected) {
        return this.selected.label;
      }

      return this.text || this.$t('select');
    },
  },
  methods: {
    handleSelectOpen() {
      if (!this.disabled) {
        this.isActive = !this.isActive;
        this.$emit('click');
      }
    },
    close() {
      this.isActive = false;

      // if (this.required) {
      //   const result = await validate(this.selected, 'required', {
      //     name: this.label || 'Select',
      //   });

      //   if (result.errors[0]) {
      //     [this.error] = result.errors;
      //   }
      // }
    },
    async selectOption(payload) {
      await this.close();
      this.selected = payload;
      this.$emit('change', payload);
      this.$emit('input', payload);
      this.search = '';
      this.error = '';
    },
    makeSearch: debounce(function filter(event) {
      const focused = document.activeElement;
      if (this.options.length > 0) {
        this.filteredOptions = this.options.filter((item) => item.label.toLowerCase().includes(this.search.toLowerCase()),
        );
        if (
          this.filteredOptions.length === 1
          && focused.id !== event.target.id
        ) {
          this.handleBlur();
        }
      }
    }, 100),
    handleOutsideClick({ target }) {
      const isClickInside = this.$el.contains(target);

      if (!isClickInside) {
        this.close();
      }
    },
    handleKeyDown() {
      this.isActive ? this.$emit('keydown') : (this.isActive = true);
    },
    handleBlur() {
      if (this.filteredOptions.length === 1) {
        this.selectOption(this.filteredOptions[0]);
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleOutsideClick);
  },
  created() {
    if (typeof this.value === 'object') {
      this.selected = this.value;
    }
  },
};
</script>

<style lang="scss" src="./BaseSelect.scss" />
