<template>
  <section class="section-testimonials">
    <div class="container">
      <img class="section-testimonials__dots" alt="" src="/assets/images/dots.svg" />
      <base-sub-heading class="section-testimonials__header-sub-heading">
        <span v-html="subTitle" />
      </base-sub-heading>
      <div class="section-testimonials__row">
        <base-heading class="section-testimonials__header-heading" bold="bold">
          <span v-html="title" />
        </base-heading>
        <div class="section-testimonials__controls">
          <button class="section-testimonials__control section-testimonials__control--left" @click="prevSlide">
            <img src="/assets/icons/arrow-right.svg" :alt="$t('arrow')" />
          </button>
          <button class="section-testimonials__control section-testimonials__control--right" @click="nextSlide">
            <img src="/assets/icons/arrow-right.svg" :alt="$t('arrow')" />
          </button>
        </div>
      </div>
      <hooper
        ref="carousel"
        style="height:100%"
        :settings="settings"
        class="section-testimonials__hooper"
      >
        <slide
          v-for="(testimonial, index) in testimonials"
          :key="testimonial.name"
          :index="index"
        >
          <testimonial v-bind="testimonial" :index="index" />
        </slide>
      </hooper>
    </div>
  </section>
</template>

<script>
import { Hooper, Slide } from 'hooper';

export default {
  name: 'SectionTestimonials',
  components: {
    Hooper,
    Slide,
  },
  data: () => ({
    settings: {
      itemsToShow: 1,
      wheelControl: false,
      trimWhiteSpace: true,
      breakpoints: {
        476: {
          itemsToShow: 1.5,
        },
        768: {
          itemsToShow: 2.2,
        },
        1024: {
          itemsToShow: 2.5,
        },
      },
    },
  }),
  methods: {
    prevSlide() {
      this.$refs.carousel.slidePrev();
    },
    nextSlide() {
      this.$refs.carousel.slideNext();
    },
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
    testimonials: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.$refs.carousel.update();
  },
};
</script>
<style lang="scss" scoped src="./SectionTestimonials.scss" />
<style lang="scss">
.section-testimonials__hooper {
  div {
    overflow: unset;
  }
}
</style>
