<template>
  <div
    class="base-multi-select"
    @keyup.esc="close()"
    @keydown.down.prevent="handleKeyDown()"
    @keydown.up.prevent="handleKeyDown()"
  >
    <label class="base-multi-select__label" :for="selectID" v-if="label">
      {{ label }}
    </label>

    <!-- Search mode -->
    <div class="base-multi-select__inner-wrapper" v-if="mode === 'search'">
      <input
        class="base-multi-select__inner base-multi-select__inner--input"
        :class="[
          isActive ? 'base-multi-select__inner--active' : '',
          hasSelectedOptions ? 'base-multi-select__inner--selected' : ''
        ]"
        v-model="search"
        @input="makeSearch()"
        @focus="isActive = true"
        :id="selectID"
        :placeholder="placeholder"
      />
      <button
        class="base-multi-select__delete"
        @click="resetSelect()"
        v-if="hasSelectedOptions"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
        >
          <g
            id="Group_2213"
            data-name="Group 2213"
            transform="translate(-965.994 -2434)"
          >
            <circle
              id="Ellipse_33"
              data-name="Ellipse 33"
              cx="7"
              cy="7"
              r="7"
              transform="translate(965.994 2434)"
              fill="#f2f2f2"
            />
            <path
              id="angle-right"
              d="M3.38,2.577.972.168a.568.568,0,0,0-.807.8L2.176,2.98.165,4.991a.568.568,0,0,0,.807.8L3.38,3.383a.568.568,0,0,0,0-.807Z"
              transform="translate(976.081 2438.017) rotate(90)"
              fill="#b4b4b4"
            />
            <path
              id="angle-right-2"
              data-name="angle-right"
              d="M3.38,2.577.972.168a.568.568,0,0,0-.807.8L2.176,2.98.165,4.991a.568.568,0,0,0,.807.8L3.38,3.383a.568.568,0,0,0,0-.807Z"
              transform="translate(970.125 2444.139) rotate(-90)"
              fill="#b4b4b4"
            />
          </g>
        </svg>
      </button>
      <button
        class="base-multi-select__arrow-button"
        @click="isActive = !isActive"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11.572"
          height="6.894"
          viewBox="0 0 11.572 6.894"
          class="base-multi-select__arrow"
          :style="{ transform }"
        >
          <path
            id="angle-right"
            d="M15.447,11.76,10.767,7.081A1.1,1.1,0,1,0,9.2,8.637l3.907,3.907L9.2,16.45a1.1,1.1,0,0,0,1.567,1.556l4.679-4.679a1.1,1.1,0,0,0,0-1.567Z"
            transform="translate(-6.754 15.773) rotate(-90)"
            fill="#132b50"
          />
        </svg>
      </button>
    </div>

    <!-- Normal mode -->
    <button
      type="button"
      class="base-multi-select__inner"
      :class="[
        isActive ? 'base-multi-select__inner--active' : '',
        selected.length > 0 ? 'base-multi-select__inner--selected' : '',
        mode === 'picker' ? 'base-multi-select__inner--picker' : ''
      ]"
      @click="(isActive = !isActive), $emit('click')"
      aria-controls="base-multi-select__content"
      :aria-label="helpfulText"
      :title="helpfulText"
      :aria-expanded="isActive ? 'true' : 'false'"
      aria-haspopup="listbox"
      :id="selectID"
      v-else
    >
      <template v-if="hasHeaderSlot">
        <slot name="header"></slot>
      </template>
      <template v-else>{{ placeholder }}</template>
      <button
        class="base-multi-select__delete"
        @click="resetSelect()"
        v-if="selected.length > 0"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
        >
          <g
            id="Group_2213"
            data-name="Group 2213"
            transform="translate(-965.994 -2434)"
          >
            <circle
              id="Ellipse_33"
              data-name="Ellipse 33"
              cx="7"
              cy="7"
              r="7"
              transform="translate(965.994 2434)"
              fill="#f2f2f2"
            />
            <path
              id="angle-right"
              d="M3.38,2.577.972.168a.568.568,0,0,0-.807.8L2.176,2.98.165,4.991a.568.568,0,0,0,.807.8L3.38,3.383a.568.568,0,0,0,0-.807Z"
              transform="translate(976.081 2438.017) rotate(90)"
              fill="#b4b4b4"
            />
            <path
              id="angle-right-2"
              data-name="angle-right"
              d="M3.38,2.577.972.168a.568.568,0,0,0-.807.8L2.176,2.98.165,4.991a.568.568,0,0,0,.807.8L3.38,3.383a.568.568,0,0,0,0-.807Z"
              transform="translate(970.125 2444.139) rotate(-90)"
              fill="#b4b4b4"
            />
          </g>
        </svg>
      </button>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11.572"
        height="6.894"
        viewBox="0 0 11.572 6.894"
        class="base-multi-select__arrow"
        :style="{ transform }"
      >
        <path
          id="angle-right"
          d="M15.447,11.76,10.767,7.081A1.1,1.1,0,1,0,9.2,8.637l3.907,3.907L9.2,16.45a1.1,1.1,0,0,0,1.567,1.556l4.679-4.679a1.1,1.1,0,0,0,0-1.567Z"
          transform="translate(-6.754 15.773) rotate(-90)"
          fill="#132b50"
        />
      </svg>
    </button>

    <!-- List of elements -->
    <transition name="dropdown-fade">
      <ul
        class="base-multi-select__content"
        v-if="isActive"
        aria-label="Kontent listy"
        role="listbox"
        tabindex="-1"
      >
        <slot name="items"></slot>
        <li class="base-multi-select__no-results" v-if="isEmpty">
          {{ $t("no results") }}
        </li>
      </ul>
    </transition>
    <transition name="page-fade">
      <span class="base-select__error" v-if="error">
        {{ error }}
      </span>
    </transition>
  </div>
</template>

<script>
import { uuid } from '@/helpers';
import debounce from 'lodash.debounce';
import { validate } from 'vee-validate/dist/vee-validate.full';

export default {
  name: 'BaseMultiSelect',
  provide() {
    return {
      select: this,
    };
  },
  data: () => ({
    isActive: false,
    selected: [],
    search: null,
    options: [],
    filteredOptions: [],
    isEmpty: false,
    error: '',
  }),
  props: {
    value: {
      type: Array,
      required: true,
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    helpfulText: {
      type: String,
      required: false,
      default: 'Otwórz listę',
    },
    mode: {
      type: String,
      required: false,
      default: 'default',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    value() {
      this.$emit('change', this.selected);
    },
    filteredOptions() {
      this.filteredOptions.length === 0
        ? (this.isEmpty = true)
        : (this.isEmpty = false);
    },
    isActive() {
      if (!this.isActive) {
        this.options = [];
        this.filteredOptions = [];
        this.isEmpty = false;
      }
    },
  },
  computed: {
    selectID() {
      return uuid();
    },
    hasSlot() {
      if (this.$slots.items) {
        return this.$slots.items.length > 0;
      }

      return false;
    },
    hasHeaderSlot() {
      if (this.$slots) {
        if (this.$slots.header) {
          return true;
        }
      }

      return false;
    },
    transform() {
      return this.isActive
        ? 'rotate(180deg) translateY(-50%)'
        : 'rotate(0deg) translateY(-50%)';
    },
    placeholder() {
      if (this.selected.length === 0) {
        return this.text || this.$t('select');
      }
      if (this.selected.length === 1) {
        return this.selected[0].label;
      }
      return `${this.$t('multiSelect.chosen')} ${this.selected.length}`;
    },
    hasSelectedOptions() {
      return this.selected.length > 0;
    },
  },
  methods: {
    async close() {
      this.isActive = false;
      if (this.required) {
        const result = await validate(this.selected, 'required', {
          name: this.label || this.$t('select'),
        });

        if (result.errors[0]) {
          [this.error] = result.errors;
        }
      }
    },
    resetSelect() {
      this.selected = [];
      this.close();
      this.$emit('input', []);
    },
    selectOption(payload) {
      const isInTheArray = () => this.selected.some((option) => option.value === payload.value);
      if (!isInTheArray()) {
        this.selected.push(payload);
        this.$emit(
          'input',
          this.selected.map((option) => option.value),
        );
      }
      this.error = '';
    },
    unselectOption(payload) {
      this.selected = this.selected.filter(
        (selectedOption) => selectedOption.value !== payload.value,
      );
      this.$emit(
        'input',
        this.selected.map((option) => option.value),
      );
    },
    makeSearch: debounce(function filter() {
      if (this.options.length > 0) {
        this.filteredOptions = this.options.filter((item) => item.label.toLowerCase().includes(this.search.toLowerCase()),
        );
      }
    }, 100),
    handleOutsideClick({ target }) {
      const isClickInside = this.$el.contains(target);

      if (!isClickInside) {
        this.close();
        this.search = '';
      }
    },
    handleKeyDown() {
      this.isActive ? this.$emit('keydown') : (this.isActive = true);
    },
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleOutsideClick);
  },
  created() {
    if (Array.isArray(this.value)) {
      this.selected = this.value;
    }
  },
};
</script>

<style lang="scss" src="./BaseMultiSelect.scss" />
