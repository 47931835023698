<template>
  <div class="base-difficulty">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="15"
      viewBox="0 0 22 15"
      class="base-difficulty__inner"
    >
      <g
        id="Group_2157"
        data-name="Group 2157"
        transform="translate(-473 -1827)"
      >
        <g
          id="Rectangle_652"
          data-name="Rectangle 652"
          transform="translate(473 1836)"
          :fill="isFilled(1) ? '#fc8c9d' : '#ffdee4'"
          stroke-linecap="round"
          stroke-width="1"
        >
          <rect width="4" height="6" rx="0.7" stroke="none" />
          <rect x="0.5" y="0.5" width="3" height="5" rx="0.2" fill="none" />
        </g>
        <rect
          id="Rectangle_653"
          data-name="Rectangle 653"
          width="4"
          height="9"
          rx="0.7"
          transform="translate(479 1833)"
          :fill="isFilled(2) ? '#fc8c9d' : '#ffdee4'"
        />
        <rect
          id="Rectangle_654"
          data-name="Rectangle 654"
          width="4"
          height="12"
          rx="0.7"
          transform="translate(485 1830)"
          :fill="isFilled(3) ? '#fc8c9d' : '#ffdee4'"
        />
        <rect
          id="Rectangle_655"
          data-name="Rectangle 655"
          width="4"
          height="15"
          rx="0.7"
          transform="translate(491 1827)"
          :fill="isFilled(4) ? '#fc8c9d' : '#ffdee4'"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'BaseDifficulty',
  props: {
    difficulty: {
      type: [String, Number],
      required: false,
      default: 0,
    },
  },
  methods: {
    isFilled(lvl) {
      return this.difficulty >= lvl;
    },
  },
};
</script>

<style lang="scss" src="./BaseDifficulty.scss" />
