import request from '@/api/';
import config from '@/config';
import queryString from 'query-string';

export default {
  confirmPayment(payload) {
    return request.get(`payments/paypal/${payload}/approve`);
  },
  makePayment(payload, promoCode) {
    if (promoCode == null) promoCode = '';
    const lastPartOfLink = queryString.stringify({
      fail_url: config.checkoutURL,
      success_url: config.checkoutURL,
    });

    return request.post(`courses/pay?order_ids=${payload}&${lastPartOfLink}&promo_code=${promoCode}`);
  },
  makeOrder(payload) {
    return request.post(`courses/orders/${payload}`);
  },
  getInvoice(payload, commision = null) {
    // return request.get(`courses/orders/${ payload }/invoice?is_commission_invoice=${commision ? true : false}`, {
    //   responseType: 'blob'
    // });

    return request.get(`courses/orders/${payload}/invoice${commision ? '?is_commission_invoice=true' : ''}`, {
      responseType: 'blob',
    });
  },
  validatePromoCode(promoCode) {
    return request.get(`payments/promo/${promoCode}/check`);
  },
  getAffiliationDetails() {
    return request.get('creator/affiliation');
  },
  giveAccess(id) {
    return request.put(`admin/orders/${id}/completed`);
  },
};
