<template>
  <header class="header-search">
    <div class="container container--big">
      <img class="header-search__image" alt="" src="/assets/images/dots.svg" />

      <base-heading align="center" class="header-search__heading" bold="bold">
        <span v-html="heading" />
      </base-heading>

      <div class="header-search__actions">
        <base-button
          type="icon"
          icon="search"
          class="header-search__actions-button"
          @click="makeSearchQuery()"
        />
        <base-multi-select
          :label="$t('headerSearch.select.method')"
          helpfulText="Select Method"
          mode="search"
          v-model="methods"
        >
          <template v-slot:items>
            <base-multi-options :options="sortList(categoriesMethods.list)" />
          </template>
        </base-multi-select>
        <base-multi-select
          :label="$t('headerSearch.select.shape')"
          helpfulText="Select Shape"
          mode="search"
          v-model="shapes"
        >
          <template v-slot:items>
            <base-multi-options :options="sortList(categoriesShapes.list)" />
          </template>
        </base-multi-select>
        <base-multi-select
          :label="$t('headerSearch.select.nailArt')"
          helpfulText="Select Nail Art"
          mode="search"
          v-model="decorations"
        >
          <template v-slot:items>
            <base-multi-options :options="sortList(categoriesArt.list)" />
          </template>
        </base-multi-select>
        <base-multi-select
          :label="$t('headerSearch.select.level')"
          helpfulText="Select Level"
          mode="search"
          v-model="lvls"
        >
          <template v-slot:items>
            <base-multi-options :options="sortList(staticLevel)" />
          </template>
        </base-multi-select>
        <base-multi-select
          :label="$t('headerSearch.select.language')"
          helpfulText="Select Language"
          mode="search"
          v-model="languages"
        >
          <template v-slot:items>
            <base-multi-options :options="sortList(staticLanguage)" />
          </template>
        </base-multi-select>
        <div class="header-search__price">
          <p class="header-search__price-label">
            {{ $t("headerSearch.select.price") }}
          </p>
          <div class="header-search__slider-wrapper">
            <vue-slider
              :marks="marks"
              class="header-search__slider"
              v-model="prices"
              :max="maxPrice + 1"
              :processStyle="{ backgroundColor: '#474BFF' }"
              :dotStyle="{ borderColor: '#474BFF' }"
              :railStyle="{ backgroundColor: '#e9e9e9' }"
              :tooltip-formatter="tooltipFormatter"
            />
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Categories from '@/mixins/Categories';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';
import config from '@/config';

export default {
  name: 'HeaderSearch',
  components: {
    VueSlider,
  },
  data: () => ({
    methods: [],
    shapes: [],
    decorations: [],
    lvls: [],
    languages: [],
    prices: [],
    marks: [],
    tooltipFormatter: () => null,
    maxPrice: 0,
  }),
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
  mixins: [Categories()],
  methods: {
    makeSearchQuery() {
      const convertedMinPrice = Math.round(
        (this.prices[0] / this.currentRate) * 100,
      );
      const convertedMaxPrice = Math.round(
        (this.prices[1] / this.currentRate) * 100,
      );
      this.$emit('search', {
        manicure_methods: this.methods,
        manicure_shapes: this.shapes,
        manicure_nail_arts: this.decorations,
        difficulties: this.lvls,
        languages: this.languages,
        min_price: convertedMinPrice,
        max_price:
          this.prices[1] === this.maxPrice + 1 ? null : convertedMaxPrice,
      });
    },
    sortList(list) {
      const localList = list;

      return localList.sort((a, b) => {
        return a.label > b.label ? 1 : -1;
      });
    },
    convertPrice() {
      const convertedPrice = this.currentRate * config.maxFilterPrice;
      // number closest to multiple of 50 and divided by 15
      let maxPrice = Math.round(convertedPrice / 50) * 50;
      while (maxPrice % 15 !== 0) {
        maxPrice += 50;
      }

      this.maxPrice = maxPrice;
      this.prices = [0, maxPrice + 1];
      this.marks = [0, maxPrice / 3, (maxPrice / 3) * 2, maxPrice];

      if (!this.rates.rates[this.currentLanguage.currency]) {
        this.tooltipFormatter = (value) => (value === maxPrice + 1 ? `${maxPrice}EUR+` : `${value}EUR`);
      } else {
        this.tooltipFormatter = (value) => (value === maxPrice + 1
          ? `${maxPrice}${this.currentLanguage.currencySign}+`
          : `${value}${this.currentLanguage.currencySign}`);
      }
    },
  },
  computed: {
    ...mapGetters({
      userType: 'auth/userType',
    }),
    ...mapState({
      currentLanguage: (state) => state.language.currentLanguage,
      rates: (state) => state.auth.currencies,
    }),
    heading() {
      if (this.title) {
        return this.title;
      }
      if (this.userType === 'author') {
        return this.$t('headerSearch.heading.author');
      }
      return this.$t('headerSearch.heading.user');
    },
    subHeading() {
      if (this.userType === 'author') {
        return this.$t('headerSearch.subHeading.author');
      }
      return this.$t('headerSearch.subHeading.user');
    },
    currentRate() {
      return this.rates?.rates[this.currentLanguage.currency] || 1;
    },
    staticLevel() {
      return [
        {
          value: '1',
          label: this.$t('levels.1'),
        },
        {
          value: '2',
          label: this.$t('levels.2'),
        },
        {
          value: '3',
          label: this.$t('levels.3'),
        },
        {
          value: '4',
          label: this.$t('levels.4'),
        },
      ];
    },
    staticLanguage() {
      return [
        {
          value: 'en',
          label: this.$t('languages.en'),
        },
        {
          value: 'pl',
          label: this.$t('languages.pl'),
        },
        {
          value: 'es',
          label: this.$t('languages.es'),
        },
        {
          value: 'it',
          label: this.$t('languages.it'),
        },
        {
          value: 'de',
          label: this.$t('languages.de'),
        },
        {
          value: 'fr',
          label: this.$t('languages.fr'),
        },
        {
          value: 'ru',
          label: this.$t('languages.ru'),
        },
        {
          value: 'cs',
          label: this.$t('languages.cs'),
        },
        {
          value: 'el',
          label: this.$t('languages.el'),
        },
        {
          value: 'hu',
          label: this.$t('languages.hu'),
        },
        {
          value: 'pt',
          label: this.$t('languages.pt'),
        },
        {
          value: 'sk',
          label: this.$t('languages.sk'),
        },
        {
          value: 'sr',
          label: this.$t('languages.sr'),
        },
        {
          value: 'uk',
          label: this.$t('languages.uk'),
        },
      ];
    },
  },
  watch: {
    currentLanguage() {
      this.convertPrice();
    },
    rates() {
      this.convertPrice();
    },
  },
  created() {
    this.convertPrice();
  },
};
</script>

<style lang="scss" src="./HeaderSearch.scss" />
