<template>
  <validation-observer slim v-slot="{ handleSubmit }">
    <form class="sign-in-form" @submit.prevent="handleSubmit(onSubmit)">
      <base-input
        class="sign-in-form__input"
        v-model="user.email"
        :label="email.label"
        :placeholder="email.placeholder"
        rules="email|required"
        :name="email.label"
      />

      <base-input
        class="sign-in-form__input"
        v-model="user.password"
        :label="password.label"
        :placeholder="password.placeholder"
        rules="required|min:6"
        :name="password.label"
        type="password"
      />
      <div class="sign-in-form__flex">
        <base-checkbox class="sign-in-form__checkbox" v-model="rememberPassword">
          {{ remember }}
        </base-checkbox>

        <base-button type="text" class="sign-in-form__forgot-password" to="/forgot-password">
          {{ forgotPassword }}
        </base-button>
      </div>

      <base-button
        nativeType="submit"
        class="sign-in-form__submit"
        :is-loading="isLoading"
      >
        {{ submit }}
      </base-button>
    </form>
  </validation-observer>
</template>

<script>
export default {
  name: 'SignInForm',
  data: () => ({
    user: {
      email: '',
      password: '',
    },
    rememberPassword: true,
    isLoading: false,
  }),
  props: {
    email: {
      type: Object,
      required: true,
    },
    password: {
      type: Object,
      required: true,
    },
    remember: {
      type: String,
      required: true,
    },
    forgotPassword: {
      type: String,
      required: true,
    },
    submit: {
      type: String,
      required: true,
    },
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;

      try {
        await this.$store.dispatch('auth/signIn', {
          email: this.user.email.toLowerCase(),
          password: this.user.password,
          rememberMe: this.rememberPassword,
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped src="./SignInForm.scss" />
