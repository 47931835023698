<template>
  <nav class="mobile-menu">
    <header class="mobile-menu__header">
      <h3 class="mobile-menu__header-title">
        {{ $t("nav.account") }}
      </h3>
      <base-close-icon @click="closeMenu()" class="mobile-menu__header-close" />
    </header>

    <div class="mobile-menu__inner" :class="!isLogged && 'mobile-menu__inner--not-logged'">
      <div class="mobile-menu__content">
        <dashboard-navigation v-if="isLogged" mobile />
        <section class="mobile-menu__not-logged" v-if="!isLogged">
          <img
            src="/logo-signet.svg"
            alt="Edunails"
            class="mobile-menu__logo"
          />
          <h4 class="mobile-menu__welcome-heading">{{ $t("nav.welcome.heading") }}</h4>
          <p class="mobile-menu__welcome-text">
            {{ $t("nav.welcome.text") }}
          </p>
        </section>
      </div>

      <footer class="mobile-menu__footer">
        <div class="mobile-menu__actions" v-if="!isLogged">
          <base-button
            to="/auth/sign-in"
            :title="$t('nav.sign-in')"
            class="mobile-menu__actions-button"
            type="text"
            @click.native="closeMenu()"
          >
            {{ $t("nav.sign-in") }}
          </base-button>
          <base-button
            to="/auth/sign-up"
            :title="$t('nav.sign-up')"
            class="mobile-menu__actions-button"
            @click.native="closeMenu()"
          >
            {{ $t("nav.sign-up") }}
          </base-button>
        </div>
      </footer>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MobileMenu',
  computed: {
    ...mapGetters({
      isLogged: 'auth/isLogged',
    }),
    menu() {
      return [
        {
          name: this.$t('nav.profile'),
          link: '/dashboard',
          isNormalLink: false,
        },
        {
          name: this.$t('nav.courses'),
          link: '/my-courses',
          isNormalLink: false,
        },
        {
          name: this.$t('nav.payments'),
          link: '/payments',
          isNormalLink: false,
        },
        {
          name: this.$t('nav.settings'),
          link: '/settings',
          isNormalLink: false,
        },
      ];
    },
  },
  methods: {
    closeMenu() {
      this.$store.dispatch('ui/setMobileMenu');
    },
    logout() {
      this.$store.dispatch('auth/logout');
    },
  },
};
</script>

<style lang="scss" src="./MobileMenu.scss" />
