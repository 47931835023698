<template>
  <div class="course-summary">
    <base-notification-box
      mode="success"
      :heading="$t('editCourse.success.title')"
    >
      <p>{{ $t("editCourse.success.desc") }}</p>

      <template slot="actions">
        <div>
          <base-button
            to="/added-courses"
            type="outline-reverse"
            class="course-summary__button course-summary__button--secondary"
          >
            {{ $t("addCourse.panel") }}
          </base-button>

          <base-button
            class="course-summary__button"
            v-if="course.slug"
            :to="`/courses/${course.slug}`"
          >
            {{ $t("addCourse.show") }}
          </base-button>
        </div>
      </template>
    </base-notification-box>

    <p class="course-summary__problems">
      {{ $t("addCourse.questions") }}
      <base-button type="link" to="/contact" class="course-summary__contact">
        {{ $t("addCourse.ask") }}
      </base-button>
    </p>
  </div>
</template>
<script>
export default {
  name: 'CourseSummary',
  props: {
    course: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" src="./CourseSummary.scss" scoped />
