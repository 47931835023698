<template>
  <nav class="course-mobile-navigation">
    <transition name="page-fade">
      <div class="course-mobile-navigation__inner" v-if="!menuStatus">
        <base-button
          type="text"
          class="course-mobile-navigation__button"
          @click="switchLesson('prev')"
        >
          {{ $t("course.prev") }}
        </base-button>

        <base-button
          type="text"
          class="course-mobile-navigation__button"
          @click="switchLesson('next')"
        >
          {{ $t("course.next") }}
        </base-button>

        <base-button
          type="text"
          class="course-mobile-navigation__button course-mobile-navigation__button--lesson"
          @click="toggleMobileMenu()"
        >
          {{ $t("course.lessons") }}
          <img
            src="/assets/icons/lesson.svg"
            alt
            class="course-mobile-navigation__button-icon"
          />
        </base-button>
      </div>

      <div class="course-mobile-navigation__inner" v-if="menuStatus">
        <h3 class="course-mobile-navigation__heading">
          {{ $t("course.lessons") }}
        </h3>

        <base-button
          type="text"
          class="course-mobile-navigation__button"
          @click="toggleMobileMenu()"
        >
          <img
            src="/assets/icons/close-big.svg"
            alt
            class="course-mobile-navigation__button-icon course-mobile-navigation__button-icon--alone"
          />
        </base-button>
      </div>
    </transition>

    <!-- Menu -->
    <transition name="page-fade" mode="out-in">
      <div class="course-mobile-navigation__menu" v-if="menuStatus">
        <progress-bar
          :percent="progress"
          class="course-mobile-navigation__progress"
        />

        <like-button
          :likes="details.likes"
          :courseId="courseId"
          class="course-actions__like"
        />

        <base-button
          v-if="isCompleted && isValidCertificate"
          @click="generateCertificate"
          class="course-actions__generate"
        >
          {{ $t("course.generateCertificate") }}
        </base-button>

        <base-button
          v-if="isCompleted"
          @click="generateCertificate(true)"
          class="course-actions__generate course-actions__generate--portal"
          type="outline-reverse"
        >
          {{ $t("course.generatePortalCertificate") }}
        </base-button>

        <base-accordion
          v-model="openLessons"
          class="course-mobile-navigation__accordion"
        >
          <base-accordion-item
            class="course-mobile-navigation__accordion-item"
            v-for="(lesson, index) in lessons"
            :key="lesson.name"
            :name="lesson.name"
            :label="`${index + 1}.`"
          >
            <ul class="course-mobile-navigation__accordion-content">
              <lesson-tile
                :lesson="lesson"
                :is-locked="false"
                :is-active="isActiveLesson(lesson.id)"
                @setLesson="setLesson($event)"
              />
            </ul>
          </base-accordion-item>
        </base-accordion>
      </div>
    </transition>
  </nav>
</template>

<script>
export default {
  name: 'CourseMobileNavigation',
  data: () => ({
    menuStatus: false,
    openLessons: [],
  }),
  props: {
    lessons: {
      type: Array,
      required: false,
      default: () => [],
    },
    activeLesson: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    details: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    generateCertificate: {
      type: Function,
      required: false,
      default: () => null,
    },
    progress: {
      type: Number,
      default: 0,
      required: false,
    },
    courseId: {
      type: String,
      default: '',
      required: false,
    },
    isValidCertificate: {
      type: Boolean,
      default: false,
    },
    isCompleted: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  watch: {
    activeLesson() {
      if (Object.keys(this.activeLesson).length) {
        this.setActiveLesson(this.activeLesson.name);
      }
    },
  },
  methods: {
    isActiveLesson(lessonID) {
      if (this.activeLesson) {
        return this.activeLesson.id === lessonID;
      }

      return false;
    },
    switchLesson(type) {
      this.$emit('switch-lesson', type);
    },
    toggleMobileMenu() {
      this.menuStatus = !this.menuStatus;
    },
    setLesson(lesson) {
      this.$emit('set-lesson', lesson);
      this.toggleMobileMenu();
    },
    setActiveLesson(lessonName) {
      this.openLessons = [lessonName];
    },
  },
  created() {
    this.openLessons.push(this.activeLesson.name);
  },
};
</script>

<style lang="scss" src="./CourseMobileNavigation.scss" />
