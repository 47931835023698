<template>
  <li class="base-notification" :class="`base-notification--${mode}`">
    <figure class="base-notification__icon">
      <img
        class="base-notification__icon-inner"
        alt=""
        :src="`/assets/icons/notification-new-${mode}.svg`"
      />
    </figure>
    <div class="base-notification__content">
      <h6 class="base-notification__content-title">
        {{ title }}
      </h6>
      <p class="base-notification__content-text">
        {{ text }}
      </p>
    </div>
    <button class="base-notification__close" @click="close()" type="button">
      <img
        class="base-notification__close-inner"
        alt=""
        src="/assets/icons/close-new.svg"
      />
    </button>
  </li>
</template>

<script>
export default {
  name: 'BaseNotification',
  props: {
    title: {
      type: [String, Number],
      required: true,
    },
    text: {
      type: [String, Number],
      required: false,
    },
    mode: {
      type: String,
      required: false,
      default: 'error',
    },
    id: {
      type: [String, Number],
      required: true,
    },
    autoClose: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  mounted() {
    if (!this.autoClose) {
      return;
    }
    setTimeout(() => {
      this.$store.dispatch('notification/close', this.id);
    }, 5000);
  },
  methods: {
    close() {
      this.$store.dispatch('notification/close', this.id);
    },
  },
};
</script>

<style lang="scss" src="./BaseNotification.scss" />
