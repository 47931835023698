import store from '@/store/';

export function loginCheck(to, from, next) {
  !store.getters['auth/isLogged'] ? next('/') : false;
  next();
}

export function adminGuard(to, from, next) {
  store.getters['auth/userType'] !== 'admin' && localStorage.getItem('userType') !== 'admin' ? next('/dashboard') : false;
  next();
}

export function creatorGuard(to, from, next) {
  store.getters['auth/userType'] !== 'creator' && localStorage.getItem('userType') !== 'creator' ? next('/dashboard') : false;
  next();
}

export function standardGuard(to, from, next) {
  store.getters['auth/userType'] !== 'standard' && localStorage.getItem('userType') !== 'standard' ? next('/dashboard') : false;
  next();
}
