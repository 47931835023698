<template>
  <nav
    class="dashboard-navigation"
    :class="mobile ? 'dashboard-navigation--mobile' : ''"
  >
    <span class="dashboard-navigation__line" />

    <ul class="dashboard-navigation__links">
      <li
        class="dashboard-navigation__link"
        v-for="link in links[userType]"
        :key="link.link"
        @click="closeMenu()"
      >
        <router-link
          :to="link.link"
          class="dashboard-navigation__link-inner"
          :title="link.text"
        >
          <component :is="`svg-${link.icon}`" />
          <span>{{ link.text }}</span>
        </router-link>
      </li>
    </ul>

    <div class="dashboard-navigation__buttons">
      <base-button
        type="contrasting"
        to="/become-author"
        class="dashboard-navigation__button"
        @click.native="closeMenu()"
        v-if="userType === 'standard'"
      >
        {{ $t("dashboardNavigation.becomeAuthor") }}
      </base-button>

      <base-button
        type="text"
        icon="sign-out-alt"
        class="dashboard-navigation__button dashboard-navigation__button--mobile"
        @click="logout()"
      >
        {{ $t("nav.logout") }}
      </base-button>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DashboardNavigation',
  props: {
    mobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      userType: 'auth/userType',
    }),
    links() {
      return {
        standard: [
          {
            icon: 'apps',
            link: '/dashboard',
            text: this.$t('dashboardNavigation.overview'),
          },
          {
            icon: 'presentation',
            link: '/my-courses',
            text: this.$t('dashboardNavigation.myCourses'),
          },
          {
            icon: 'credit-card',
            link: '/my-payments',
            text: this.$t('dashboardNavigation.payments'),
          },
          {
            icon: 'settings',
            link: '/settings',
            text: this.$t('dashboardNavigation.settings'),
          },
          {
            icon: 'presentation',
            link: '/join-designers',
            text: 'Edunails Designers',
          },
        ],
        creator: [
          {
            icon: 'apps',
            link: '/dashboard',
            text: this.$t('dashboardNavigation.overview'),
          },
          {
            icon: 'sales',
            link: '/sales-history',
            text: this.$t('dashboardNavigation.sales'),
          },
          {
            icon: 'presentation',
            link: '/my-courses',
            text: this.$t('dashboardNavigation.myCourses'),
          },
          {
            icon: 'camera',
            link: '/added-courses',
            text: this.$t('dashboardNavigation.addedCourses'),
          },
          {
            icon: 'credit-card',
            link: '/my-payments',
            text: this.$t('dashboardNavigation.payments'),
          },
          {
            icon: 'presentation',
            link: '/join-designers',
            text: 'Edunails Designers',
          },
          {
            icon: 'settings',
            link: '/settings',
            text: this.$t('dashboardNavigation.settings'),
          },
        ],
        admin: [
          {
            icon: 'apps',
            link: '/dashboard',
            text: this.$t('dashboardNavigation.overview'),
          },
          {
            icon: 'user-plus',
            link: '/requests',
            text: this.$t('dashboardNavigation.authorRequests'),
          },
          {
            icon: 'sales',
            link: '/sales-history/admin',
            text: this.$t('dashboardNavigation.adminSales'),
          },
          {
            icon: 'sales',
            link: '/payouts/admin',
            text: 'Payouts',
          },
          {
            icon: 'settings',
            link: '/settings',
            text: this.$t('dashboardNavigation.settings'),
          },
        ],
      };
    },
  },
  methods: {
    closeMenu() {
      this.$store.dispatch('ui/setMobileMenu', false);
    },
    logout() {
      this.$store.dispatch('auth/logout');
      this.$store.dispatch('ui/setMobileMenu', false);
    },
  },
};
</script>
<style lang="scss" scoped src="./DashboardNavigation.scss" />

<style lang="scss">
.dashboard-navigation__icon {
  fill: $accent--darkest;
  transition: all $time;

  &--stroke {
    transition: all $time;
    stroke: $accent--darkest;
  }
}

.router-link-active {
  .dashboard-navigation__icon {
    fill: $brand-secondary;

    &--stroke {
      stroke: $brand-secondary;
    }
  }
}
</style>
