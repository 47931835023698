import { setItemToLocalStorage } from '@/helpers';
import { interpretLocale, loadLocale } from '@/helpers/locales';
import i18n from '@/i18n';

export default {
  namespaced: true,
  state: {
    currentLanguage: null,
    languagesList: [
      {
        key: 'en',
        label: 'English',
        currency: 'GBP',
        currencySign: '£',
      },
      {
        key: 'cs',
        label: 'Čeština',
        currency: 'CZK',
        currencySign: 'Kč',
      },
      {
        key: 'de',
        label: 'Deutsch',
        currency: 'EUR',
        currencySign: '€',
      },
      {
        key: 'el',
        label: 'Ελληνικά',
        currency: 'EUR',
        currencySign: '€',
      },
      {
        key: 'es',
        label: 'Español',
        currency: 'EUR',
        currencySign: '€',
      },
      {
        key: 'fr',
        label: 'Français',
        currency: 'EUR',
        currencySign: '€',
      },
      {
        key: 'hu',
        label: 'Magyar',
        currency: 'HUF',
        currencySign: 'Ft',
      },
      {
        key: 'it',
        label: 'Italiano',
        currency: 'EUR',
        currencySign: '€',
      },
      {
        key: 'pl',
        label: 'Polski',
        currency: 'PLN',
        currencySign: 'zł',
      },
      {
        key: 'pt',
        label: 'Português',
        currency: 'EUR',
        currencySign: '€',
      },
      {
        key: 'ru',
        label: 'Русский',
        currency: 'RUB',
        currencySign: '₽',
      },
      {
        key: 'sr',
        label: 'Srpski',
        currency: 'EUR',
        currencySign: '€',
      },
      {
        key: 'sk',
        label: 'Slovenčina',
        currency: 'EUR',
        currencySign: '€',
      },
      {
        key: 'uk',
        label: 'Українська',
        currency: 'EUR',
        currencySign: '€',
      },
      {
        key: 'us',
        label: 'English',
        currency: 'USD',
        currencySign: '$',
      },
      {
        key: 'ro',
        label: 'Romanian',
        currency: 'EUR',
        currencySign: '€',
      },
    ],
  },
  getters: {
    getCurrentLocale(state) {
      return state.currentLanguageISO;
    },
  },
  mutations: {
    SET_LANGUAGE(state, payload) {
      setItemToLocalStorage('localeKey', payload);
      state.currentLanguage = interpretLocale(payload, state.languagesList);
      i18n.locale = state.currentLanguage.key;
      loadLocale(state.currentLanguage.key);
    },
  },
  actions: {
    changeLanguage({ commit }, payload) {
      commit('SET_LANGUAGE', payload);
    },
    autoDetectLanguage({ commit }, payload) {
      const locale = localStorage.getItem('localeKey');
      locale ? commit('SET_LANGUAGE', locale) : commit('SET_LANGUAGE', payload);
    },
  },
};
