<template>
  <upload-file-logic
    class="upload-thumbnail"
    v-slot="{
      handleUpload,
      isDragOver,
      computedImage,
      handleDelete,
      isUploading,
      isError,
      errors
    }"
    :image="image"
    :rules="rules"
    :name="name"
    @upload="$emit('upload', $event)"
    @error="$emit('error')"
    @clear-error="$emit('clear-error')"
  >
    <label :for="name" class="upload-thumbnail__label" :title="helpfulText">
      <span class="upload-thumbnail__label-text" v-if="label">
        {{ label }}
      </span>
      <input
        type="file"
        :id="name"
        :name="name"
        accept="image/png, image/jpeg"
        class="upload-thumbnail__upload"
        @change="handleUpload($event.target)"
        :disabled="computedImage"
      />
      <div
        class="upload-thumbnail__inner"
        :class="[
          isDragOver ? 'upload-thumbnail__inner--is-over' : '',
          courseThumbnail ? 'upload-thumbnail__inner--course-thumbnail' : ''
        ]"
      >
        <transition mode="out-in" name="page-fade">
          <div class="upload-thumbnail__inner-fallback" v-if="!computedImage">
            <loader v-if="isUploading" class="upload-thumbnail__loader" />
            <img
              v-else
              src="/assets/icons/picture.svg"
              alt=""
              class="upload-thumbnail__icon"
              :class="big && 'upload-thumbnail__icon--big'"
            />
            <p class="upload-thumbnail__text" :class="big && 'upload-thumbnail__text--big'">
              {{ $t("uploadFile.text") }}
              <strong class="upload-thumbnail__text-strong">
                {{ $t("uploadFile.browse") }}
              </strong>
            </p>
            <p
              class="upload-thumbnail__requirement"
              v-for="requirement in requirements"
              :key="requirement"
            >
              {{ requirement }}
            </p>
          </div>
          <img
            :src="computedImage"
            alt=""
            class="upload-thumbnail__image"
            v-if="computedImage"
          />
        </transition>
        <transition mode="out-in" name="page-fade">
          <p class="upload-file__error upload-thumbnail__error" v-if="isError">
            {{ $t("uploadFile.error") }}
          </p>
          <span
            class="base-input__error upload-file__required"
            v-if="errors[0]"
          >
            {{ errors[0] }}
          </span>
          <base-button
            type="text"
            class="upload-thumbnail__delete"
            @click="handleDelete"
            v-if="computedImage"
          >
            <span>{{ $t("uploadFile.label") }}</span>
            <svg-trash fill="#A0A4A8" class="upload-thumbnail__delete-icon" />
          </base-button>
        </transition>
      </div>
    </label>
  </upload-file-logic>
</template>

<script>
export default {
  name: 'UploadThumbnail',
  props: {
    image: {
      type: String,
      required: false,
      default: null,
    },
    rules: {
      type: [String, Object],
      required: false,
      default: '',
    },
    helpfulText: {
      type: String,
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    name: {
      type: String,
      required: false,
      default: 'Field',
    },
    requirements: {
      type: Array,
      required: false,
      default: () => [],
    },
    courseThumbnail: {
      type: Boolean,
      required: false,
      default: false,
    },
    big: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped src="./UploadThumbnail.scss" />
