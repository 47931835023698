import request from '@/api/';
import config from '@/config';
import queryString from 'query-string';

export default {
  login(payload) {
    return request.post('auth/login', {
      ...payload,
    });
  },
  register(payload) {
    return request.post('auth/register', {
      ...payload,
    });
  },
  getUserData() {
    return request.get('auth/me');
  },
  updateUserData(payload) {
    return request.put('auth/me', {
      ...payload,
    });
  },
  getUserNotifications() {
    return request.get('auth/me/notifications');
  },
  resetUserPassword(payload) {
    const query = queryString.stringify({
      redirect_url: `${config.baseURL}forgot-password`,
    });

    return request.post(`auth/reset-password?${query}`, {
      ...payload,
    });
  },
  setUserPassword(payload) {
    return request.post('auth/set-password', {
      ...payload,
    });
  },
  setNewUserPassword({ id, token, password }) {
    return request.put(`auth/reset-password/${id}`, {
      password,
      token,
    });
  },
  sendAuthorDocuments(payload) {
    return request.post('creator/promote', {
      ...payload,
    });
  },
  getAuthorDetails(payload) {
    return request.get(`courses/authors/${payload}`);
  },
};
