<template>
  <validation-provider
    v-slot="{ errors }"
    :rules="{ required: required ? { allowFalse: false } : false }"
    :name="name"
    class="base-checkbox"
    :class="required ? 'base-checkbox--required' : ''"
  >
    <label
      class="base-checkbox__label"
      :for="checkboxID"
      :class="[
        fullWidth ? 'base-checkbox__label--full-width' : '',
        center ? 'base-checkbox__label--center' : '',
        withPadding ? 'base-checkbox__label--padding' : '',
      ]"
    >
      <div class="base-checkbox__wrapper">
        <input
          type="checkbox"
          class="base-checkbox__input"
          :id="checkboxID"
          :checked="value"
          :value="value"
          :disabled="disabled"
          @change="updateValue($event.target.checked)"
          :tabindex="tabindex"
        />
        <span class="base-checkbox__inner" :class="squareClass" />
      </div>
      <slot></slot>
    </label>
    <transition name="page-fade">
      <span class="base-checkbox__error" v-if="errors[0]">
        {{ errors[0] }}
      </span>
    </transition>
  </validation-provider>
</template>

<script>
import { uuid } from '@/helpers/';

export default {
  name: 'BaseCheckbox',
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: false,
      default: 'Checkbox',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'circle',
    },
    tabindex: {
      type: String,
      required: false,
      default: '0',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    center: {
      type: Boolean,
      required: false,
      default: false,
    },
    withPadding: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    checkboxID() {
      return uuid();
    },
    squareClass() {
      return this.type === 'square' ? 'base-checkbox__inner--square' : '';
    },
  },
  methods: {
    updateValue(checked) {
      this.$emit('input', checked);
    },
  },
};
</script>

<style lang="scss" scoped src="./BaseCheckbox.scss" />
