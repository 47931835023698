<template>
  <section class="course-actions">
    <header class="course-actions__header">
      <progress-bar :percent="progress" class="course-actions__progress" />

      <h1 class="course-actions__header-name">
        {{ course.name }}
      </h1>

      <certificate-label
        v-if="isValidCertificate"
        class="course-actions__certificate-label"
      />

      <like-button
        :likes="details.likes"
        :courseId="course.id"
        class="course-actions__like"
      />

      <base-button
        v-if="isCompleted && isValidCertificate"
        @click="generateCertificate"
        class="course-actions__generate"
      >
        {{ $t("course.generateCertificate") }}
      </base-button>

      <base-button
        v-if="isCompleted"
        @click="generateCertificate(true)"
        class="course-actions__generate course-actions__generate--portal"
        type="outline-reverse"
      >
        {{ $t("course.generatePortalCertificate") }}
      </base-button>
    </header>

    <div class="course-actions-content">
      <h2 class="course-actions-content__heading">
        {{ $t("course.program") }}
      </h2>

      <base-accordion
        v-model="lessonStatus"
        class="my-course-content__accordion"
      >
        <base-accordion-item
          class="my-course-cont ent__accordion-item"
          v-for="(lesson, index) in lessons"
          :key="lesson.name"
          :name="lesson.name"
          :label="`${index + 1}.`"
          :is-completed="isLessonCompleted(lesson)"
        >
          <lesson-tile
            :lesson="lesson"
            :is-locked="false"
            :is-active="isActiveLesson(lesson.id)"
            @setLesson="setLesson($event)"
          />
        </base-accordion-item>
      </base-accordion>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CourseActions',
  data: () => ({
    lessonStatus: [],
  }),
  props: {
    course: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    lessons: {
      type: Array,
      required: false,
      default: () => [],
    },
    details: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    activeLesson: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    progress: {
      type: Number,
      default: 0,
    },
    isCompleted: {
      type: Boolean,
      default: false,
    },
    generateCertificate: {
      type: Function,
      default: () => null,
    },
    isValidCertificate: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    activeLesson() {
      if (Object.keys(this.activeLesson).length) {
        this.setActiveLesson(this.activeLesson.name);
      }
    },
  },
  methods: {
    isLessonCompleted(lesson) {
      let completedLessonsLength = 0;
      return lesson.isCompleted ? (completedLessonsLength += 1) : false;
    },
    isActiveLesson(lessonID) {
      if (this.activeLesson) {
        return this.activeLesson.id === lessonID;
      }

      return false;
    },
    setActiveLesson(lessonName) {
      this.openLessons = [lessonName];
    },
    setLesson(lesson) {
      this.$emit('set-lesson', lesson);
    },
  },
};
</script>

<style lang="scss" src="./CourseActions.scss" />
