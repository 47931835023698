<template>
  <base-card class="edit-profile">
    <validation-observer ref="form">
      <h2 class="edit-profile__heading">
        {{ $t("accountCreation.form.basicData.heading") }}
      </h2>
      <form @submit.prevent="handleSubmit">
        <base-textarea
          v-model="userData.bio"
          :label="$t('editProfile.aboutMe')"
          :name="$t('editProfile.aboutMe')"
          class="edit-profile__textarea"
          rows="10"
        />
        <section>
          <h3 class="edit-profile__sub-heading">
            {{ $t("editProfile.social.heading") }}
          </h3>
          <div class="edit-profile__socials">
            <social-input
              class="edit-profile__social-input"
              v-model="userData.facebookLink"
              socialMedia="Facebook"
            />
            <social-input
              class="edit-profile__social-input"
              v-model="userData.instagramLink"
              socialMedia="Instagram"
            />
            <social-input
              class="edit-profile__social-input"
              v-model="userData.linkedInLink"
              socialMedia="Linkedin"
            />
            <social-input
              class="edit-profile__social-input"
              v-model="userData.pinterestLink"
              socialMedia="Pinterest"
            />
            <social-input
              class="edit-profile__social-input"
              v-model="userData.twitterLink"
              socialMedia="Twitter"
            />
            <social-input
              class="edit-profile__social-input"
              v-model="userData.youtubeLink"
              socialMedia="Youtube"
            />
          </div>
        </section>
        <section>
          <div class="edit-profile__row">
            <h3 class="edit-profile__sub-heading">
              {{ $t("editProfile.portfolio.heading") }}
            </h3>
            <span class="edit-profile__limit">
              ({{ $t("editProfile.portfolio.limit", { amount: 4 }) }})
            </span>
          </div>
          <draggable
            v-model="userData.portfolio"
            v-bind="dragOptions"
            @start="drag = true"
            @end="drag = false"
            @change="sortImages"
          >
            <transition-group
              type="transition"
              class="edit-profile__portfolio"
              tag="div"
              :name="!drag ? 'edit-profile__flip-list' : null"
            >
              <upload-portfolio-image
                v-for="(image, index) in userData.portfolio"
                :key="image.key"
                class="edit-profile__upload"
                :class="
                  isDraggingEnabled
                    ? 'edit-profile__upload--active'
                    : 'edit-profile__upload--disabled'
                "
                :image="image.id"
                @upload="setImage($event, index)"
                @uploaded="sortImages"
                :label="$t('editProfile.portfolio.upload')"
                type="editProfile"
                dropText="Drop and upload"
                :rules="`image|size:${1024 * 5}`"
                :name="`Portfolio ${index + 1}`"
                veeValidate
              />
            </transition-group>
          </draggable>
          <transition name="page-fade" mode="out-in">
            <p class="edit-profile__tip" v-if="isDraggingEnabled">
              {{ $t("editProfile.portfolio.tip") }}
            </p>
          </transition>
        </section>
        <div class="edit-profile__actions">
          <base-button
            type="text"
            @click="handleReset"
            class="edit-profile__cancel"
          >
            {{ $t("accountSettings.accountDetails.cancel") }}
          </base-button>
          <base-button
            nativeType="submit"
            class="edit-profile__cancel"
            :isLoading="isLoading"
          >
            {{ $t("accountSettings.accountDetails.submit") }}
          </base-button>
        </div>
      </form>
    </validation-observer>
  </base-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'EditProfile',
  data: () => ({
    userData: {},
    drag: false,
    isLoading: false,
  }),
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    dragOptions() {
      return {
        animation: 300,
        ghostClass: 'edit-profile__ghost',
        handle: '.edit-profile__upload--active',
        disabled: !this.isDraggingEnabled,
      };
    },
    isDraggingEnabled() {
      return this.userData.portfolio.filter((image) => image.id).length > 1;
    },
  },
  methods: {
    async handleSubmit() {
      this.isLoading = true;
      await this.$store.dispatch('auth/updateUserData', {
        ...this.userData,
        featuredImagesIds: this.userData.portfolio
          .map((image) => image.id)
          .filter(Boolean),
      });
      this.isLoading = false;
    },
    handleReset() {
      const portfolio = this.createPortfolio();
      this.userData = { ...this.user, portfolio };
    },
    setImage(event, index) {
      this.userData.portfolio[index].id = event;

      // handle delete
      if (event === '') {
        this.sortImages();
      }
    },
    sortImages() {
      this.userData.portfolio.sort((a, b) => {
        if (!a.id) {
          return 1;
        }
        if (!b.id) {
          return -1;
        }
        return 0;
      });
    },
    createPortfolio() {
      const portfolio = new Array(4).fill(null).map((_, index) => ({
        id: this.user.featuredImagesIds[index] || '',
        key: index,
      }));
      return portfolio;
    },
  },
  created() {
    const portfolio = this.createPortfolio();
    this.userData = {
      ...this.user,
      ...this.userData,
      portfolio,
    };
  },
};
</script>
<style lang="scss" scoped src="./EditProfile.scss" />
