<template>
  <validation-provider
    v-slot="{ errors, valid }"
    :rules="rules"
    :name="name"
    tag="div"
    class="base-input"
    :vid="vid"
    mode="eager"
    ref="provider"
  >
    <div
      class="base-input__label-wrapper"
      :class="centeredLabel ? 'base-input__label-wrapper--center' : ''"
    >
      <label class="base-input__label" :for="uniqueName">
        {{ label }}
      </label>
    </div>
    <div class="base-input__wrapper">
      <input
        :type="type === 'password' ? localType : type"
        :value="value"
        :disabled="disabled"
        :id="uniqueName"
        @input="updateValue"
        @change="updateValue"
        @blur="handleBlur"
        @focus="$emit('focus')"
        class="base-input__inner"
        :class="[
          icon ? 'base-input__inner--with-icon' : '',
          errors[0] ? 'base-input__inner--error' : '',
          hideArrows ? 'base-input__inner--hide-arrows' : ''
        ]"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        ref="input"
        :max="max"
        :min="min"
        :inputmode="inputmode"
        :step="step"
      />
      <transition name="page-fade">
        <button
          type="button"
          :title="$t('showPassword')"
          v-if="icon"
          class="base-input__icon"
          @click="showPassword"
          tabindex="-1"
        >
          <img :src="inputIcon" alt=""/>
        </button>
        <img
          src="/assets/icons/exclamation.svg"
          :alt="$t('warning')"
          class="base-input__icon"
          v-else-if="errors[0]"
        />
        <svg-tick
          :fill="tickColor"
          class="base-input__icon base-input__icon--tick"
          v-else-if="valid && rules && !hideTick"
        />
      </transition>
    </div>
    <transition
      name="page-fade"
      @enter="$emit('error', name)"
      @leave="$emit('clear-error', name)"
    >
      <span class="base-input__error" v-if="errors[0]">
        {{ errors[0] }}
      </span>
    </transition>
  </validation-provider>
</template>

<script>
import { uuid } from '@/helpers/';

export default {
  name: 'BaseInput',
  data() {
    return {
      localType: 'password',
      inputIcon: `/assets/icons/${this.icon}-slash.svg`,
    };
  },
  props: {
    value: {
      type: [String, Number],
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    name: {
      type: String,
      required: false,
      default: 'Field',
    },
    rules: {
      type: [String, Object],
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
    vid: {
      type: String,
      required: false,
      default: '',
    },
    max: {
      type: String,
      required: false,
      default: '',
    },
    min: {
      type: String,
      required: false,
      default: '',
    },
    inputmode: {
      type: String,
      required: false,
      default: 'text',
    },
    centeredLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    step: {
      type: String,
      required: false,
      default: '',
    },
    autocomplete: {
      type: String,
      required: false,
      default: '',
    },
    trim: {
      type: Boolean,
      required: false,
      default: true,
    },
    hideOptional: {
      type: Boolean,
      required: false,
      default: false,
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideTick: {
      type: Boolean,
      required: false,
      default: false,
    },
    tickColor: {
      type: String,
      required: false,
      default: '#48EFA7',
    },
    hideArrows: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    uniqueName() {
      return `${this.name}-${uuid()}`;
    },
  },
  methods: {
    updateValue(event) {
      this.$emit('input', event.target.value);
    },
    changeInputType() {
      switch (this.localType) {
        case 'password': {
          this.localType = 'text';
          break;
        }
        case 'text': {
          this.localType = 'password';
          break;
        }
        default: {
          break;
        }
      }
    },
    showPassword() {
      if (this.$refs.input.type === 'password') {
        this.$refs.input.type = 'text';
        this.inputIcon = `/assets/icons/${this.icon}.svg`;
      } else {
        this.$refs.input.type = 'password';
        this.inputIcon = `/assets/icons/${this.icon}-slash.svg`;
      }
    },
    handleBlur() {
      this.$emit('blur', {
        name: this.name,
        valid: this.rules ? this.valid : false,
      });
      if (this.trim) {
        this.$emit('input', this.value.trim());
      }
    },
  },
  mounted() {
    if (this.autofocus) {
      this.$refs.input.focus({ preventScroll: true });
    }
  },
};
</script>

<style lang="scss" src="./BaseInput.scss"/>
