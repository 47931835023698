import Vue from 'vue';
import VueRouter from 'vue-router';
import multiguard from 'vue-router-multiguard';
import {
  loginCheck, adminGuard, creatorGuard, standardGuard,
} from '@/helpers/guards';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import(/* webpackChunkName: "home-page" */ '../views/Home/Home.vue'),
  },
  {
    path: '/join-designers',
    name: 'DesignersLandingPage',
    component: () => import(/* webpackChunkName: "designers-landing-page" */ '../views/DesignersLandingPage/DesignersLandingPage.vue'),
  },
  {
    path: '/courses',
    name: 'CoursesPage',
    component: () => import(/* webpackChunkName: "courses-page" */ '../views/Courses/Courses.vue'),
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: '/courses/:slug',
    name: 'CoursePage',
    component: () => import(/* webpackChunkName: "course-page" */ '../views/Course/Course.vue'),
  },
  {
    path: '/auth/:slug',
    name: 'AuthPageSlug',
    component: () => import(/* webpackChunkName: "auth-page" */ '../views/Auth/Auth.vue'),
    props: true,
    beforeEnter: (to, from, next) => {
      const isValid = (param) => {
        const params = ['sign-in', 'sign-up'];
        return params.includes(param);
      };
      if (!isValid(to.params.slug)) {
        next({ path: 'sign-in' });
      } else {
        next();
      }
    },
  },
  {
    path: '/auth',
    name: 'AuthPage',
    component: () => import(/* webpackChunkName: "auth-page" */ '../views/Auth/Auth.vue'),
    props: true,
    beforeEnter: (to, from, next) => {
      next({ path: '/auth/sign-in' });
    },
  },
  {
    path: '/contact',
    name: 'ContactPage',
    component: () => import(/* webpackChunkName: "contact-page" */ '../views/Contact/Contact.vue'),
  },
  {
    path: '/about-us',
    name: 'AboutUsPage',
    component: () => import(/* webpackChunkName: "about-us-page" */ '../views/AboutUs/AboutUs.vue'),
  },

  {
    path: '/shop',
    name: 'ShopPage',
    component: () => import(/* webpackChunkName: "shop-page" */ '../views/Shop/Shop.vue'),
  },

  {
    path: '/tutorial',
    name: 'TutorialPage',
    component: () => import(/* webpackChunkName: "shop-page" */ '../views/Tutorial/Tutorial.vue'),
  },

  {
    path: '/checkout',
    name: 'CheckoutPage',
    component: () => import(/* webpackChunkName: "checkout-page" */ '../views/Checkout/Checkout.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ResetPasswordPage',
    component: () => import(/* webpackChunkName: "reset-password-page" */ '../views/ResetPassword/ResetPassword.vue'),
  },
  {
    path: '/payment-status',
    name: 'PaymentStatusPage',
    component: () => import(/* webpackChunkName: "payment-status-page" */ '../views/PaymentStatus/PaymentStatus.vue'),
  },
  {
    path: '/privacy-settings',
    name: 'PrivacySettingsPage',
    component: () => import(/* webpackChunkName: "privacy-settings-page" */ '../views/PrivacySettings/PrivacySettings.vue'),
  },
  {
    path: '/faq',
    name: 'FaqPage',
    component: () => import(/* webpackChunkName: "faq-page" */ '../views/Faq/Faq.vue'),
  },
  {
    path: '/ebook',
    name: 'EbookPage',
    component: () => import(/* webpackChunkName: "ebook-page" */ '../views/Ebook/Ebook.vue'),
  },
  {
    path: '/instructor/:slug',
    name: 'InstructorPage',
    component: () => import(/* webpackChunkName: "instructor-page" */ '../views/Instructor/Instructor.vue'),
  },
  {
    path: '/blog',
    name: 'BlogCategoryPage',
    component: () => import(/* webpackChunkName: "blog-category-page" */ '../views/BlogCategory/BlogCategory.vue'),
  },
  {
    path: '/blog/:slug',
    name: 'BlogPostPage',
    component: () => import(/* webpackChunkName: "blog-post-page" */ '../views/BlogPostPage/BlogPostPage.vue'),
  },
  // DASHBOARD ROUTES
  {
    path: '/become-author',
    name: 'BecomeAuthor',
    component: () => import('../views/BecomeAuthor/BecomeAuthor.vue'),
    beforeEnter: multiguard([loginCheck, standardGuard]),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard-page" */ '../views/Dashboard/Dashboard.vue'),
    beforeEnter: multiguard([loginCheck]),
  },
  {
    path: '/requests',
    name: 'Requests',
    component: () => import('../views/AuthorRequests/Requests.vue'),
    beforeEnter: multiguard([loginCheck, adminGuard]),
  },
  {
    path: '/requests/:id',
    name: 'RequestDetails',
    props: true,
    component: () => import('../views/AuthorRequests/RequestDetails.vue'),
    beforeEnter: multiguard([loginCheck, adminGuard]),
  },
  {
    path: '/my-courses',
    name: 'MyCoursesPage',
    component: () => import(/* webpackChunkName: "my-courses-page" */ '../views/MyCourses/MyCourses.vue'),
    beforeEnter: multiguard([loginCheck]),
  },
  {
    path: '/watch-course/:id',
    name: 'WatchCoursePage',
    component: () => import(/* webpackChunkName: "my-course-page" */ '../views/MyCourse/MyCourse.vue'),
    beforeEnter: multiguard([loginCheck]),
  },
  {
    path: '/my-payments',
    name: 'MyPayments',
    component: () => import(/* webpackChunkName: "my-payments-page" */ '../views/MyPayments/MyPayments.vue'),
    beforeEnter: multiguard([loginCheck]),
  },
  {
    path: '/sales-history',
    name: 'SalesHistoryPage',
    component: () => import(/* webpackChunkName: "sales-history" */ '../views/SalesHistory/SalesHistory.vue'),
    beforeEnter: multiguard([loginCheck, creatorGuard]),
  },
  {
    path: '/sales-history/admin',
    name: 'SalesHistoryPageAdmin',
    component: () => import(/* webpackChunkName: "sales-history" */ '../views/SalesHistory/SalesHistory.vue'),
    beforeEnter: multiguard([loginCheck, adminGuard]),
  },
  {
    path: '/payouts/admin',
    name: 'PayoutsHistoryPageAdmin',
    component: () => import(/* webpackChunkName: "sales-history" */ '../views/PayoutsHistory/PayoutsHistory.vue'),
    beforeEnter: multiguard([loginCheck, adminGuard]),
  },
  {
    path: '/settings',
    name: 'AccountSettingsPage',
    component: () => import(/* webpackChunkName: "settings-page" */ '../views/AccountSettings/AccountSettings.vue'),
    beforeEnter: multiguard([loginCheck]),
  },
  {
    path: '/add-course',
    name: 'AddCoursePage',
    component: () => import(/* webpackChunkName: "add-course-page" */ '../views/AddCourse/AddCourse.vue'),
    beforeEnter: multiguard([loginCheck, creatorGuard]),
  },
  {
    path: '/added-courses',
    name: 'AddedCoursesPage',
    component: () => import(/* webpackChunkName: "added-courses-page" */ '../views/AddedCourses/AddedCourses.vue'),
    beforeEnter: multiguard([loginCheck, creatorGuard]),
  },
  {
    path: '/manage-course/:slug',
    name: 'ManageCoursePage',
    component: () => import(/* webpackChunkName: "add-course-page" */ '../views/AddCourse/AddCourse.vue'),
    props: true,
    beforeEnter: multiguard([loginCheck, creatorGuard]),
  },
  // 404 page
  {
    path: '*',
    name: '404Page',
    component: () => import(/* webpackChunkName: "404-page" */ '../views/404/404.vue'),
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    const position = {
      x: 0,
      y: 0,
    };

    if (savedPosition) {
      position.x = savedPosition.x;
      position.y = savedPosition.y;
    }

    // Listen for scrollBeforeEnter event and set scroll position
    return new Promise((resolve) => {
      this.app.$root.$once('scrollBeforeEnter', () => {
        resolve(position);
      });
    });
  },
});

export default router;
