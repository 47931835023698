<template>
  <section class="section-benefits">
    <div class="container section-benefits__container">
      <header class="section-benefits__header">
        <base-sub-heading align="center">
          {{ subHeading }}
        </base-sub-heading>
        <base-heading align="center" bold="bold">
          {{ heading }}
        </base-heading>
      </header>
      <ul class="section-benefits__list">
        <li
          class="section-benefits__item"
          v-for="item in listWithIcons"
          :key="item.name"
        >
          <div class="section-benefits__item-icon">
            <img alt="" :src="`/assets/flat icons/${item.icon}.svg`" />
          </div>
          <h4 class="section-benefits__item-name" v-html="item.name" />
          <p class="section-benefits__item-text">
            {{ item.text }}
          </p>
        </li>
      </ul>
      <img
        class="section-benefits__image"
        alt=""
        src="/assets/images/dots.svg"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionBenefits',
  props: {
    heading: {
      type: String,
      required: true,
    },
    subHeading: {
      type: String,
      required: true,
    },
    list: {
      type: Array,
      required: true,
    },
    icons: {
      type: Array,
      required: true,
    },
  },
  computed: {
    listWithIcons() {
      return this.list.map((item, index) => ({
        ...item,
        icon: this.icons[index],
      }));
    },
  },
};
</script>

<style lang="scss" src="./SectionBenefits.scss" />
