<template>
  <div class="social-input">
    <div class="social-input__left">
      <div class="social-input__icon">
        <img
          :src="`/assets/socials/${socialMedia}.svg`"
          :alt="socialMedia"
          class="social-input__icon-inner"
        />
      </div>
      <label :for="socialMedia" class="social-input__text">
        {{ socialMedia }}
      </label>
    </div>
    <div class="social-input__right">
      <input
        :class="isInput && 'social-input__input--active'"
        :value="value"
        @input="handleChange"
        :placeholder="$t('editProfile.social.input')"
        class="social-input__input"
        @focus="handleFocus"
        @blur="handleBlur"
        :id="socialMedia"
        :name="socialMedia"
      />
      <transition name="page-fade" mode="out-in">
        <button
          @click="handleDelete"
          class="social-input__delete"
          type="button"
          :title="$t('myCourses.author.delete.accept')"
          v-if="value && value.length > 0"
        >
          <svg-close fill="#dbdde0" class="social-input__delete-icon" />
        </button>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SocialInput',
  data: () => ({
    isInput: false,
  }),
  props: {
    socialMedia: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    handleChange($event) {
      this.$emit('input', $event.target.value);
    },
    handleDelete() {
      this.$emit('input', '');
    },
    handleFocus() {
      this.isInput = true;
    },
    handleBlur() {
      this.isInput = false;
    },
  },
};
</script>
<style lang="scss" scoped src="./SocialInput.scss" />
