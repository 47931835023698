<template>
  <upload-file-logic
    v-slot="{
      handleUpload,
      isDragOver,
      computedImage,
      handleDelete,
      errors,
      isError
    }"
    :image="image"
    :rules="rules"
    :name="name"
    @upload="$emit('upload', $event)"
    @error="$emit('error')"
    @clear-error="$emit('clear-error')"
  >
    <div
      class="upload-file"
      :class="[rectangle ? 'upload-file--rectangle' : '']"
    >
      <label
        for="image"
        class="upload-file__label"
        :class="[
          computedImage ? 'upload-file__label--uploaded' : '',
          isDragOver ? 'upload-file__label--is-over' : '',
          rectangle ? 'upload-file__label--rectangle' : ''
        ]"
        :title="helpfulText"
      >
        <div class="upload-file__icon" v-if="!computedImage">
          <img
            :src="'/assets/icons/upload-alt.svg'"
            :alt="$t('uploadFile.addFiles')"
            :title="$t('uploadFile.addFiles')"
            class="upload-file__icon-inner"
          />
        </div>

        <input
          type="file"
          id="image"
          name="image"
          accept="image/png, image/jpeg"
          class="upload-file__upload"
          @change="handleUpload($event.target)"
          :disabled="disabled"
        />
        <p
          class="upload-file__help"
          :class="isDragOver ? 'upload-file__help--is-over' : ''"
          v-if="!computedImage"
        >
          {{ label }}
        </p>
      </label>

      <transition mode="out-in" name="page-fade">
        <img
          :src="computedImage"
          alt=""
          class="upload-file__image"
          v-if="computedImage"
        />
      </transition>

      <transition mode="out-in" name="page-fade">
        <base-button
          type="text"
          class="upload-file__delete"
          @click="handleDelete"
          v-if="computedImage && !disabled"
        >
          {{ $t("uploadFile.label") }}
        </base-button>
      </transition>

      <transition mode="out-in" name="page-fade">
        <p class="upload-file__error" v-if="isError">
          {{ $t("uploadFile.error") }}
        </p>
      </transition>

      <div class="upload-file__requirements" v-if="!disabled">
        <p
          class="upload-file__requirement"
          v-for="requirement in requirements"
          :key="requirement"
        >
          {{ requirement }}
        </p>
      </div>

      <transition
        name="page-fade"
        @enter="$emit('error', name)"
        @leave="$emit('clear-error', name)"
      >
        <span class="base-input__error upload-file__required" v-if="errors[0]">
          {{ errors[0] }}
        </span>
      </transition>
    </div>
  </upload-file-logic>
</template>

<script>
export default {
  name: 'UploadFile',
  props: {
    image: {
      type: String,
      required: false,
      default: null,
    },
    rules: {
      type: [String, Object],
      required: false,
      default: '',
    },
    helpfulText: {
      type: String,
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    rectangle: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: false,
      default: 'Field',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    requirements: {
      type: Array,
      required: false,
    },
  },
};
</script>

<style lang="scss" src="./UploadFile.scss" />
