<template>
  <ul class="base-multi-options">
    <li class="base-multi-options__select-all">
      <base-checkbox
        type="square"
        :value="selectedAll"
        @input="handleSelectAll"
        withPadding
      >
        {{$t('multiSelect.selectAll')}}
      </base-checkbox>
    </li>
    <li
      v-for="option in select.filteredOptions"
      :key="option.value"
      class="base-multi-options__item"
    >
      <base-checkbox
        type="square"
        :value="isChecked(option)"
        @input="handleCheckbox($event, option)"
        fullWidth
        withPadding
      >
        {{ option.label }}
      </base-checkbox>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'BaseMultiOptions',
  data: () => ({
    selectedAll: false,
  }),
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
  inject: ['select'],
  methods: {
    handleCheckbox(event, option) {
      if (event) {
        this.select.selectOption(option);
        if (this.options.length === this.select.selected.length) {
          this.selectedAll = true;
        }
      } else {
        this.selectedAll = false;
        this.select.unselectOption(option);
      }
    },
    handleSelectAll() {
      this.selectedAll = !this.selectedAll;
      if (this.selectedAll) {
        this.options.forEach((option) => this.select.selectOption(option));
      } else {
        this.options.forEach((option) => this.select.unselectOption(option));
      }
    },
    isChecked(option) {
      return (
        this.select.selected.findIndex(
          (selected) => selected.value === option.value,
        ) > -1
      );
    },
  },
  created() {
    this.select.options = this.options;
    this.select.filteredOptions = this.options;
    if (this.select.selected.length === this.select.filteredOptions.length) {
      this.selectedAll = true;
    }
  },
};
</script>
<style lang="scss" scoped src="./BaseMultiOptions.scss" />
