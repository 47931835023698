<template>
  <div
    class="form-progress"
    :class="isNavigationVisible() ? 'form-progress--nav-visible' : ''"
  >
    <div
      class="form-progress__inner"
      :style="{ gridTemplateColumns: `repeat(${steps.length}, 1fr)` }"
    >
      <button
        v-for="(stepText, index) in steps"
        :key="stepText"
        class="form-progress__step"
        :class="index + 1 <= step ? 'form-progress__step--active' : ''"
        @click="$emit('click', index)"
      >
        <span class="form-progress__line form-progress__line--gray" />
        <span class="form-progress__line form-progress__line--active" />
        <span
          class="form-progress__step-text"
          :class="index + 1 === step ? 'form-progress__step-text--active' : ''"
        >
          <span class="form-progress__step-text-inner">
            {{ stepText }}
          </span>
        </span>
        <span class="form-progress__dot" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormProgress',
  inject: ['isNavigationVisible'],
  props: {
    steps: {
      type: Array,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped src="./FormProgress.scss" />
