<template>
  <button
    class="play-button"
    :class="[
      size === 'small' ? 'play-button--small' : 'play-button--big',
      border ? 'play-button--border' : false,
      color === 'pink' ? 'play-button--pink' : ''
    ]"
    @click="$emit('click')"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="14"
      viewBox="0 0 9 14"
      v-if="color === 'blue'"
    >
      <path
        id="Polygon_2"
        data-name="Polygon 2"
        d="M6.211,1.015a1,1,0,0,1,1.579,0l4.955,6.371A1,1,0,0,1,11.955,9H2.045a1,1,0,0,1-.789-1.614Z"
        transform="translate(9) rotate(90)"
        fill="#474bff"
      />
    </svg>
    <img src="/assets/icons/play.svg" alt="Play" v-if="color === 'pink'"/>
  </button>
</template>

<script>
export default {
  name: 'PlayButton',
  props: {
    size: {
      type: String,
      required: false,
      default: 'big',
    },
    border: {
      type: Boolean,
      required: false,
    },
    color: {
      type: String,
      required: false,
      default: 'blue',
    },
  },
};
</script>

<style lang="scss" src="./PlayButton.scss" />
