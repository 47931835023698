<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
  >
    <g>
      <g>
        <path
          :fill="fill"
          d="M13.583 10.997l7.875-7.858a1.84 1.84 0 1 0-2.6-2.6L11 8.414 3.143.54a1.84 1.84 0 1 0-2.601 2.6l7.876 7.858-7.876 7.857a1.832 1.832 0 0 0 0 2.601 1.832 1.832 0 0 0 2.6 0L11 13.58l7.857 7.876a1.832 1.832 0 0 0 2.601 0 1.832 1.832 0 0 0 0-2.6z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SvgClose',
  props: {
    fill: {
      type: String,
      required: false,
      default: '#858585',
    },
  },
};
</script>
