<template>
  <transition name="">
    <div class="base-tab-item" v-if="isActive">
      <slot></slot>
    </div>
  </transition>

</template>

<script>
export default {
  name: 'BaseTabItem',
  inject: ['tabs'],
  props: {
    name: {
      type: [String, Number],
      requred: true,
    },
    label: {
      type: [String, Number],
      requred: true,
    },
  },
  computed: {
    isActive() {
      return this.tabs.value === this.name;
    },
  },
  created() {
    this.tabs.navigation.push({
      name: this.name,
      label: this.label,
    });
  },
};
</script>

<style>

</style>
