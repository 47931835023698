<template>
  <validation-observer ref="form" class="add-step-videos" tag="form">
    <base-card class="add-step-videos__card">
      <h3 class="add-step-videos__heading">
        {{ $t("addCourse.add-video.addVideo") }}
      </h3>

      <base-input
        :name="$t('addCourse.add-video.lessonTitle')"
        :value="lesson.name"
        @input="updateLesson({ name: $event })"
        rules="required"
        :label="$t('addCourse.add-video.lessonTitle')"
        class="add-step-videos__input"
        ref="input"
        :disabled="disabled"
      />

      <upload-video
        :name="$t('addCourse.add-video.addVideo')"
        :video="lesson.assetId"
        :videoURL="lesson.videoURL"
        :status="lesson.assetStatus"
        :course-id="course.id"
        :lesson-id="lesson.id"
        :disabled="disabled"
        @upload="handleUploadVideo"
        @delete="handleDeleteVideo"
        class="add-step-videos__video"
        formats="video/mp4,video/quicktime"
        :requirements="[$t('requirements.format', { format: '.mp4, .mov' })]"
      />

      <transition name="page-fade" mode="out-in">
        <information-banner
          v-if="processingInfoBanner"
          @close="processingInfoBanner = false"
          class="add-step-videos__info-banner"
        >
          {{ $t("maxUploadFileSize") }}
        </information-banner>
      </transition>
    </base-card>
    <base-card class="add-step-videos__subtitles" v-if="lesson.assetId">
      <h3 class="add-step-videos__heading">
        {{ $t("addCourse.add-video.subtitles") }}
      </h3>

      <ul class="add-step-videos__subtitles-list">
        <li
          v-for="(subtitle, index) in subtitles"
          :key="subtitle.locale"
          class="add-step-videos__subtitles-item"
        >
          <button
            @click="editSubtitles(subtitle)"
            class="add-step-videos__subtitles-details"
            type="button"
          >
            <span class="add-step-videos__subtitles-number">
              {{ index + 1 }}.
            </span>

            <country-label
              :countryKey="subtitle.locale"
              class="add-step-videos__subtitles-language"
            >
              {{ subtitle.label }}
            </country-label>
          </button>

          <button
            class="add-step-videos__subtitles-edit"
            @click="editSubtitles(subtitle)"
            type="button"
          >
            {{ $t("addCourse.add-video.edit") }}
          </button>
        </li>
      </ul>

      <base-button
        @click="addSubtitles"
        class="add-step-videos__subtitles-new"
        type="text"
      >
        {{ $t("addCourse.add-video.addSubtitle") }}
        <svg-plus fill="#474BFF" class="add-step-videos__subtitles-new-icon" />
      </base-button>
    </base-card>

    <subtitles-modal
      v-if="isSubtitlesModal"
      @input="isSubtitlesModal = false"
      :activeSubtitles="activeSubtitles"
      :activeLessonId="activeLesson.id"
      :course="course"
      :subtitlesList="subtitles"
      @change-active="changeActiveSubtitles"
      @update-data="updateSubtitles"
      @delete-subtitles="deleteSubtitles($event)"
    />

    <transition name="page-fade" mode="out-in">
      <main-percentage-loading
        :percent="uploadedPercentage"
        v-if="isUploading"
      />
    </transition>
  </validation-observer>
</template>

<script>
import axios from 'axios';
import video from '@/api/upload/video';
import course from '@/api/services/course';
import { mapState } from 'vuex';

export default {
  name: 'AddStepVideos',
  data: () => ({
    isSubtitlesModal: false,
    uploadedPercentage: 0,
    isUploading: false,
    activeSubtitles: null,
    processingInfoBanner: true,
    subtitles: [],
    areLessonsProcessed: true,
  }),
  props: {
    course: {
      type: Object,
      required: true,
    },
    activeLesson: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState({
      languagesList: (state) => state.language.languagesList,
    }),
    lesson: {
      get() {
        return this.course.lessons.find(
          (lesson) => lesson.id === this.activeLesson.id,
        );
      },
      set(newValue) {
        const data = this.injectData(
          this.course.lessons,
          newValue,
          (lesson) => lesson.id === newValue.id,
        );
        this.$emit('update-data', {
          lessons: data,
        });
      },
    },
  },
  methods: {
    updateLesson(value) {
      this.lesson = { ...this.lesson, ...value };
    },
    async handleUploadVideo({ file, duration, videoURL }) {
      this.uploadedPercentage = 0;
      this.isUploading = true;
      try {
        const { data } = await video.createVideo();
        this.updateLesson({
          assetId: data.id,
          videoDuration: duration,
          videoURL,
        });
        await axios.put(data.link, file, {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
            this.uploadedPercentage = percentCompleted;
          },
        });
        setTimeout(async () => {
          await video.processVideo(data.id);
          this.areLessonsProcessed = false;
          const interval = setInterval(() => {
            if (!this.areLessonsProcessed) {
              this.checkProcessing();
            } else {
              clearInterval(interval);
            }
          }, 3000);
          this.$store.dispatch('notification/spawn', {
            type: 'warning',
            title: this.$t('processingStart.title'),
            text: this.$t('processingStart.text'),
          });
        }, 200);
      } catch (err) {
        this.$store.dispatch('notification/spawn', {
          errorMessage: err.response.data.message,
          type: 'error',
        });
      } finally {
        this.isUploading = false;
      }
    },
    async checkProcessing() {
      const { data } = await video.getVideos();
      const videosInCourse = data.filter((asset) => this.course.lessons.some((lesson) => lesson.assetId === asset.id),
      );
      const isProcessing = () => videosInCourse.every((asset) => asset.status === 'processed');
      if (!isProcessing()) return;
      this.areLessonsProcessed = true;
      this.$store.dispatch('notification/spawn', {
        type: 'success',
        title: this.$t('processingEnd.title'),
        text: this.$t('processingEnd.text'),
      });
    },
    async handleDeleteVideo() {
      const { assetId } = this.lesson;

      this.updateLesson({
        assetId: '',
        assetStatus: '',
        videoDuration: 0,
      });

      if (!this.course.id || !this.lesson.id) {
        return;
      }

      this.$store.dispatch('ui/setPreloader', true);

      try {
        await course.editLesson(
          {
            ...this.lesson,
            assetId: '',
            assetStatus: '',
            videoDuration: 0,
            subtitles: JSON.stringify(this.lesson.subtitles),
          },
          this.course.id,
          this.lesson.id,
        );
        await video.deleteVideo(assetId);
        this.$store.dispatch('notification/spawn', {
          type: 'success',
          title: this.$t('uploadVideo.delete.success.title'),
          text: this.$t('uploadVideo.delete.success.text'),
        });
      } catch (err) {
        this.$store.dispatch('notification/spawn', {
          errorMessage: err.response.data.message,
          type: 'error',
        });
      } finally {
        this.$store.dispatch('ui/setPreloader', false);
      }
    },
    changeActiveSubtitles(subtitles) {
      this.activeSubtitles = subtitles;
    },
    injectData(data, newValue, dataToCompare) {
      const index = data.findIndex(dataToCompare);
      if (index < 0) {
        return [...data, newValue];
      }
      const copy = [...data];
      copy.splice(index, 1, newValue);
      return copy;
    },
    updateSubtitles(newSubtitles) {
      this.subtitles = this.injectData(
        this.subtitles,
        newSubtitles,
        (subtitles) => subtitles.locale === newSubtitles.locale,
      );
    },
    addSubtitles() {
      this.isSubtitlesModal = true;
      this.changeActiveSubtitles(null);
    },
    editSubtitles(subtitles) {
      this.isSubtitlesModal = true;
      this.changeActiveSubtitles(subtitles.locale);
    },
    deleteSubtitles(subtitles) {
      this.subtitles = subtitles;
    },
    async getSubtitles(assetId) {
      const { data } = await video.getSubtitles(assetId);
      this.subtitles = data.map((subtitles) => ({
        ...subtitles,
        label: this.$t(`languages.${subtitles.locale}`),
      }));
    },
  },
  watch: {
    activeLesson(newValue) {
      if (newValue.assetId) this.getSubtitles(newValue.assetId);
    },
    subtitles(newValue) {
      this.$emit('update-data', {
        subtitlesLanguages: newValue.map((subtitles) => subtitles.locale),
      });
    },
  },
  mounted() {
    this.$refs.input.$refs.input.focus({ preventScroll: true });
  },
  async created() {
    if (this.activeLesson.assetId) this.getSubtitles(this.activeLesson.assetId);
  },
};
</script>
<style lang="scss" src="./AddStepVideos.scss" scoped />
