<template>
  <portal
    to="modal"
    v-if="value"
  >
    <transition
      name="page-fade"
      mode="out-in"
      appear
    >
      <div
        class="base-modal__overlay"
        :class="corner ? `base-modal__overlay--${corner}` : ''"
        @click.self="closeModal()"
      >
        <div
          class="base-modal"
          :class="[
            size === 'small' ? 'base-modal--small' : false,
            size === 'medium' ? 'base-modal--medium' : false,
            size === 'big' ? 'base-modal--big' : false,
            frameless === true ? 'base-modal--frameless' : false,
            overflowHidden ? 'base-modal--overflow-hidden' : false,
          ]"
        >
          <header class="base-modal__header">
            <h4 class="base-modal__header-title">
              {{ title}}
            </h4>
            <base-close-icon
              @click="closeModal()"
              class="base-modal__header-close"
            />
          </header>

          <div class="base-modal__content">
            <slot name="content"></slot>
          </div>

          <footer class="base-modal__footer">
            <slot name="footer">
              <div class="base-modal__actions" v-if="footer">
                <base-button
                  type="text"
                  @click="closeModal()"
                  :title="$t('modal.close')"
                  class="base-modal__actions-button"
                >
                  {{ $t('modal.close') }}
                </base-button>
                <base-button
                  type="default"
                  @click="confirm()"
                  :title="$t('modal.accept')"
                  class="base-modal__actions-button"
                >
                  {{ $t('modal.accept') }}
                </base-button>
              </div>
            </slot>
          </footer>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    title: {
      type: [Number, String],
      required: false,
      default: '',
    },
    size: {
      type: String,
      required: false,
      default: 'medium',
    },
    value: {
      type: Boolean,
      required: false,
    },
    footer: {
      type: Boolean,
      required: false,
      default: true,
    },
    frameless: {
      type: Boolean,
      required: false,
      default: false,
    },
    overflowHidden: {
      type: Boolean,
      required: false,
      default: false,
    },
    corner: {
      type: String,
      required: false,
      default: 'center',
    },
  },
  methods: {
    closeModal() {
      this.$emit('input', false);
    },
    handleEscClick({ key }) {
      key === 'Escape' ? this.closeModal() : false;
    },
    confirm() {
      this.$emit('accept');
    },
  },
  mounted() {
    document.addEventListener('keydown', this.handleEscClick);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleEscClick);
  },
};
</script>

<style lang="scss" src="./BaseModal.scss" />
