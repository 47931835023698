<template>
  <base-modal
    size="big"
    :title="$t('addCourse.modal.title')"
    :value="true"
    @input="$emit('input')"
    :footer="false"
    class="subtitles-modal"
  >
    <validation-observer
      ref="form"
      slot="content"
      tag="form"
      @submit.prevent="addSubtitle"
      class="subtitles-modal__form"
    >
      <base-select
        required
        :helpfulText="$t('addCourse.select_language')"
        :text="$t('addCourse.select_language')"
        :name="$t('addCourse.forms.language')"
        :value="language"
        @change="handleChangeLanguage"
        rules="required"
        ref="select"
      >
        <template v-slot:header>
          <country-label :countryKey="language" v-if="language" />
          <span v-else>{{ $t("addCourse.select_language") }}</span>
        </template>

        <template v-slot:items>
          <base-option
            v-for="option in languagesList.filter(
              language => language.key !== 'us'
            )"
            :key="option.key"
            :value="option.key"
            :label="$t(`languages.${option.key}`)"
          >
            <country-label :countryKey="option.key">
              {{ $t(`languages.${option.key}`) }}
            </country-label>
          </base-option>
        </template>
      </base-select>
      <base-input
        v-model="current.text"
        class="subtitles-modal__text"
        :label="$t('addCourse.modal.addSubtitle')"
        :placeholder="$t('addCourse.modal.area')"
        rules="required"
        :name="$t('addCourse.modal.addSubtitle')"
      />

      <div class="subtitles-modal__stamp">
        <base-input
          :rules="`required|is_smaller:${current.end}`"
          :label="$t('addCourse.modal.from')"
          :name="$t('addCourse.modal.from')"
          type="time"
          step="1"
          v-model="current.start"
        />

        <base-input
          :rules="`required|is_greater:${current.start}`"
          :label="$t('addCourse.modal.to')"
          :name="$t('addCourse.modal.to')"
          type="time"
          step="1"
          v-model="current.end"
        />

        <base-button nativeType="submit">
          {{ $t("addCourse.modal.addSubtitle") }}
        </base-button>

        <transition name="page-fade" mode="out-in">
          <span class="subtitles-modal__error" v-if="isOverlappedError">
            {{ $t("addCourse.modal.overlapped") }}
          </span>
        </transition>
      </div>

      <div class="subtitles-modal__list">
        <label class="subtitles-modal__label" for="subtitles-table">
          {{ $t("addCourse.modal.list") }}
        </label>

        <base-table :columns="columns" :data="subtitles" id="subtitles-table">
          <template slot-scope="{ row, index }">
            <td>{{ index + 1 }}.</td>
            <td>
              {{ formatTime(row.start) }}
            </td>
            <td>{{ formatTime(row.end) }}</td>
            <td>
              {{ row.duration }}
            </td>
            <td>{{ row.text }}</td>
            <td>
              <button
                class="subtitles-modal__delete"
                @click="deleteSubtitle(index)"
                type="button"
              >
                <img
                  src="/assets/icons/trash.svg"
                  alt=""
                  class="subtitles-modal__delete-icon"
                />
              </button>
            </td>
          </template>
        </base-table>
        <div class="subtitles-modal__actions">
          <base-button
            class="subtitles-modal__delete-locale"
            type="outline-reverse"
            @click="handleDeleteSubtitles"
            :title="$t('addCourse.modal.delete.title')"
          >
            {{ $t("addCourse.modal.delete.text") }}
          </base-button>

          <base-button class="subtitles-modal__submit" @click="submitModal">
            {{ $t("addCourse.modal.submit") }}
          </base-button>
        </div>
      </div>
    </validation-observer>
  </base-modal>
</template>

<script>
import i18n from '@/i18n';

import { stringifySync, parseSync } from 'subtitle';
import {
  convertTimeToSeconds,
  setSubtitlesDuration,
  convertSecondsToTime,
} from '@/helpers/time';
import { extend } from 'vee-validate/dist/vee-validate.full';
import video from '@/api/upload/video';
import { mapState } from 'vuex';

const isGreater = {
  message: (field, value) => i18n.t('addCourse.modal.isGreater', { field, value: value[0] }),
  validate: (end, [start]) => !!setSubtitlesDuration(start, end),
};

const isSmaller = {
  message: (field, value) => i18n.t('addCourse.modal.isSmaller', { field, value: value[0] }),
  validate: (start, [end]) => !!setSubtitlesDuration(start, end),
};

extend('is_greater', isGreater);
extend('is_smaller', isSmaller);

export default {
  name: 'SubtitlesModal',
  data: () => ({
    isOverlappedError: false,
    current: {
      start: '00:00:00',
      end: '00:00:01',
      text: '',
    },
    language: null,
    subtitles: [],
  }),
  props: {
    course: {
      type: Object,
      required: true,
    },
    activeSubtitles: {
      type: String,
      required: false,
      default: '',
    },
    activeLessonId: {
      type: String,
      required: false,
      default: '',
    },
    subtitlesList: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState({
      languagesList: (state) => state.language.languagesList,
    }),
    columns() {
      const keys = ['no', 'start', 'end', 'duration', 'added', 'delete'];
      return [...this.$t('addCourse.modal.columns'), ''].map(
        (column, index) => ({
          key: keys[index],
          label: column,
        }),
      );
    },
    activeLesson() {
      return this.course.lessons.find(
        (lesson) => lesson.id === this.activeLessonId,
      );
    },
  },
  methods: {
    findSubtitlesInTheSameLangauge(language) {
      return this.subtitlesList.find(
        (subtitles) => subtitles.locale === language.value,
      );
    },
    handleChangeLanguage(language) {
      this.language = language.value;

      const subtitlesInTheSameLangauge = this.findSubtitlesInTheSameLangauge(
        language,
      );
      if (subtitlesInTheSameLangauge) {
        this.$emit('change-active', subtitlesInTheSameLangauge.locale);
      }
    },
    async submitModal() {
      console.log('a');
      if (this.subtitles.length === 0) {
        return;
      }
      const vtt = stringifySync(
        this.subtitles.map((subtitle) => ({
          type: 'cue',
          data: {
            start: convertTimeToSeconds(subtitle.start) * 1000, // milliseconds
            end: convertTimeToSeconds(subtitle.end) * 1000,
            text: subtitle.text,
          },
        })),
        { format: 'WebVTT' },
      );
      this.$emit('update-data', {
        locale: this.language,
        label: this.$t(`languages.${this.language}`),
      });
      this.$emit('input');

      try {
        await video.addSubtitles(this.activeLesson.assetId, this.language, vtt);
      } catch (err) {
        this.$store.dispatch('notification/spawn', {
          errorMessage: err.response.data.message,
          type: 'error',
        });
      }
    },
    formatTime(time) {
      const pieces = time.split(':');
      if (pieces.length === 2) {
        return `${time}:00`;
      }
      return time;
    },
    async addSubtitle() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }
      const { start, end, text } = this.current;
      const startInSeconds = convertTimeToSeconds(start);
      const endInSeconds = convertTimeToSeconds(end);
      const isOverlapped = () => this.subtitles.some((subtitle) => {
        const originalstartInSeconds = convertTimeToSeconds(subtitle.start);
        const originalendInSeconds = convertTimeToSeconds(subtitle.end);
        return (
          (originalendInSeconds > startInSeconds
              && endInSeconds > originalstartInSeconds)
            || (originalstartInSeconds < endInSeconds
              && originalendInSeconds > startInSeconds)
            || (originalstartInSeconds < startInSeconds
              && originalendInSeconds > endInSeconds)
        );
      });
      if (isOverlapped()) {
        this.isOverlappedError = true;
        return;
      }
      this.isOverlappedError = false;
      this.current = {
        text: '',
        start: end,
        end: convertSecondsToTime(endInSeconds + 1),
      };
      const duration = setSubtitlesDuration(start, end);
      this.subtitles.push({
        duration,
        start,
        end,
        text,
      });

      this.subtitles.sort(
        (a, b) => convertTimeToSeconds(a.start) - convertTimeToSeconds(b.start),
      );
    },
    async handleDeleteSubtitles() {
      if (!this.language) return;

      const { data } = await video.getSubtitles(this.activeLesson.assetId);
      const isNew = () => data.findIndex((subtitles) => subtitles.locale === this.language) === -1;

      if (isNew()) {
        this.deleteLocalSubtitles();
        return;
      }

      this.$store.dispatch('ui/setPreloader', true);

      try {
        await video.deleteSubtitles(this.activeLesson.assetId, this.language);
        this.deleteLocalSubtitles();
      } catch (err) {
        this.$store.dispatch('notification/spawn', {
          errorMessage: err.response.data.message,
          type: 'error',
        });
      } finally {
        setTimeout(() => {
          this.$store.dispatch('ui/setPreloader', false);
        }, 300);

        this.$emit('input'); // close modal
      }
    },
    deleteSubtitle(index) {
      this.subtitles.splice(index, 1);
    },
    deleteLocalSubtitles() {
      const payload = this.subtitlesList.filter(
        (subtitles) => subtitles.locale !== this.language,
      );

      this.$emit('delete-subtitles', payload);
    },
    async convertSubtitles(locale) {
      const { data } = await video.getLocaleVideo({
        courseId: this.course.id,
        lessonId: this.activeLesson.id,
        locale,
      });
      const parsedVtt = parseSync(data);

      this.subtitles = parsedVtt
        .filter((subtitles) => subtitles.type === 'cue')
        .map((subtitles) => {
          const { start, end, text } = subtitles.data;
          const startInString = convertSecondsToTime(start, {
            miliseconds: true,
          });
          const endInString = convertSecondsToTime(end, { miliseconds: true });
          const duration = setSubtitlesDuration(startInString, endInString);

          return {
            start: startInString,
            end: endInString,
            text,
            duration,
          };
        });
    },
  },
  watch: {
    async activeSubtitles(value) {
      this.language = value;
      this.convertSubtitles(value);
    },
  },
  async created() {
    if (!this.activeSubtitles) return;

    this.language = this.activeSubtitles;
    this.convertSubtitles(this.activeSubtitles);
  },
};
</script>
<style lang="scss" scoped src="./SubtitlesModal.scss" />
