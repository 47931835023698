<template>
  <div class="certificate-label" >
      <img
        src="/assets/icons/quality.svg"
        alt=""
        class="certificate-label__icon"
      />
      <p class="certificate-label__text">
        {{ $t('become-author.certificate.label') }}
      </p>
    </div>
</template>

<script>
export default {
  name: 'CertificateLabel',
};
</script>
<style lang="scss" scoped src="./CertificateLabel.scss"/>
