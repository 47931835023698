<template>
  <button
    class="base-account-icon"
    :aria-label="$t('nav.account')"
    :title="$t('nav.account')"
    @click="openAccount()"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.504"
      height="27.482"
      viewBox="0 0 21.504 27.482"
      class="base-account-icon__image"
    >
      <g id="Group_9" data-name="Group 9" transform="translate(1.03 1)">
        <circle
          :class="mobileMenuStatus ? 'base-account-icon__image--active' : ''"
          id="Ellipse_2"
          data-name="Ellipse 2"
          cx="5.663"
          cy="5.663"
          r="5.663"
          transform="translate(4.059)"
          fill="rgba(0,0,0,0)"
          stroke="#1a1a1a"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <path
          :class="mobileMenuStatus ? 'base-account-icon__image--active' : ''"
          id="Path_7"
          data-name="Path 7"
          d="M501.855,481.805a3.079,3.079,0,0,0,2.851-4.293,10.386,10.386,0,0,0-18.91,0,3.078,3.078,0,0,0,2.849,4.293Z"
          transform="translate(-485.529 -456.324)"
          fill="rgba(0,0,0,0)"
          stroke="#1a1a1a"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </svg>
  </button>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'BaseChartIcon',
  computed: {
    ...mapState({
      mobileMenuStatus: (state) => state.ui.mobileMenuStatus,
    }),
    ...mapGetters({
      isLogged: 'auth/isLogged',
    }),
  },
  methods: {
    openAccount() {
      this.$store.dispatch('ui/setMobileMenu');
    },
  },
};
</script>

<style lang="scss" src="./BaseAccountIcon.scss" />
