<template>
  <div v-if="user" class="dashboard-heading">
    <h1 class="dashboard-heading-inner">
      <strong class="dashboard-heading__welcome">
        {{ $t("dashboard.welcome") }},
      </strong>

      <span class="dashboard-heading__name">
        {{ user.firstName }}.
      </span>
      <span class="dashboard-heading__second-part">
        {{ $t("dashboard.welcomeSecondPart") }}
      </span>
    </h1>

    <h2 class="dashboard-heading__sub-heading">
      {{ subHeading }}
    </h2>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'DashboardHeading',
  props: {
    userType: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    subHeading() {
      return this.userType === 'user'
        ? this.$t('dashboard.user.subHeading')
        : this.$t('dashboard.author.subHeading');
    },
  },
};
</script>
<style lang="scss" scoped src="./DashboardHeading.scss" />
