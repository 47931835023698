<template>
  <nav class="mobile-navigation">
    <ul class="mobile-navigation__list">
      <li class="mobile-navigation__list-item">
        <base-account-icon />
      </li>
      <li class="mobile-navigation__list-item">
         <base-wishlist-icon />
      </li>
      <li class="mobile-navigation__list-item">
        <base-chart-icon />
      </li>
      <li class="mobile-navigation__list-item">
        <base-search-icon />
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'MobileNavigation',
};
</script>

<style lang="scss" src="./MobileNavigation.scss" />
