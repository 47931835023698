<template>
  <div class="author-details">
    <user-card
      class="author-details__card"
      :firstName="author.firstName"
      :lastName="author.lastName"
      :lvl="author.lvl"
      :avatar="author.avatarId"
      :slug="author.slug"
    />
    <div class="author-details__info">
      <h3 class="author-details__info-heading">
        {{ $t('aboutAuthor') }}
      </h3>
      <p class="author-details__info-description">
        {{ author.bio ? author.bio : $t('noDescription') }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthorDetails',
  props: {
    author: {
      type: Object,
      required: true,
    },
    course: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isValidCertificate() {
      return this.course.certificateImageId;
    },
  },
};
</script>

<style lang="scss" src="./AuthorDetails.scss" />
