<template>
  <carousel :per-page="1" class="header-slide__carousel_wrapper"
            :navigateTo="navigateTo" :navigation-enabled="!isMobile()"
            @navigation-click="navigationHandler">
    <slide v-for="(slide, index) in slides" :key="index" :index="index">
      <!--      <div class="header-slide__wrapper" v-if="index===2"-->
      <!--           style="width: 100%; text-align: center">-->
      <!--        <a href="/shop"><img src="/assets/shop/slider2.png"-->
      <!--                             style="margin-left: auto; margin-right: auto; height: 400px;"></a>-->
      <!--      </div>-->
      <div class="header-slide__wrapper">
        <div class="header-slide__info">
          <div class="header-slide__text">
            <h1
              class="header-slide__header"
              :style="{
                    fontSize: `${(windowWidth > 768 ? 150 : 80) /
                      (slides[0].text.length / 10)}px`,
                      zoom: index === 2 ? 0.8 : 1
                  }"
            >
              {{ slide.text }}
              <router-link to="auth/sign-up" class="header-slide__header-link"
                           v-if="index===0">
                {{
                  slide.headerLink.length > 1 ? slide.headerLink : slide.link.text
                }}
              </router-link>
              <a href="/join-designers" class="header-slide__header-link"
                 v-if="index===2">
                {{
                  slide.headerLink.length > 1 ? slide.headerLink : slide.link.text
                }}
              </a>
              <a href="/shop" class="header-slide__header-link"
                 v-if="index===1">
                {{
                  slide.headerLink.length > 1 ? slide.headerLink : slide.link.text
                }}
              </a>
            </h1>
          </div>
          <base-button
            type="text"
            to="/courses"
            icon="arrow-right"
            class="header-slide__link"
            v-if="index===0"
          >
            {{ slide.link.text }}
          </base-button>
          <base-button
            type="text"
            to="/join-designers"
            icon="arrow-right"
            class="header-slide__link"
            v-if="index===1"
          >
            {{ slide.link.text }}
          </base-button>
        </div>
        <div class="header-slide__image-wrapper" v-if="index===0">
          <img :src="slide.image.src" :alt="slide.image.alt"
               class="header-slide__image header-slide__image-flipped"/>
          <img
            src="/assets/images/banner-mobile.png"
            :alt="slide.image.alt"
            class="header-slide__image header-slide__image--mobile"
          />
          <div class="header-slide__banner-content">
            <h3 class="header-slide__banner-text">{{ slide.banner.text }}</h3>
            <h2 class="header-slide__banner-heading">{{
                slide.banner.heading
              }}</h2>
            <img src="/logo.svg" alt="Edunails"
                 class="header-slide__banner-logo"/>
          </div>
        </div>
        <div class="header-slide__image-wrapper" v-if="index===2">
          <video autoplay loop muted playsinline
                 src="/assets/slider/slider6.mp4"
                 width="100%" id="slider-video"></video>
        </div>
        <div class="header-slide__image-wrapper" v-if="index===1">
          <img :src="'/assets/shop/banners/'+currentLanguage.key+'.png'"
               :alt="$t('shop.label')"
               class="header-slide__image"/>
          <img
            :src="'/assets/shop/banners/'+currentLanguage.key+'.png'"
            :alt="$t('shop.label')"
            class="header-slide__image header-slide__image--mobile"
          />
          <!--          <div class="header-slide__banner-content">-->
          <!--            <h3 class="header-slide__banner-text">{{ slide.text }}</h3>-->
          <!--            <h2 class="header-slide__banner-heading">{{-->
          <!--                slide.link.text-->
          <!--              }}</h2>-->
          <!--            <img src="/logo.svg" alt="Edunails"-->
          <!--                 class="header-slide__banner-logo"/>-->
          <!--          </div>-->
        </div>
      </div>
    </slide>
  </carousel>

</template>

<script>
import { mapGetters, mapState } from 'vuex';

window.onscroll = () => {
  try {
    document.getElementById('slider-video')
      .play();
  } catch (err) {
  }
};

export default {
  name: 'HeaderSlider',
  data: () => ({
    windowWidth: null,
    navigateTo: null,
  }),
  methods: {
    isMobile() {
      return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    },
    navigationHandler() {
      const video = document.getElementById('slider-video');
      if (video) {
        video.pause();
        video.currentTime = 0;
        video.play();
      }
    },
  },
  computed: {
    ...mapGetters({
      userType: 'auth/userType',
    }),
    ...mapState({
      currentLanguage: (state) => state.language.currentLanguage,
    }),
    slides() {
      const slides = [...(this.$t('slides')
        .map((slide, index) => ({
          ...slide,
          banner: this.$t('slidesBanner')[index],
          link: {
            href: '#',
            text: this.$t('learnMore'),
          },
          image: {
            src: '/assets/images/banner.png',
            alt: slide.image,
          },
        })))];
      return [slides[0], slides[2], slides[1]];
    },
    subHeading() {
      if (this.userType === 'author') {
        return this.$t('headerSearch.subHeading.author');
      }
      return this.$t('headerSearch.subHeading.user');
    },
  },
  mounted() {
    const videoPlayer = document.querySelector('#slider-video');
    const originalSrc = videoPlayer.src;
    setTimeout(() => {
      videoPlayer.src = '';
      videoPlayer.src = originalSrc;
    }, 2000);
    this.windowWidth = window.innerWidth;
    const runSlider = () => {
      this.navigateTo = 1;
      setTimeout(() => {
        this.navigateTo = 2;
        const video = document.getElementById('slider-video');

        if (video) {
          video.pause();
          video.currentTime = 0;
          video.play();
        }

        setTimeout(() => {
          this.navigateTo = 0;
        }, 7000);
      }, 5000);
    };
    setInterval(runSlider, 17000);
    setTimeout(runSlider, 5000);
  },
};
</script>
<style lang="scss" scoped src="./HeaderSlider.scss"/>
