<template>
  <div class="main-lightbox">
    <div class="main-lightbox__overlay" />

    <div class="main-lightbox__content">
      <ul
        class="main-lightbox__list"
        :style="{
          transform: `translateX(${transformValue}%)`
        }"
        v-hammer:pan="onPan"
      >
        <li
          class="main-lightbox__list-item"
          v-for="(image, index) in lighbox.images"
          :key="`image-${index}`"
        >
          <img
            class="main-lightbox__list-image"
            :src="image"
            alt
          />
        </li>
      </ul>

      <button
        class="main-lightbox__content-button main-lightbox__content-button--prev"
        @click="goPrev()"
      >
        <img
          class="main-lightbox__content-button-icon"
          alt
          src="/assets/icons/chevron-left.svg"
        />
      </button>

      <button
        class="main-lightbox__content-button main-lightbox__content-button--next"
        @click="goNext()"
      >
        <img
          class="main-lightbox__content-button-icon"
          alt
          src="/assets/icons/chevron-right.svg"
        />
      </button>
    </div>

    <div class="main-lightbox__pagination">
      {{ activeIndex + 1 }} / {{ lighbox.images.length }}
    </div>

    <base-close-icon
      @click="closeLightbox()"
      class="main-lightbox__close"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'MainLightbox',
  data: () => ({
    activeIndex: 0,
    images: [],
    percentageCalculated: 0,
    percentage: 0,
    transformValue: 0,
    sensitivy: 25,
  }),
  computed: {
    ...mapState({
      lighbox: (state) => state.ui.lightbox,
    }),
    sliderLength() {
      return this.images.length;
    },
  },
  methods: {
    closeLightbox() {
      this.$store.dispatch('ui/closeLightbox');
    },
    handleEscClick({ key }) {
      key === 'Escape' ? this.closeLightbox() : false;
    },
    goTo(index) {
      if (index < 0) {
        this.activeIndex = 0;
      } else if (index > this.sliderLength - 1) {
        this.activeIndex -= 1;
      } else {
        this.activeIndex = index;
      }

      this.transformValue = -(100 / this.sliderLength) * this.activeIndex;
    },
    onPan(event) {
      const slider = document.querySelector('.main-lightbox__list');
      const imagesLength = this.lighbox.images.length;
      const percentage = 100 / imagesLength * event.deltaX / slider.offsetWidth;
      const { activeIndex } = this;
      const percentageCalculated = percentage - 100 / imagesLength * activeIndex;

      this.transformValue = percentageCalculated;

      if (event.isFinal) {
        if (event.velocityX > 1) {
          this.goTo(activeIndex - 1);
        } else if (event.velocityX < -1) {
          this.goTo(activeIndex + 1);
        } else if (percentage <= -(this.sensitivy / imagesLength)) {
          this.goTo(activeIndex + 1);
        } else if (percentage >= (this.sensitivy / imagesLength)) {
          this.goTo(activeIndex - 1);
        } else {
          this.goTo(activeIndex);
        }
      }
    },
    goNext() {
      this.transformValue += 100;
    },
    goPrev() {

    },
  },
  mounted() {
    document.addEventListener('keydown', this.handleEscClick);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleEscClick);
  },
  created() {
    this.activeIndex = this.lighbox.activeIndex;
    this.images = this.lighbox.images;
  },
};
</script>

<style lang="scss" src="./MainLightbox.scss" />
