<template>
  <button
    class="base-wishlist-icon"
    :aria-label="$t('wishlist.open')"
    :title="$t('wishlist.open')"
    @click="openWishlist()"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.533"
      height="27.033"
      viewBox="0 0 29.533 27.033"
      class="base-wishlist-icon__image"
      :class="[
        lengthOfWishlist > 0 ? 'base-wishlist-icon__image--active' : false,
      ]"
    >
      <path
        id="Path_10"
        data-name="Path 10"
        d="M215.295,529.909c0,8.8-8.619,14.677-12.707,16.5-.4.18-.763.317-1.06.444-.3-.127-.656-.264-1.058-.444-4.088-1.822-12.709-7.7-12.709-16.5,0-4.469,3.326-8.09,7.414-8.09a7.3,7.3,0,0,1,6.353,3.928,7.3,7.3,0,0,1,6.354-3.928C211.981,521.819,215.295,525.44,215.295,529.909Z"
        transform="translate(-186.762 -520.819)"
        fill="rgba(0,0,0,0)"
        stroke="#1a1a1a"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </svg>

    <span class="base-wishlist-icon__label" v-if="lengthOfWishlist > 0">
      {{ lengthOfWishlist }}
    </span>
  </button>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BaseChartIcon',
  computed: {
    ...mapGetters({
      lengthOfWishlist: 'wishlist/lengthOfWishlist',
    }),
  },
  methods: {
    openWishlist() {
      this.$store.dispatch('ui/setWishlist', true);
    },
  },
};
</script>

<style lang="scss" src="./BaseWishlistIcon.scss" />
