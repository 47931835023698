import i18n from '@/i18n';
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    cartStatus: false,
    wishlistStatus: false,
    overlayStatus: false,
    mobileMenuStatus: false,
    searchStatus: false,
    preloaderStatus: false,
    preloaderText: i18n.t('loading.text'),
    scrolledCourses: 0,
    lightboxStatus: false,
    lightbox: {
      activeIndex: 0,
      images: [],
    },
  },
  mutations: {
    SET_CART(state, payload) {
      state.cartStatus = payload;
    },
    SET_WISHLIST(state, payload) {
      state.wishlistStatus = payload;
    },
    SET_OVERLAY(state, payload) {
      state.overlayStatus = payload;
    },
    SET_MOBILE_MENU(state, payload) {
      state.mobileMenuStatus = payload || !state.mobileMenuStatus;
    },
    SET_SEARCH(state, payload) {
      Vue.analytics.fbq.event('Search');
      state.searchStatus = payload;
    },
    SET_PRELOADER(state, payload) {
      const status = payload.status ? payload.status : payload;
      const text = payload.text ? payload.text : 'Loading...';

      state.preloaderStatus = status || !state.preloaderStatus;
      state.preloaderText = text;
    },
    CLOSE_ALL(state, payload) {
      state.cartStatus = false;
      state.wishlistStatus = false;
      state.searchStatus = false;
      if (payload !== 'menu') {
        state.mobileMenuStatus = false;
      }
    },
    SET_LIGHTBOX(state, { index, images }) {
      state.lightboxStatus = true;
      state.lightbox.images = images || [];
      state.lightbox.activeIndex = index || 0;
    },
    CLOSE_LIGHTBOX(state) {
      state.lightboxStatus = false;
    },
    SET_SCROLLED_COURSES(state, payload) {
      state.scrolledCourses = payload;
    },
    RESET_SCROLLED_COURSES(state) {
      state.scrolledCourses = 0;
    },
  },
  actions: {
    setCart({ commit }, payload) {
      commit('CLOSE_ALL');
      commit('SET_CART', payload);
      commit('SET_OVERLAY', payload);
    },
    setWishlist({ commit }, payload) {
      commit('CLOSE_ALL');
      commit('SET_WISHLIST', payload);
      commit('SET_OVERLAY', payload);
    },
    closeAll({ commit }) {
      commit('SET_OVERLAY', false);
      commit('SET_CART', false);
      commit('SET_WISHLIST', false);
    },
    setMobileMenu({ commit }, payload) {
      commit('CLOSE_ALL', 'menu');
      commit('SET_MOBILE_MENU', payload);
    },
    setSearch({ commit }, payload) {
      commit('CLOSE_ALL');
      commit('SET_SEARCH', payload);
    },
    setPreloader({ commit }, payload) {
      commit('SET_PRELOADER', payload);
    },
    setScrolledCourses({ commit }, payload) {
      commit('SET_SCROLLED_COURSES', payload);
    },
    resetScrolledCourses({ commit }) {
      commit('RESET_SCROLLED_COURSES');
    },
    setLightbox({ commit }, payload) {
      commit('SET_LIGHTBOX', payload);
    },
    closeLightbox({ commit }) {
      commit('CLOSE_LIGHTBOX');
    },
  },
};
