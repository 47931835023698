<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.348"
    height="21.617"
    viewBox="0 0 21.348 21.617"
  >
    <g transform="translate(1.006 1)">
      <ellipse
        cx="2.908"
        cy="2.89"
        rx="2.908"
        ry="2.89"
        transform="translate(6.759 6.918)"
        fill="rgba(0,0,0,0)"
        stroke="#fff"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        class="dashboard-navigation__icon--stroke"
      />
      <path
        d="M502.333,657.461l.028.015a1.429,1.429,0,0,1,.528,1.959l-1.846,3.173a1.448,1.448,0,0,1-1.971.524l-.006,0a1.43,1.43,0,0,0-1.438,0c-.066.039-.133.078-.2.115a1.412,1.412,0,0,0-.725,1.239v0a1.438,1.438,0,0,1-1.443,1.434h-3.7a1.438,1.438,0,0,1-1.442-1.434v0a1.411,1.411,0,0,0-.721-1.235c-.066-.037-.133-.075-.2-.114a1.43,1.43,0,0,0-1.444-.007h0a1.449,1.449,0,0,1-1.971-.524l-1.847-3.176a1.429,1.429,0,0,1,.526-1.958l.024-.014a1.43,1.43,0,0,0,.719-1.246v-.2a1.428,1.428,0,0,0-.719-1.245l-.024-.015a1.428,1.428,0,0,1-.526-1.957l1.847-3.177a1.45,1.45,0,0,1,1.971-.524h0a1.433,1.433,0,0,0,1.444-.006c.065-.039.132-.077.2-.115a1.409,1.409,0,0,0,.721-1.234v0a1.438,1.438,0,0,1,1.442-1.434h3.7a1.439,1.439,0,0,1,1.443,1.434v0a1.41,1.41,0,0,0,.725,1.238c.068.038.135.076.2.115a1.43,1.43,0,0,0,1.438,0l.006,0a1.449,1.449,0,0,1,1.971.524l1.846,3.174a1.429,1.429,0,0,1-.528,1.959l-.028.015a1.429,1.429,0,0,0-.721,1.247v.193A1.432,1.432,0,0,0,502.333,657.461Z"
        transform="translate(-483.745 -646.309)"
        fill="rgba(0,0,0,0)"
        stroke="#fff"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        class="dashboard-navigation__icon--stroke"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SvgSettings',
  data: () => ({}),
  methods: {},
};
</script>
