<template>
  <section class="dashboard-stats">
    <div class="dashboard-stats__left">
      <dashboard-stat-box
        color="blue"
        type="likes"
        :name="$t('likes')"
        :value="totalLikes"
      />
      <dashboard-stat-box
        color="pink"
        type="solds"
        :name="$t('dashboard.author.sold')"
        :value="totalSolds"
      />
    </div>
    <div class="dashboard-stats__right">
      <base-button
        class="dashboard-stats__demo-banner"
        type="outline-reverse"
        @click="$emit('watch-video')"
      >
        {{ $t("dashboard.author.demoVideo") }}
      </base-button>
      <base-button
        class="dashboard-stats__demo-banner"
        type="outline-reverse"
        href="/ebook.pdf"
        to="/ebook.pdf"
        nativeLink
        :title="$t('ebook')"
        :aria-label="$t('ebook')"
        target="_blank"
        rel="noopener noreferrer"
        @click="$emit('open-ebook')"
      >
        {{ $t("ebook") }}
      </base-button>
    </div>
  </section>
</template>

<script>
export default {
  name: 'DashboardStats',
  data: () => ({}),
  props: {
    orders: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    courses: {
      type: Array,
      required: false,
      default: () => ([]),
    },
  },
  computed: {
    totalSolds() {
      return this.courses.reduce((acc, course) => (acc + course.soldCount), 0);
    },
    totalLikes() {
      return this.courses.reduce((acc, course) => (acc + course.karma), 0);
    },
  },
};
</script>
<style lang="scss" scoped src="./DashboardStats.scss" />
