var render = function render(){var _vm=this,_c=_vm._self._c;return _c('upload-file-logic',{staticClass:"upload-thumbnail",attrs:{"image":_vm.image,"rules":_vm.rules,"name":_vm.name},on:{"upload":function($event){return _vm.$emit('upload', $event)},"error":function($event){return _vm.$emit('error')},"clear-error":function($event){return _vm.$emit('clear-error')}},scopedSlots:_vm._u([{key:"default",fn:function({
    handleUpload,
    isDragOver,
    computedImage,
    handleDelete,
    isUploading,
    isError,
    errors
  }){return [_c('label',{staticClass:"upload-thumbnail__label",attrs:{"for":_vm.name,"title":_vm.helpfulText}},[(_vm.label)?_c('span',{staticClass:"upload-thumbnail__label-text"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('input',{staticClass:"upload-thumbnail__upload",attrs:{"type":"file","id":_vm.name,"name":_vm.name,"accept":"image/png, image/jpeg","disabled":computedImage},on:{"change":function($event){return handleUpload($event.target)}}}),_c('div',{staticClass:"upload-thumbnail__inner",class:[
        isDragOver ? 'upload-thumbnail__inner--is-over' : '',
        _vm.courseThumbnail ? 'upload-thumbnail__inner--course-thumbnail' : ''
      ]},[_c('transition',{attrs:{"mode":"out-in","name":"page-fade"}},[(!computedImage)?_c('div',{staticClass:"upload-thumbnail__inner-fallback"},[(isUploading)?_c('loader',{staticClass:"upload-thumbnail__loader"}):_c('img',{staticClass:"upload-thumbnail__icon",class:_vm.big && 'upload-thumbnail__icon--big',attrs:{"src":"/assets/icons/picture.svg","alt":""}}),_c('p',{staticClass:"upload-thumbnail__text",class:_vm.big && 'upload-thumbnail__text--big'},[_vm._v(" "+_vm._s(_vm.$t("uploadFile.text"))+" "),_c('strong',{staticClass:"upload-thumbnail__text-strong"},[_vm._v(" "+_vm._s(_vm.$t("uploadFile.browse"))+" ")])]),_vm._l((_vm.requirements),function(requirement){return _c('p',{key:requirement,staticClass:"upload-thumbnail__requirement"},[_vm._v(" "+_vm._s(requirement)+" ")])})],2):_vm._e(),(computedImage)?_c('img',{staticClass:"upload-thumbnail__image",attrs:{"src":computedImage,"alt":""}}):_vm._e()]),_c('transition',{attrs:{"mode":"out-in","name":"page-fade"}},[(isError)?_c('p',{staticClass:"upload-file__error upload-thumbnail__error"},[_vm._v(" "+_vm._s(_vm.$t("uploadFile.error"))+" ")]):_vm._e(),(errors[0])?_c('span',{staticClass:"base-input__error upload-file__required"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e(),(computedImage)?_c('base-button',{staticClass:"upload-thumbnail__delete",attrs:{"type":"text"},on:{"click":handleDelete}},[_c('span',[_vm._v(_vm._s(_vm.$t("uploadFile.label")))]),_c('svg-trash',{staticClass:"upload-thumbnail__delete-icon",attrs:{"fill":"#A0A4A8"}})],1):_vm._e()],1)],1)])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }