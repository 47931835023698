<template>
  <ul class="course-features">
    <li class="course-features__item course-features__item--add">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29.535"
        height="29.535"
        viewBox="0 0 29.535 29.535"
        class="course-features__item-icon"
      >
        <path
          id="check-circle"
          d="M20.784,12.027l-6.335,6.35L12.012,15.94A1.477,1.477,0,1,0,9.93,18.023l3.47,3.485a1.48,1.48,0,0,0,2.082,0l7.384-7.384a1.478,1.478,0,1,0-2.082-2.1ZM16.767,2A14.767,14.767,0,1,0,31.535,16.767,14.767,14.767,0,0,0,16.767,2Zm0,26.581A11.814,11.814,0,1,1,28.581,16.767,11.814,11.814,0,0,1,16.767,28.581Z"
          transform="translate(-2 -2)"
          fill="#858585"
        />
      </svg>
      <input
        class="course-features__item-input"
        type="text"
        name="new-feature"
        :id="`new-feature-${color}`"
        v-model="newFeature"
        :placeholder="$t('addCourse.new_option')"
        :disabled="disabled"
        @keyup.enter="AddNewFeature()"
      />
      <button
        class="course-features__enter"
        v-if="newFeature"
        type="button"
        @click="AddNewFeature()"
      >
        <svg
          class="course-features__enter-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="16.181"
          height="10.127"
          viewBox="0 0 16.181 10.127"
        >
          <path
            id="arrow-from-right"
            d="M26.8,15.193a1.012,1.012,0,0,0-.212-.334l-4.047-4.047a1.016,1.016,0,0,0-1.437,1.437l2.327,2.317H11.71a1.012,1.012,0,1,0,0,2.023H23.435l-2.327,2.317a1.016,1.016,0,1,0,1.437,1.437L26.592,16.3a1.046,1.046,0,0,0,.212-1.1Z"
            transform="translate(-10.699 -10.515)"
            fill="#858585"
          />
        </svg>
      </button>
    </li>
    <li
      class="course-features__item"
      v-for="feature in renderedFeatures"
      :key="feature"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29.535"
        height="29.535"
        viewBox="0 0 29.535 29.535"
        class="course-features__item-icon"
      >
        <path
          id="check-circle"
          d="M20.784,12.027l-6.335,6.35L12.012,15.94A1.477,1.477,0,1,0,9.93,18.023l3.47,3.485a1.48,1.48,0,0,0,2.082,0l7.384-7.384a1.478,1.478,0,1,0-2.082-2.1ZM16.767,2A14.767,14.767,0,1,0,31.535,16.767,14.767,14.767,0,0,0,16.767,2Zm0,26.581A11.814,11.814,0,1,1,28.581,16.767,11.814,11.814,0,0,1,16.767,28.581Z"
          transform="translate(-2 -2)"
          :fill="color === 'primary' ? '#fc8c9d' : '#132b50'"
        />
      </svg>
      <p class="course-features__item-text">
        {{ feature }}
      </p>
      <span
        v-if="!disabled"
        :aria-label="$t('delete')"
        :title="$t('delete')"
        @click="deleteFeature(feature)"
        class="base-close-icon course-features__item-delete"
      >
        <img
          class="base-close-icon__image"
          alt=""
          src="/assets/icons/close-big.svg"
        />
      </span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'CourseFeatures',
  data() {
    return { features: [...this.initFeatures], newFeature: '' };
  },
  props: {
    color: {
      type: String,
      required: false,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    initFeatures: {
      type: Array,
      required: false,
    },
  },
  computed: {
    renderedFeatures() {
      return [...new Set(this.features.concat(this.initFeatures))];
    },
  },
  methods: {
    AddNewFeature() {
      this.features = [...this.initFeatures, this.newFeature];
      this.newFeature = '';
      this.$emit('input', this.features);
    },
    deleteFeature(feature) {
      this.features = this.features.filter((item) => item !== feature);
      this.$emit('input', this.features);
    },
  },
};
</script>

<style lang="scss" src="./CourseFeatures.scss" />
