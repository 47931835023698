<template>
  <li
    class="blog-post"
    :class="[
      mode === 'full' ? 'blog-post--full' : false,
    ]"
  >
    <figure class="blog-post__main-image">
      <base-image
        :src="mainImage"
        class="blog-post__main-image-inner"
      />
    </figure>

    <div class="blog-post__content">
      <header class="blog-post__header">
        <ul class="blog-post__header-list" v-if="productCategories && productCategories.length">
          <li
            class="blog-post__header-category"
            v-for="category in productCategories"
            :key="category.id"
          >
            <button
              class="blog-post__header-button"
              :title="displayCategory(category.category.slug)"
              :aria-label="displayCategory(category.category.slug)"
              @click="filterByCategory(category)"
            >
              {{ displayCategory(category.category.slug) }}
            </button>
          </li>
        </ul>

        <span class="blog-post__header-date">
          {{ filteredDate }}
        </span>
      </header>

      <router-link
        class="blog-post__title"
        :to="`/blog/${slug}`"
        :aria-label="title"
        :title="title"
      >
        {{ title }}
      </router-link>

      <prismic-rich-text
        :field="content"
        class="blog-post__description prismic"
      />

      <blog-author class="blog-post__author" />
    </div>
  </li>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'BlogPost',
  props: {
    title: {
      type: String,
      required: true,
      default: () => (''),
    },
    mainImage: {
      type: String,
      required: true,
      default: () => (''),
    },
    content: {
      type: [Array, String],
      required: true,
      default: () => (null),
    },
    categories: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    publishDate: {
      type: String,
      required: true,
      default: () => (''),
    },
    slug: {
      type: String,
      required: true,
      default: () => (''),
    },
    mode: {
      type: String,
      required: false,
      default: () => ('normal'),
    },
  },
  computed: {
    filteredDate() {
      return dayjs(this.publishDate).format('MMM DD, YYYY');
    },
    productCategories() {
      return this.categories.filter((category) => category.category.id);
    },
  },
  methods: {
    displayCategory(categorySlug) {
      return categorySlug ? categorySlug.replace(/-/g, ' ') : false;
    },
    filterByCategory(payload) {
      this.$emit('filter-by-category', payload.category);
    },
  },
};
</script>

<style lang="scss" src="./BlogPost.scss" />
