<template>
  <div class="about-us-advantages">
    <ul class="about-us-advantages__list">
      <li v-for="(item, index) in $t(`aboutUs.mission.advantages.${type}`)" :key="index" class="about-us-advantages__list-item">
        <h3 class="about-us-advantages__title">{{ item.title }}</h3>
        <p class="about-us-advantages__content">{{ item.content }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'AboutUsAdvantages',
  props: {
    type: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped src="./AboutUsAdvantages.scss" />
