<template>
  <div class="author-form">
    <base-heading class="author-form__heading" align="center">
      {{ $t("become-author.heading") }}
    </base-heading>
    <p class="author-form__desc">
      {{ $t("become-author.description") }}
    </p>
    <account-details-form
      @submit="handleSubmit()"
      ref="form"
      :backButton="$t('become-author.cancel')"
      :submitButton="$t('become-author.submit')"
      :forAuthorForm="true"
      @update-data="updateData($event)"
      :additionalSections="[certificate.label, paypal.heading]"
      v-bind="$t('accountCreation.form')"
      :userData="userData"
      :is-loading="isLoading"
    >
      <template :slot="certificate.label">
        <p class="author-form__tip">
          {{ $t("become-author.certificate.tip") }}
        </p>
        <upload-file
          :image="userData.imageId"
          @upload="userData.imageId = $event"
          :label="certificate.label"
          :name="certificate.label"
          rules="required"
          :requirements="[
            $t('requirements.size', { size: '5MB' }),
            $t('requirements.format', { format: '.jpg, .png' })
          ]"
          @error="handleAddError"
          @clear-error="handleClearError"
        />
      </template>
      <template :slot="paypal.heading">
        <base-input
          v-model="userData.payPalEmail"
          rules="required|email"
          :label="paypal.label"
          :name="paypal.label"
          @error="handleAddError"
          @clear-error="handleClearError"
        />
        <div class="author-form__paypal-warning">
          <img
            src="/assets/icons/exclamation-warning.svg"
            :alt="$t('warning')"
            class="author-form__exclamation"
          />
          <p class="author-form__paypal-text">
            {{ $t("become-author.paypal.info[0]") }}
            <base-button
              type="link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.paypal.com/businesswallet/money"
              to="https://www.paypal.com/businesswallet/money"
              nativeLink
              class="author-form__paypal-link"
            >
              {{ $t("become-author.paypal.info[1]") }}
            </base-button>
            {{ $t("become-author.paypal.info[2]") }}
          </p>
        </div>
      </template>

      <validation-observer ref="agreements">
        <base-checkbox v-model="userData.signedAgreement" required>
          <p>
            {{ $t("signUp.formFields.terms[0]") }}
            <a
              href="/instructor-terms.pdf"
              target="_blank"
              rel="noreferrer noopener"
              class="author-form__link"
            >
              {{ $t("footer.instructorTerms") }}
            </a>
          </p>
        </base-checkbox>
      </validation-observer>
    </account-details-form>
  </div>
</template>
<script>
export default {
  name: 'AuthorForm',
  props: {
    paypal: {
      type: Object,
      required: true,
    },
    certificate: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    agreements: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    userData: {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      email: '',
      city: '',
      street: '',
      postalCode: '',
      country: '',
      companyName: '',
      companyTaxId: '',
      imageId: '',
      payPalEmail: '',
      isCompany: false,
      signedAgreement: false,
      companyCity: '',
      companyStreet: '',
      companyPostalCode: '',
      companyCountry: '',
    },
  }),
  created() {
    this.userData = { ...this.userData, ...this.user };
  },
  methods: {
    updateData(event) {
      this.userData = { ...this.userData, ...event };
    },
    handleAddError(event) {
      this.$refs.form.handleAddError(event);
    },
    handleClearError(event) {
      this.$refs.form.handleClearError(event);
    },
    async handleSubmit() {
      const validAgreements = await this.$refs.agreements.validate();

      if (validAgreements) {
        this.$emit('form-sent', {
          success: true,
          userData: this.userData,
        });
      }
    },
  },
};
</script>
<style lang="scss" src="./AuthorForm.scss" scoped />
