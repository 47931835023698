<template>
  <section class="courses-listing">
    <ul
      class="courses-listing__grid"
      ref="list"
      v-if="courses"
    >
      <course-tile
        v-for="course in courses"
        :key="course.id"
        :course="course"
        :isSold="ownedCoursesIds.includes(course.id)"
      />
    </ul>
    <p class="courses-listing__empty" v-else>
      {{ $t('courses.noCourses') }}
    </p>
  </section>
</template>

<script>
export default {
  name: 'CoursesListing',
  props: {
    courses: {
      type: Array,
      required: false,
    },
    ownedCoursesIds: {
      type: Array,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped src="./CoursesListing.scss"/>
