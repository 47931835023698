<template>
  <div
    class="base-accordion"
    :class="separated && 'base-accordion--separated'"
    aria-multiselectable="true"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BaseAccordion',
  componentName: 'Accordion',
  data() {
    return {
      activeNames: [].concat(this.value),
    };
  },
  props: {
    isAccordion: {
      type: Boolean,
      required: false,
      default: false,
    },
    transitionName: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    separated: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: [String, Number, Array, Boolean],
      required: false,
      default() {
        return [];
      },
    },
  },
  provide() {
    return {
      accordion: this,
    };
  },
  watch: {
    value(value) {
      this.activeNames = [].concat(value);
    },
  },
  methods: {
    setActiveNames(activeNames) {
      activeNames = [].concat(activeNames);
      const value = this.isAccordion ? activeNames[0] : activeNames;
      this.activeNames = activeNames;
      this.$emit('input', value);
      this.$emit('change', value);
    },
    handleItemClick(item) {
      if (this.isAccordion) {
        this.setActiveNames(
          (this.activeNames[0] || this.activeNames[0] === 0)
            && this.activeNames[0] === item.name
            ? ''
            : item.name,
        );
      } else {
        const activeNames = this.activeNames.slice(0);
        const index = activeNames.indexOf(item.name);
        if (index > -1) {
          activeNames.splice(index, 1);
        } else {
          activeNames.push(item.name);
        }

        this.setActiveNames(activeNames);
      }
    },
  },
  created() {
    this.$on('item-click', this.handleItemClick);
    if (this.disabled) {
      this.$emit('input', '');
      this.$emit('change', '');
    }
  },
};
</script>

<style lang="scss" src="./BaseAccordion.scss" />
