<template>
  <validation-provider
    :rules="rules"
    :name="name"
    ref="provider"
    v-slot="{ errors }"
  >
    <div
      @drop.prevent="handleUpload($event.dataTransfer)"
      @dragover.prevent="handleDragOver"
      @dragleave="isDragOver = false"
    >
      <slot
        :computedImage="localImage || imageLink"
        :errors="errors"
        :handleUpload="handleUpload"
        :isDragOver="isDragOver"
        :handleDelete="handleDelete"
        :isError="isError"
        :isUploading="isUploading"
      ></slot>
    </div>
  </validation-provider>
</template>

<script>
import imageCompression from 'browser-image-compression';
import resource from '@/api/services/resource';
import { getImageLink } from '@/helpers';

export default {
  name: 'UploadFileLogic',
  data: () => ({
    isDragOver: false,
    isError: false,
    localImage: null,
    isUploading: false,
  }),
  props: {
    image: {
      type: String,
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
    rules: {
      type: [String, Object],
      required: false,
      default: '',
    },
    veeValidate: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxSizeMB: {
      type: Number,
      required: false,
      default: 0.3,
    },
  },
  computed: {
    imageLink() {
      return this.image && getImageLink(this.image);
    },
  },
  methods: {
    async handleUpload(source) {
      if (this.image) {
        return;
      }
      this.isDragOver = false;
      const file = source.files[0];
      if (!file) {
        return;
      }
      this.isUploading = true;
      const isValidFile = () => (file.type === 'image/png' || file.type === 'image/jpeg')
        && file.size < 5 * 1024 * 1024;
      const { valid } = await this.$refs.provider.validate(file);
      if (this.veeValidate ? valid : isValidFile()) {
        this.isError = false;
        this.$emit('clear-error');
        try {
          const compressedFile = await imageCompression(file, {
            useWebWorker: true,
            maxSizeMB: this.maxSizeMB,
          });
          const formData = new FormData();
          formData.append('file', compressedFile);
          const { data } = await resource.addResource(formData);
          this.$emit('upload', data.id);
          const base64Data = await imageCompression.getDataUrlFromFile(
            compressedFile,
          );
          this.localImage = base64Data;
        } catch (err) {
          this.$store.dispatch('notification/spawn', {
            errorMessage: err.response.data.message,
            type: 'error',
          });
        }
      } else {
        this.isError = true;
        this.$emit('error');
      }
      this.isUploading = false;
      this.$emit('uploaded');
    },
    handleDragOver() {
      this.isDragOver = true;
    },
    async handleDelete() {
      try {
        await resource.deleteResource(this.image);
      } catch (err) {
        this.$store.dispatch('notification/spawn', {
          errorMessage: err.response.data.message,
          type: 'error',
        });
      } finally {
        this.$emit('upload', null);
        this.localImage = null;
      }
    },
  },
  mounted() {
    if (this.image && !this.veeValidate) {
      this.$refs.provider.validate(this.image);
    }
  },
};
</script>
