<template>
  <section
    class="course-info"
    :class="[mode === 'mobile' ? 'course-info--mobile' : false]"
  >
    <user-card
      class="course-info__card"
      :firstName="course.author.firstName"
      :lastName="course.author.lastName"
      :lvl="course.author.lvl"
      :avatar="course.author.avatarId"
      :slug="course.author.slug"
      v-if="mode === 'desktop'"
    />
    <certificate-label v-if="isValidCertificate"/>
    <h1 class="course-info__heading">
      {{ course.name }}
    </h1>

    <div class="course-info-details">
      <div class="course-info-details__column">
        <h3 class="course-info-details__heading">
          {{ $t('course.lvl') }}
        </h3>
        <div class="course-info-details__content">
          <base-difficulty
            :difficulty="course.difficulty"
            class="course-info-details__content-icon"
          />

          <p class="course-info-details__content-value">
            {{ $t(`levels.${course.difficulty}`) }}
          </p>
        </div>
      </div>
      <div class="course-info-details__column">
        <h3 class="course-info-details__heading">
          {{ $t('course.length') }}
        </h3>
        <div class="course-info-details__content">
          <p class="course-info-details__content-value">
            {{ courseDuration }}
          </p>
        </div>
      </div>
      <div class="course-info-details__column">
        <h3 class="course-info-details__heading">
          {{ $t('course.likes') }}
        </h3>
        <div class="course-info-details__content">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12.409"
            height="13.431"
            viewBox="0 0 12.409 13.431"
            class="course-info-details__content-icon"
          >
            <g
              id="Group_52"
              data-name="Group 52"
              transform="translate(0.75 0.802)"
            >
              <rect
                id="Rectangle_40"
                data-name="Rectangle 40"
                width="2.785"
                height="7.59"
                rx="1.393"
                transform="translate(0 4.005)"
                stroke-width="1.5"
                stroke="#fc8c9d"
                stroke-linecap="round"
                stroke-linejoin="round"
                fill="rgba(0,0,0,0)"
              />
              <path
                id="Path_39"
                data-name="Path 39"
                d="M365.839,657.3a1.255,1.255,0,0,0,.81.429l3.871.444a1.864,1.864,0,0,0,2.055-1.577l.877-4.367a1.276,1.276,0,0,0-1.2-1.526l-1.691-.062a.36.36,0,0,1-.308-.521l1.052-2.1a1.178,1.178,0,0,0-.482-1.558h0a1.177,1.177,0,0,0-1.448.245l-4.014,4.481V656a1.985,1.985,0,0,0,.481,1.3Z"
                transform="translate(-362.573 -646.317)"
                fill="rgba(0,0,0,0)"
                stroke="#fc8c9d"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
            </g>
          </svg>
          <p class="course-info-details__content-value">
            {{ course.karma }}
          </p>
        </div>
      </div>
    </div>
    <div style="margin-top: 24px;">
      <h3 class="course-info-details__heading">
        {{ $t('headerSearch.select.language') }}
      </h3>
      <div class="course-info-details__content">
        <p class="course-info-details__content-value">
          {{
            course.languages.split(',')
              .map(language => $t(`languages.${language}`))
              .join(', ')
          }}
        </p>
      </div>
    </div>
    <div style="margin-top: 24px;" v-if="course.subtitles && course.subtitles.length > 0">
      <h3 class="course-info-details__heading">
        {{ $t('addCourse.add-video.subtitles') }} ({{ $t('courseInfo.subtitles') }})
      </h3>
      <div class="course-info-details__content">
        <p class="course-info-details__content-value">
          {{
            course.subtitles.split(',')
              .map(language => $t(`languages.${language}`))
              .join(', ')
          }}
        </p>
      </div>
    </div>
    <p class="course-info__text">
      {{ course.description | shortText(300) }}
    </p>

    <footer class="course-info__bottom" v-if="!isDemo">
      <div class="course-info__bottom-info">
        <p class="course-info__bottom-label">
          {{ $t('courseInfo.price') }}
        </p>

        <h6 class="course-info__bottom-price">
          <base-price :promoPrice="getFinalPrice(course)"
                      :price="course.price"/>
        </h6>
      </div>
      <div class="course-info__bottom-actions" v-if="!isBought">
        <base-button @click="addToCart()" class="course-info__bottom-add">
          {{ $t('courseInfo.cart') }}
        </base-button>
        <base-add-wish
          @click="addToWishlist()"
          class="course-info__bottom-wishlist"
          :is-on-wish-list="isOnWishlist"
        />
      </div>
    </footer>
  </section>
</template>

<script>
import { isValidCertificate } from '@/helpers';
import { secondsToTime } from '@/helpers/time';

export default {
  name: 'CourseInfo',
  props: {
    course: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      required: false,
      default: 'desktop',
    },
    isDemo: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    wishlist() {
      return this.$store.state.wishlist.list;
    },
    isOnWishlist() {
      return this.wishlist.includes(this.course);
    },
    courseDuration() {
      return secondsToTime(this.course.duration, false);
    },
    isBought() {
      return this.course.authorId === this.userID;
    },
    userID() {
      return this.$store.state.auth.user && this.$store.state.auth.user.id;
    },
    isValidCertificate() {
      return isValidCertificate(this.course);
    },
  },
  methods: {
    getFinalPrice(course) {
      const local = new Date();
      const now = new Date(local.getTime() + local.getTimezoneOffset() * 60000);
      const promoEndDate = new Date(new Date(Date.parse(this.course.promotionEnd)).getTime() + local.getTimezoneOffset() * 60000);
      const diff = (promoEndDate - now);
      if (diff <= 0) return null;
      if (course.promotionEnd == null) return null;
      return (course.price * (100 - course.promotionValue)) / 100;
    },
    addToWishlist() {
      if (this.isOnWishlist) {
        this.$store.dispatch('wishlist/deleteWishlistItem', this.course.id);
      } else {
        this.$store.dispatch('wishlist/addToWishlist', this.course);
      }
    },
    addToCart() {
      this.$store.dispatch('cart/addToCart', this.course);
    },
  },
};
</script>

<style lang="scss" src="./CourseInfo.scss"/>
