<template>
  <upload-file-logic
    class="upload-avatar"
    v-slot="{
      handleUpload,
      isDragOver,
      computedImage,
      handleDelete,
      isUploading,
      isError
    }"
    :image="image"
    :rules="rules"
    :name="name"
    @upload="$emit('upload', $event)"
    @error="$emit('error')"
    @clear-error="$emit('clear-error')"
  >
    <label
      :for="name"
      class="upload-avatar__label"
      :class="[
        computedImage ? 'upload-avatar__label--uploaded' : '',
        isDragOver ? 'upload-avatar__label--is-over' : ''
      ]"
      :title="helpfulText"
    >
      <user-avatar
        :user="user"
        size="large"
        class="upload-avatar__image"
        :localImage="computedImage"
      />
      <loader
        color="blue"
        v-if="isUploading"
        class="upload-avatar__loader"
        thin
      />
      <input
        type="file"
        :id="name"
        :name="name"
        accept="image/png, image/jpeg"
        class="upload-avatar__upload"
        @change="handleUpload($event.target)"
        :disabled="computedImage"
      />
      <transition mode="out-in" name="page-fade">
        <p
          class="upload-avatar__help"
          :class="isDragOver ? 'upload-avatar__help--is-over' : ''"
          v-if="!computedImage"
        >
          {{ label }}
          <img
            src="/assets/icons/upload.svg"
            alt=""
            class="upload-avatar__help-icon"
          />
        </p>
        <delete-button
          @click="handleDelete"
          v-if="computedImage"
          class="upload-avatar__delete"
        >
        <span>{{ $t("uploadFile.label") }}</span>
        </delete-button>
      </transition>
      <transition mode="out-in" name="page-fade">
        <p class="upload-file__error upload-avatar__error" v-if="isError">
          {{ $t("uploadFile.error") }}
        </p>
      </transition>
    </label>
  </upload-file-logic>
</template>

<script>
export default {
  name: 'UploadAvatar',
  props: {
    user: {
      type: Object,
      required: true,
    },
    image: {
      type: String,
      required: false,
      default: null,
    },
    rules: {
      type: [String, Object],
      required: false,
      default: '',
    },
    helpfulText: {
      type: String,
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
      default: 'Field',
    },
  },
};
</script>
<style lang="scss" scoped src="./UploadAvatar.scss" />
