var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-pagination"},[_c('button',{staticClass:"base-pagination__button",class:[
      !_vm.hasPrev ? 'base-pagination__button--disabled' : false,
    ],attrs:{"disabled":!_vm.hasPrev},on:{"click":function($event){return _vm.changePage(_vm.prevPage)}}},[_c('svg',{staticClass:"base-pagination__button-icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"8","height":"14","viewBox":"0 0 8 14","fill":"none"}},[_c('path',{staticClass:"base-pagination__button-path",attrs:{"d":"M7 1L0.999999 7L7 13","stroke":"#132B50","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_c('div',{staticClass:"base-pagination__content"},[_c('ul',{staticClass:"base-pagination__list"},[(_vm.hasFirst)?_c('li',{staticClass:"base-pagination__list-item"},[_c('button',{staticClass:"base-pagination__list-button",on:{"click":function($event){return _vm.changePage(1)}}},[_vm._v(" 1 ")])]):_vm._e(),(_vm.hasFirst)?_c('li',{staticClass:"base-pagination__list-item base-pagination__list-item--dots"},[_vm._v(" ... ")]):_vm._e(),_vm._l((_vm.pages),function(page){return _c('li',{key:page,staticClass:"base-pagination__list-item"},[_c('button',{staticClass:"base-pagination__list-button",class:[
            _vm.current === page ? 'base-pagination__list-button--active' : false,
          ],on:{"click":function($event){return _vm.changePage(page)}}},[_vm._v(" "+_vm._s(page)+" ")])])}),(_vm.hasLast)?_c('li',{staticClass:"base-pagination__list-item base-pagination__list-item--dots"},[_vm._v(" ... ")]):_vm._e(),(_vm.hasLast)?_c('li',{staticClass:"base-pagination__list-item"},[_c('button',{staticClass:"base-pagination__list-button",class:[
            _vm.current === _vm.totalPages ? 'base-pagination__list-button--active' : false,
          ],on:{"click":function($event){return _vm.changePage(_vm.totalPages)}}},[_vm._v(" "+_vm._s(_vm.totalPages)+" ")])]):_vm._e()],2)]),_c('button',{staticClass:"base-pagination__button",class:[
      !_vm.hasNext ? 'base-pagination__button--disabled' : false,
    ],attrs:{"disabled":!_vm.hasNext},on:{"click":function($event){return _vm.changePage(_vm.nextPage)}}},[_c('svg',{staticClass:"base-pagination__button-icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"8","height":"14","viewBox":"0 0 8 14","fill":"none"}},[_c('path',{staticClass:"base-pagination__button-path",attrs:{"d":"M1 13L7 7L1 1","stroke":"#132B50","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }