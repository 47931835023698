import Cookies from 'js-cookie';
import config from '@/config';

export function uuid() {
  return Math.random()
    .toString(16)
    .slice(2);
}

export function isDashboard(path) {
  return (
    path.includes('/dashboard')
    || path.includes('/my-courses')
    || path.includes('/my-payments')
    || path.includes('/settings')
    || path.includes('/add-course')
    || path.includes('/requests')
    || path.includes('/become-author')
    || path.includes('/requests')
    || path.includes('/manage-course')
    || path.includes('/become-author')
    || path.includes('/added-courses')
    || path.includes('/sales-history')
    || path.includes('/payouts')
  );
}

export function setItemToLocalStorage(key, value) {
  if (!Cookies.get('cookiesAgreement')) {
    return;
  }
  localStorage.setItem(key, value);
}

export function isStringEmpty(string) {
  return (!string || string.length === 0);
}

export function getImageLink(id) {
  return `${config.apiServerURL}resources/${id}`;
}

export function isValidCertificate(course) {
  const certificateValues = [
    course.certificateNameBoxPositionX,
    course.certificateNameBoxPositionY,
    course.certificateNameBoxScale,
    course.certificateDateBoxPositionX,
    course.certificateDateBoxPositionY,
    course.certificateDateBoxScale,
    course.certificateImageId,
  ];
  return (
    certificateValues.filter(Boolean).length === certificateValues.length
  );
}
