<template>
  <li
    class="course-tile"
    :class="withObserver ? 'course-tile--with-observer' : ''"
  >
    <div
      v-if="promotionDuration != null && showPromo"
      style="position: absolute; z-index: 10; right: 0; top: -28px; background-color: #FF1617; font-weight: bold; color: white; border-radius: 8px; padding: 0 12px;">
      Sale -{{ course.promotionValue }}%
    </div>
    <router-link
      :to="!disabled && `${link}/${linkDetail}`"
      :aria-label="course.name"
      :title="course.name"
      class="course-tile__link"
    >    
      <div class="course-tile__video">

      <img
        class="course-tile__video-poster"
        :class="{ 'course-tile__video-poster--hide': videoStatus }"
        alt=""
        :src="coverUrl"
      />
      <span v-if="showPromo&&promotionDuration!=null"
            style="position: absolute; z-index: 10; background-color: #474BFF; bottom: 0; left: 0; border-radius: 8px; color: white; padding: 2px 8px;">{{
          promotionDuration
        }}</span>
      <!-- <video
        :controls="false"
        muted
        class="course-tile__video-inner"
        ref="video"
        playsinline
      >
        <source :src="preview.link" type="video/mp4" v-if="preview"/>
        Video not supported :(
      </video> -->
    </div>      
  </router-link>



    <div class="course-tile__content">
      <div class="course-tile__body">
        <div class="course-tile__row">
          <router-link
            :to="!disabled && `${link}/${linkDetail}`"
            :aria-label="course.name"
            :title="course.name"
            class="course-tile__link"
          >
            {{ course.name }}
            <base-tooltip style="z-index: 1" direction="right">
              <template v-slot:element>
                <img
                  src="/assets/icons/quality.svg"
                  alt=""
                  class="course-tile__certificate"
                  v-if="isValidCertificate"
                />
              </template>
              <template v-slot:content>
                <p>{{ $t('certificate.label') }}</p>
              </template>
            </base-tooltip>
          </router-link>
        </div>

        <div v-if="mode === 'normal'">
          <p class="course-tile__description">
            {{ course.description | shortText(70) }}
          </p>

          <router-link
            class="course-tile__author"
            :to="`/instructor/${course.author.slug}`"
          >
            {{ course.author.firstName }} {{ course.author.lastName }}
          </router-link>

          <div class="course-tile__details">
            <div class="course-tile__stats">
              <div class="course-tile__stats-language">
                <img src="/assets/icons/globe.svg" alt="Globe"/>

                <span class="course-tile__stats-text">
                  {{ mainLanguage }}
                </span>
                <div
                  class="course-tile__stats-other-languages"
                  v-if="otherLanguages.length > 0"
                >
                  <span class="course-tile__stats-other-languages-text">
                    +{{ otherLanguages.length }} {{ $t('more') }}
                  </span>
                  <ul class="course-tile__stats-other-languages-list">
                    <li
                      class="course-tile__stats-other-languages-list-item"
                      v-for="language in otherLanguages"
                      :key="language.key"
                    >
                      {{ $t(`languages.${language.key}`) }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="course-tile__stats-right">
                <div class="course-tile__stats-item">
                  <base-difficulty :difficulty="course.difficulty"/>
                </div>
                <!--                <div class="course-tile__stats-item">-->
                <!--                  <span class="course-tile__stats-text">-->
                <!--                    {{ course.karma }}-->
                <!--                  </span>-->
                <!--                  <img-->
                <!--                    class="course-tile__stats-icon"-->
                <!--                    alt=""-->
                <!--                    src="/assets/icons/likes.svg"-->
                <!--                  />-->
                <!--                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer class="course-tile__bottom" v-if="mode === 'normal'">
        <h5
          title="Price can change, because it's based on exchange course!"
          class="course-tile__price-wrapper"
        >
          <base-price
            :price="course.price"
            class="course-tile__price"
            originalPrice
            big
            v-if="promotionDuration==null"
          />
          <base-price
            :price="course.price"
            class="course-tile__price"
            originalPrice
            :promoPrice="course.price * (100-course.promotionValue) /100"
            big
            v-if="promotionDuration!=null"
          />
          <span v-if="isSold"> <br/>
          <small> {{ $t('apiHandler.courseBought.title') }}</small></span>
        </h5>
        <div class="course-tile__actions">
          <base-add-cart @click="addToCart()"
                         :disabled="disabled || isDemo || isSold"/>
          <base-add-wish
            @click="addToWishlist()"
            :is-on-wish-list="isOnWishlist"
            :disabled="disabled || isDemo || isSold"
            style="margin-left: 8px;"
          />
        </div>
      </footer>

      <footer
        class="course-tile__bottom course-tile__bottom--dashboard"
        v-if="mode === 'dashboard'"
      >
        <progress-bar :percent="progress"/>
        <like-button :likes="likes" :courseId="course.id"/>
      </footer>
    </div>
  </li>
</template>

<script>
import { mapState } from 'vuex';
import video from '@/api/upload/video';
import { getImageLink, isValidCertificate } from '@/helpers';

import request from '@/api';

export default {
  name: 'CourseTile',
  data: () => ({
    videoStatus: false,
    preview: null,
    playPromise: null,
    isError: false,
    videoTimeout: null,
    coverUrl: '',
  }),
  props: {
    course: {
      type: Object,
      required: true,
    },
    withObserver: {
      type: Boolean,
      required: false,
      default: false,
    },
    mode: {
      type: String,
      required: false,
      default: 'normal',
    },
    progress: {
      type: Number,
      default: 0,
    },
    likes: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSold: {
      type: Boolean,
      required: false,
      default: false,
    },
    showPromo: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    ...mapState({
      languagesList: (state) => state.language.languagesList,
    }),
    promotionDuration() {
      if (this.course.promotionEnd == null || this.course.promotionValue == null || this.course.promotionValue === 0) return null;
      const local = new Date();
      const now = new Date(local.getTime() + local.getTimezoneOffset() * 60000);
      const promoEndDate = new Date(new Date(Date.parse(this.course.promotionEnd)).getTime() + local.getTimezoneOffset() * 60000);
      const diff = (promoEndDate - now);
      if (diff <= 0) return null;
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff - hours * 1000 * 60 * 60) / (1000 * 60));
      const seconds = Math.floor((diff - hours * 1000 * 60 * 60 - minutes * 1000 * 60) / (1000));
      return `⏱️ ${hours} : ${minutes > 10 ? minutes : `0${minutes}`} : ${seconds > 9 ? seconds : `0${seconds}`}`;
    },
    isValidCertificate() {
      return isValidCertificate(this.course);
    },
    isDemo() {
      return this.course.description.includes('DEMO - NOT FOR SALE');
    },
    otherLanguages() {
      if ((this.course.subtitles?.trim()?.length || 0) > 0) {
        const courseLanguages = this.course.subtitles.split(',').slice(1);
        return this.languagesList.filter((language) => courseLanguages.includes(language.key));
      }
      return [];
    },
    mainLanguage() {
      return this.$t(`languages.${this.course.languages.split(',')[0]}`);
    },
    link() {
      return this.mode === 'dashboard' ? '/watch-course' : '/courses';
    },
    linkDetail() {
      return this.mode === 'dashboard'
        ? this.courseDetails.id
        : this.courseDetails.slug;
    },
    courseDetails() {
      if (this.course.course) {
        return this.course.course;
      }
      return this.course;
    },
    wishlist() {
      return this.$store.state.wishlist.list;
    },
    isOnWishlist() {
      return this.wishlist.includes(this.course);
    },
  },
  methods: {
    redirectToMyPayments() {
      this.$router.push('/my-payments');
    },
    async startVideo() {
      if (!this.course.previewAssetId || this.disabled) return;

      try {
        const { data } = await video.getPreview({ courseId: this.course.id });
        this.preview = data;
        this.isError = false;
        this.videoStatus = true;
      } catch (error) {
        this.isError = true;
      }
      if (!this.preview) return;

      try {
        this.$refs.video.currentTime = await this.course.previewStartTime;
        this.playPromise = await this.$refs.video.play();

        this.videoTimeout = setTimeout(() => {
          this.pauseVideo();
        }, 30000);
      } catch (err) {
        console.log(err);
      }
    },
    pauseVideo() {
      if (!this.course.previewAssetId || this.disabled) return;

      setTimeout(() => {
        this.videoStatus = false;
        this.$refs.video.pause();
      }, 500);
      clearInterval(this.videoTimeout);
    },
    addToCart() {
      this.$store.dispatch('cart/addToCart', this.course);
    },
    addToWishlist() {
      if (this.isOnWishlist) {
        this.$store.dispatch('wishlist/deleteWishlistItem', this.course.id);
      } else {
        this.$store.dispatch('wishlist/addToWishlist', this.course);
      }
    },
  },
  async mounted() {
    const response = await request.get(getImageLink(this.course.imageId), {
      responseType: 'blob',
      headers: {
        Accept: 'image/avif,image/webp,image/*,*/*;q=0.8',
      },
    });
    this.coverUrl = URL.createObjectURL(response.data);
    setInterval(() => {
      this._computedWatchers.promotionDuration.run();
      this.$forceUpdate();
    }, 1000);
  },
};
</script>

<style lang="scss" scoped src="./CourseTile.scss"/>
