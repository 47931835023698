import request from '@/api/upload';

export default {
  createVideo() {
    return request.post('/videos');
  },
  processVideo(id) {
    return request.put(`/videos/${id}`);
  },
  getVideos() {
    return request.get('/videos');
  },
  deleteVideo(id) {
    return request.delete(`/videos/${id}`);
  },
  getAllVideoSources({ courseID, lessonID }) {
    return request.get(`/assets/${courseID}/${lessonID}/all`);
  },
  getVideoURL({ courseID, lessonID, quality }) {
    return request.get(`/assets/${courseID}/${lessonID}/${quality}p`);
  },
  getSubtitles(id) {
    return request.get(`/videos/${id}/subtitles`);
  },
  addSubtitles(id, locale, payload) {
    return request.put(`/videos/${id}/subtitles/${locale}`, payload);
  },
  deleteSubtitles(id, locale) {
    return request.delete(`/videos/${id}/subtitles/${locale}`);
  },
  getVideoSubtitles({ courseId, lessonId }) {
    return request.get(`/assets/${courseId}/${lessonId}/subtitles`);
  },
  getLocaleVideo({ courseId, lessonId, locale }) {
    return request.get(`/assets/${courseId}/${lessonId}/subtitles/${locale}`);
  },
  getPreview({ courseId }) {
    return request.get(`/assets/${courseId}/preview`);
  },
};
