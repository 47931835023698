export function milisecondsToTime(miliseconds) {
  const ms = miliseconds % 1000;
  miliseconds = (miliseconds - ms) / 1000;
  const secs = miliseconds % 60;
  miliseconds = (miliseconds - secs) / 60;
  const mins = miliseconds % 60;
  const hrs = (miliseconds - mins) / 60;

  return `${hrs}h ${mins}mins`;
}

export function secondsToTime(time, renderSeconds = false) {
  const seconds = time % 60;
  time = (time - seconds) / 60;
  const mins = time % 60;
  const hrs = (time - mins) / 60;

  if (!renderSeconds) {
    return `${hrs}h ${mins}mins`;
  }
  return `${hrs}h ${mins}mins ${seconds}secs`;
}

export function convertTimeToSeconds(time) {
  const [hours, minutes, seconds] = time.split(':');
  return (hours || 0) * 3600 + (minutes || 0) * 60 + (seconds || 0) * 1;
}

export function convertSecondsToTime(time, { miliseconds = false } = {}) {
  if (miliseconds) time /= 1000;
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = (time % 3600) % 60;
  const timeWithLeadingZero = [hours, minutes, seconds].map((piece) => (piece < 10 ? `0${piece}` : piece),
  );
  return timeWithLeadingZero.join(':');
}

export function setSubtitlesDuration(from, to) {
  const fromInSeconds = convertTimeToSeconds(from);
  const toInSeconds = convertTimeToSeconds(to);
  const duration = toInSeconds - fromInSeconds;
  if (duration <= 0) {
    return false;
  }
  return convertSecondsToTime(duration);
}
