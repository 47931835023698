<template>
  <div>
    <section class="section-ad__wrapper">
      <div class="container section-ad__container">
        <img
          src="/assets/images/left.png"
          :alt="$t('user')"
          class="section-ad__images section-ad__images--left"
        />
        <div class="section-ad__content">
          <base-sub-heading
            class="section-ad__header-sub-heading"
            align="center"
          >
            <span v-html="subTitle" />
          </base-sub-heading>
          <base-heading
            class="section-ad__header-heading"
            align="center"
            bold="bold"
          >
            <span v-html="title" />
          </base-heading>
          <p class="section-ad__text">
            {{ text }}
          </p>
          <base-button
            class="section-ad__button"
            to="/auth"
          >
            {{ buttonText }}
          </base-button>
        </div>
        <img
          src="/assets/images/right.png"
          :alt="$t('user')"
          class="section-ad__images section-ad__images--right"
        />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'SectionAd',
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped src="./SectionAd.scss" />
