<template>
  <base-card>
    <validation-observer ref="form">
      <h2 class="change-password__heading">{{ changePassword.heading }}</h2>
      <form @submit.prevent="handleSubmit">
        <base-input
          v-model="oldPassword"
          :label="changePassword.oldPassword.label"
          rules="required|min:8"
          :name="changePassword.oldPassword.name"
          type="password"
          icon="eye"
        />
        <base-input
          v-model="newPassword"
          :label="changePassword.newPassword.label"
          rules="required|min:8"
          :name="changePassword.newPassword.name"
          type="password"
          icon="eye"
        />
        <base-input
          v-model="newPasswordConfirmation"
          :label="changePassword.newPasswordConfirmation.label"
          :rules="'required|min:8|confirmed:' + changePassword.newPassword.name"
          :name="changePassword.newPasswordConfirmation.name"
          type="password"
          icon="eye"
        />
        <base-button
          class="change-password__button"
          nativeType="submit"
          :is-loading="isLoading"
        >
          {{ changePassword.button }}
        </base-button>
      </form>
    </validation-observer>
  </base-card>
</template>

<script>
import auth from '@/api/services/auth';

export default {
  name: 'ChangePassword',
  data: () => ({
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
    isLoading: false,
  }),
  methods: {
    async handleSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;

      const old = this.oldPassword;
      const newPass = this.newPassword;
      const newPassRepeat = this.newPasswordConfirmation;
      let status = '';
      this.isLoading = true;

      try {
        const response = await auth.setUserPassword({
          oldPassword: old,
          newPassword: newPass,
          newPasswordConfirmation: newPassRepeat,
        });
        status = response.status === 200 ? 'success' : 'error';
      } catch {
        status = 'error';
      } finally {
        this.isLoading = false;

        this.$store.dispatch('notification/spawn', {
          type: status,
          title: this.$t(`accountSettings.changePassword.${status}.title`),
          text: this.$t(`accountSettings.changePassword.${status}.text`),
        }, { root: true });
      }
    },
  },
  props: {
    changePassword: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped src="./ChangePassword.scss" />
