<template>
  <div class="base-table">
    <table class="base-table__inner">
      <thead class="base-table__header">
        <slot name="columns">
          <th
            class="base-table__header-column"
            v-for="(item, index) in columns"
            :key="index"
          >
            {{ item.label }}
          </th>
        </slot>
      </thead>

      <tbody class="base-table__body" v-if="!isEmpty">
        <template v-for="(item, index) in data">
          <tr class="base-table__body-row" :key="item.label">
            <slot :row="item" :index="index">

            </slot>
          </tr>
        </template>
      </tbody>
    </table>

    <p class="base-table__no-results" v-if="isEmpty">
      {{ $t('no data') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'BaseTable',
  props: {
    data: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    columns: {
      type: Array,
      required: false,
    },
  },
  computed: {
    isEmpty() {
      return (this.data && !this.data.length > 0);
    },
  },
};
</script>

<style lang="scss" src="./BaseTable.scss" />
