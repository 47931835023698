<template>
  <section class="header-carousel">
    <div class="container--big container">
      <!--      <hooper-->
      <!--        :settings="hooperSettings"-->
      <!--        class="header-carousel__hooper"-->
      <!--        ref="carousel"-->
      <!--        @slide="changeSlide"-->
      <!--      >-->
      <!--        <slide >-->
      <!--    -->
      <!--        </slide>-->
      <!--      </hooper>-->
      <header-slider/>
      <div class="header-carousel__controls">
        <button
          class="header-carousel__control"
          :class="currentSlide === 0 ? 'header-carousel__control--active' : ''"
          @click="changeSlide({ currentSlide: 0 })"
        />
        <button
          class="header-carousel__control"
          :class="currentSlide === 1 ? 'header-carousel__control--active' : ''"
          @click="changeSlide({ currentSlide: 1 })"
        />
        <button
          class="header-carousel__control"
          :class="currentSlide === 2 ? 'header-carousel__control--active' : ''"
          @click="changeSlide({ currentSlide: 2 })"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';

export default {
  name: 'HeaderCarousel',
  data: () => ({
    currentSlide: 0,
    hooperSettings: {
      vertical: false,
      transition: 1000,
      playSpeed: 7000,
      autoPlay: true,
      wheelControl: false,
      infiniteScroll: true,
      breakpoints: {
        1024: {
          vertical: true,
        },
      },
    },
  }),
  computed: {
    slides() {
      return this.$t('slides').map((slide, index) => ({
        ...slide,
        banner: this.$t('slidesBanner')[index],
        link: {
          href: '#',
          text: this.$t('learnMore'),
        },
        image: {
          src: '/assets/images/banner.png',
          alt: slide.image,
        },
      }));
    },
  },
  methods: {
    changeSlide({ currentSlide }) {
      this.currentSlide = currentSlide;
      if (currentSlide < 0) {
        this.currentSlide = currentSlide + 3;
      } else if (currentSlide > 3 - 1) {
        this.currentSlide = currentSlide - 3;
      } else {
        this.currentSlide = currentSlide;
      }
    },
  },
  watch: {
    currentSlide() {
      this.$refs.carousel.slideTo(this.currentSlide);
    },
  },
  mounted() {
    this.$refs.carousel.update();
  },
};
</script>
<style lang="scss" scoped src="./HeaderCarousel.scss"/>
