var render = function render(){var _vm=this,_c=_vm._self._c;return _c('validation-provider',{ref:"provider",staticClass:"base-select",attrs:{"tag":"div","rules":_vm.rules},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.close()},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;$event.preventDefault();return _vm.handleKeyDown()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;$event.preventDefault();return _vm.handleKeyDown()}]},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [(_vm.label)?_c('label',{staticClass:"base-select__label",attrs:{"for":_vm.selectID}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('input',{staticClass:"base-select__hidden-input",attrs:{"disabled":_vm.disabled,"name":_vm.name,"type":"search","autocomplete":_vm.autocomplete},domProps:{"value":_vm.value}}),(_vm.mode === 'search')?_c('div',{staticClass:"base-select__inner-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"base-select__inner base-select__inner--input",class:[
        _vm.isActive ? 'base-select__inner--active' : '',
        _vm.placeholderAsText ? 'base-select__inner--selected-placeholder' : '',
        _vm.thin ? 'base-select__inner--thin' : ''
      ],attrs:{"disabled":_vm.disabled,"id":_vm.selectID,"placeholder":_vm.placeholder,"type":"search","autocomplete":_vm.autocomplete},domProps:{"value":(_vm.search)},on:{"input":[function($event){if($event.target.composing)return;_vm.search=$event.target.value},function($event){return _vm.makeSearch($event)}],"blur":function($event){return _vm.handleBlur()},"focus":function($event){_vm.isActive = true},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.close()},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;$event.preventDefault();return _vm.handleKeyDown()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;$event.preventDefault();return _vm.handleKeyDown()}]}}),(_vm.hasHeaderSlot)?[_vm._t("header")]:_vm._e(),_c('svg',{staticClass:"base-select__arrow",style:({ transform: _vm.transform }),attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"11.572","height":"6.894","viewBox":"0 0 11.572 6.894"}},[_c('path',{attrs:{"id":"angle-right","d":"M15.447,11.76,10.767,7.081A1.1,1.1,0,1,0,9.2,8.637l3.907,3.907L9.2,16.45a1.1,1.1,0,0,0,1.567,1.556l4.679-4.679a1.1,1.1,0,0,0,0-1.567Z","transform":"translate(-6.754 15.773) rotate(-90)","fill":"#132b50"}})])],2):_c('button',{staticClass:"base-select__inner",class:[
      _vm.isActive ? 'base-select__inner--active' : '',
      _vm.value ? 'base-select__inner--selected' : '',
      _vm.mode === 'picker' ? 'base-select__inner--picker' : '',
      errors[0] ? 'base-select__inner--error' : '',
      _vm.thin ? 'base-select__inner--thin' : ''
    ],attrs:{"disabled":_vm.disabled,"type":"button","aria-controls":"base-select__content","aria-label":_vm.helpfulText,"title":_vm.helpfulText,"aria-expanded":_vm.isActive ? 'true' : 'false',"aria-haspopup":"listbox","id":_vm.selectID,"value":_vm.value},on:{"click":function($event){return _vm.handleSelectOpen()}}},[(_vm.hasHeaderSlot)?[_vm._t("header")]:[_vm._v(" "+_vm._s(_vm.value ? _vm.value.label : _vm.placeholder)+" ")],_c('svg',{staticClass:"base-select__arrow",style:({ transform: _vm.transform }),attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"11.572","height":"6.894","viewBox":"0 0 11.572 6.894"}},[_c('path',{attrs:{"id":"angle-right","d":"M15.447,11.76,10.767,7.081A1.1,1.1,0,1,0,9.2,8.637l3.907,3.907L9.2,16.45a1.1,1.1,0,0,0,1.567,1.556l4.679-4.679a1.1,1.1,0,0,0,0-1.567Z","transform":"translate(-6.754 15.773) rotate(-90)","fill":"#132b50"}})])],2),_c('transition',{attrs:{"name":"dropdown-fade"}},[(_vm.isActive)?_c('ul',{staticClass:"base-select__content",attrs:{"aria-label":"Kontent listy","role":"listbox","tabindex":"-1"}},[_vm._t("items"),(_vm.isEmpty)?_c('li',{staticClass:"base-select__no-results"},[_vm._v(" "+_vm._s(_vm.$t("no results"))+" ")]):_vm._e()],2):_vm._e()]),_c('transition',{attrs:{"name":"page-fade"},on:{"enter":function($event){return _vm.$emit('error', _vm.name)},"leave":function($event){return _vm.$emit('clear-error', _vm.name)}}},[(errors[0])?_c('span',{staticClass:"base-select__error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }