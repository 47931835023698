<template>
  <div class="blog-share">
    <p class="blog-share__label">
      {{ $t('blog.share') }}:
    </p>

    <ul class="blog-share__list">
      <li
        class="blog-share__list-item"
        v-for="socialItem in socials"
        :key="socialItem.name"
      >
        <a
          class="blog-share__list-link"
          :href="socialItem.link"
          :aria-label="socialItem.name"
          :title="socialItem.name"
          target="_blank"
        >
          <img
            class="blog-share__list-icon"
            alt=""
            :src="`/assets/blog/${socialItem.name}.svg`"
          />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'BlogShare',
  props: {
    title: {
      type: [String, Number],
      required: false,
      default: () => (''),
    },
  },
  computed: {
    socials() {
      return [
        {
          name: 'Facebook',
          link: `https://www.facebook.com/sharer/sharer.php?u=${this.pageURL}&title=${this.title}`,
        },
        {
          name: 'Twitter',
          link: `https://twitter.com/intent/tweet?url=${this.title}`,
        },
        {
          name: 'Linkedin',
          link: `https://www.linkedin.com/sharing/share-offsite/?url=${this.pageURL}`,
        },
      ];
    },
    pageURL() {
      return window.location.href;
    },
  },
};
</script>

<style lang="scss" src="./BlogShare.scss" />
