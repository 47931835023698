<template>
  <div class="country-label">
    <img
      :src="`/assets/flags/${countryKey}.svg`"
      :alt="$t(`languages.${countryKey}`)"
      class="country-label__flag"
    />
    <span class="country-label__text">
      <slot>
        {{ $t(`languages.${countryKey}`) }}
      </slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'CountryLabel',
  props: {
    countryKey: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped src="./CountryLabel.scss" />
