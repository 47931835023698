<template>
  <section class="author-courses">
    <base-heading bold="bold" class="dashboard-heading">
      {{ $t("myCourses.author.heading") }}
    </base-heading>

    <base-table
      :columns="columns"
      :data="courses"
      class="author-courses__table"
    >
      <template slot-scope="{ row, index }">
        <td>{{ index + 1 }}.</td>
        <td>
          <course-thumbnail
            :id="index"
            v-bind="row"
            :additionalDetails="`${$t('level')} ${row.difficulty}`"
          />
        </td>
        <td>
          {{ row.category }}
        </td>
        <td>
          {{ row.soldCount }}
        </td>
        <td>
          {{ row.price }}
          <!-- {{ row.price }} {{ row.author.currency }} -->
        </td>
        <td class="author-courses__commission">
          <div class="author-courses__profit">
            <img src="/assets/icons/arrow-up-right.svg" :alt="$t('arrow')" />
            {{ getCommision(row) }}
            <!-- {{ getCommision(row) }} {{ row.author.currency }} -->
          </div>
        </td>
        <td>
          {{
            row.status === "published"
              ? $t("myCourses.author.table.published")
              : $t("myCourses.author.table.draft")
          }}
        </td>
        <td class="author-courses__table-actions">
          <router-link
            :to="`/manage-course/${row.id}`"
            class="author-courses__table-button author-courses__table-button--manage"
            :title="$t('myCourses.author.table.settings')"
          >
            <img
              src="/assets/icons/settings.svg"
              :alt="$t('myCourses.author.table.settings')"
            />
          </router-link>

          <button
            type="button"
            class="author-courses__table-button author-courses__table-button--delete"
            :title="$t('myCourses.author.table.delete')"
            @click="handleShowModal(row)"
          >
            <svg-trash fill="#fff" />
          </button>
        </td>
      </template>
    </base-table>

    <base-button
      type="contrasting"
      class="author-courses__button"
      to="/add-course"
    >
      {{ $t("myCourses.author.addNew") }}
    </base-button>

    <base-modal
      :value="showModal"
      class="image-modal"
      @input="() => (this.showModal = false)"
      @accept="handleDelete"
      :title="$t('myCourses.author.delete.heading')"
    >
      <div slot="content">
        <p>
          {{ $t("myCourses.author.delete.text") }}
        </p>
        <course-thumbnail
          v-if="deletedCourse"
          :id="deletedCourse.id"
          :name="deletedCourse.name"
          :additionalDetails="`${$t('level')} ${deletedCourse.difficulty}`"
          class="author-courses__deleted-course"
        />
      </div>
    </base-modal>
  </section>
</template>

<script>
import course from '@/api/services/course';

export default {
  name: 'AuthorCourses',
  data: () => ({
    showModal: false,
    deletedCourse: null,
  }),
  props: {
    courses: {
      type: Array,
      required: true,
    },
  },
  computed: {
    columns() {
      const keys = [
        'no',
        'name',
        'category',
        'sold',
        'price',
        'commission',
        'published',
        'actions',
      ];
      return this.$t('myCourses.author.table.columns').map((column, index) => ({
        key: keys[index],
        label: column,
      }));
    },
    hasCourses() {
      return this.courses.length > 0;
    },
  },
  methods: {
    getCommision(courseObject) {
      return Number(0.3 * courseObject.soldCount * courseObject.price).toFixed(2);
    },
    async handleDelete() {
      this.showModal = false;
      const result = await course.deleteCourse(this.deletedCourse.id);

      if (result.status === 200) {
        this.$store.dispatch(
          'notification/spawn',
          {
            type: 'success',
            title: this.$t('deleteCourse.success'),
            text: this.$t('deleteCourse.text'),
          },
          { root: true },
        );
      }
    },
    handleShowModal(deletedCourse) {
      this.showModal = true;
      this.deletedCourse = deletedCourse;
    },
  },
};
</script>
<style lang="scss" scoped src="./AuthorCourses.scss" />
