<template>
  <li class="activity-notification">
    <div class="activity-notification__left">
      <div
        class="activity-notification__icon-wrapper"
        :class="`activity-notification__icon-wrapper--${notification.kind}`"
      >
        <img :src="icon" alt="" class="activity-notification__icon" />
      </div>
      <p class="activity-notification__content">
        <strong v-if="notification.kind === 'new_order'">{{ notification.from }}</strong>
        {{ $t(`notification.${notification.kind}`) }}
      </p>
    </div>
    <p class="activity-notification__time">{{ timeAgo }}</p>
  </li>
</template>

<script>
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { mapState } from 'vuex';
import 'dayjs/locale/pl';
import 'dayjs/locale/es';
import 'dayjs/locale/ru';
import 'dayjs/locale/it';

export default {
  name: 'ActivityNotification',
  data: () => ({}),
  methods: {},
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      language: (state) => state.language.currentLanguage,
    }),
    timeAgo() {
      dayjs.extend(relativeTime);
      dayjs.locale(this.language.key);
      return dayjs(this.notification.sent).fromNow();
    },
    icon() {
      switch (this.notification.kind) {
        case 'new_order':
          return '/assets/icons/cartAdd.svg';
        case 'promotion_request_accepted':
          return '/assets/icons/confetti.svg';
        case 'promotion_request_rejected':
          return '/assets/icons/times.svg';
        default:
          return '/assets/icons/bell.svg';
      }
    },
  },
};
</script>
<style lang="scss" scoped src="./ActivityNotification.scss" />
