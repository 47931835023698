import { localize } from 'vee-validate/dist/vee-validate.full';

export function interpretLocale(payload, languagesList) {
  const locale = languagesList.find((language) => payload.includes(language.key));
  return locale || languagesList.find((language) => language.key === 'en');
}

export async function loadLocale(code) {
  const locale = await require(`vee-validate/dist/locale/${code === 'us' ? 'en' : code}.json`);
  return localize(code, locale);
}
