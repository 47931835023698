<template>
  <base-card class="author-activity">
    <template name="header-left">
      <h3 class="author-activity__heading">
        {{ $t("dashboard.author.lastActivity") }}
      </h3>
    </template>
    <div v-if="notifications.length > 0">
      <ul class="author-activity__notifications">
        <activity-notification
          v-for="notification in formattedNotifications"
          :key="notification.id"
          :notification="notification"
        />
      </ul>
      <!-- <base-button
        type="text"
        to="/activities"
        class="author-activity__more-activities"
      >
        {{ $t("dashboard.author.moreActivities") }}
      </base-button> -->
    </div>
    <p class="author-activity__no-activities" v-else>
      {{ $t("dashboard.author.noActivities") }}
    </p>
  </base-card>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'AuthorActivity',
  props: {
    notifications: {
      type: Array,
      required: true,
    },
  },
  computed: {
    formattedNotifications() {
      return this.notifications
        .map((notification) => ({
          ...notification,
          timestamp: dayjs(notification.sent).unix(),
        }))
        .sort((b, a) => a.timestamp - b.timestamp);
    },
  },
};
</script>
<style lang="scss" scoped src="./AuthorActivity.scss" />
