import * as Sentry from '@sentry/vue';
import axios from 'axios';
import store from '@/store/index';
import appConfig from '@/config';

// Base configuration of Axios instance
const request = axios.create({
  baseURL: appConfig.apiServerURL,
  validateStatus: (status) => {
    return status >= 200 && status < 300;
  },
});

// Set HTTP header with token
request.interceptors.request.use(
  (config) => {
    store.state.auth.token ? config.headers.Authorization = `Bearer ${store.state.auth.token}` : false;
    return config;
  }, (err) => { console.log(err); },
);

// Global request interceptors for ajax requests - Sentry purpose
request.interceptors.response.use(
  (response) => {
    if (response.status >= 400) {
      const message = response.data.message ? response.data.message : response.data.status;
      if (store.state.auth.user) Sentry.setUser({ email: store.state.auth.user.email });

      Sentry.captureMessage(`AJAX ${message}`, {
        level: 'error',
      });
      return Promise.reject(message);
    }

    return response;
  }, (err) => {
    if (store.state.auth.user) Sentry.setUser({ email: store.state.auth.user.email });
    Sentry.captureMessage(`AJAX ${err.response.data ? JSON.stringify(err.response.data) : err}`, {
      level: 'error',
    });
    return Promise.reject(err);
  },
);

export default request;
