<template>
  <li class="cart-course">
    <router-link
      :to="`/courses/${course.slug}`"
      :aria-label="course.name"
      :title="course.name"
      class="cart-course__image-link"
      @click.native="$emit('click')"
    >
      <img class="cart-course__image" alt :src="imageLink"/>
    </router-link>
    <div class="cart-course__content">
      <div class="cart-course__info">
        <p v-if="mode === 'checkout'">1</p>
        <router-link
          :to="`/courses/${course.slug}`"
          :aria-label="course.name"
          :title="course.name"
          class="cart-course__link"
          @click.native="$emit('click')"
        >
          {{ course.name }}
        </router-link>
        <base-price
          :price="course.price"
          class="cart-course__price"
          originalPrice
          v-if="promotionDuration!=null"
          :promoPrice="course.price*(100-course.promotionValue)/100"
        />
        <base-price
          :price="course.price"
          class="cart-course__price"
          originalPrice
          v-if="promotionDuration==null"
        />
      </div>
      <div class="cart-course__actions">
        <base-add-cart
          @click="addToCart()"
          v-if="mode === 'wishlist'"
          class="cart-course__actions-add"
        />

        <button
          class="cart-course__delete"
          :title="$t('cart.delete')"
          :aria-label="$t('cart.delete')"
          @click="deleteFromCart()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            class="cart-course__delete-icon"
          >
            <g
              id="Group_2220"
              data-name="Group 2220"
              transform="translate(-964.994 -2432.519)"
            >
              <circle
                id="Ellipse_33"
                data-name="Ellipse 33"
                cx="12.5"
                cy="12.5"
                r="12.5"
                transform="translate(964.994 2432.519)"
                fill="#f2f2f2"
              />
              <g
                id="Group_2221"
                data-name="Group 2221"
                transform="translate(971.907 2438.915)"
              >
                <g id="Group_46" data-name="Group 46">
                  <path
                    id="Path_33"
                    data-name="Path 33"
                    d="M194.734,648.843H190.3v8.509a2.329,2.329,0,0,0,2.329,2.329h4.21a2.329,2.329,0,0,0,2.329-2.329v-8.509Z"
                    transform="translate(-189.316 -646.873)"
                    fill="rgba(0,0,0,0)"
                    stroke="#b4b4b4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.3"
                  />
                  <line
                    id="Line_9"
                    data-name="Line 9"
                    y2="4.926"
                    transform="translate(3.941 4.926)"
                    fill="none"
                    stroke="#b4b4b4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.3"
                  />
                  <line
                    id="Line_10"
                    data-name="Line 10"
                    y2="4.926"
                    transform="translate(6.897 4.926)"
                    fill="none"
                    stroke="#b4b4b4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.3"
                  />
                  <line
                    id="Line_11"
                    data-name="Line 11"
                    x2="10.838"
                    transform="translate(0 1.97)"
                    fill="none"
                    stroke="#b4b4b4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.3"
                  />
                  <path
                    id="Path_34"
                    data-name="Path 34"
                    d="M197.765,648.275l-.579-.927a2.22,2.22,0,0,0-1.882-1.043h0a2.219,2.219,0,0,0-1.882,1.043l-.58.927"
                    transform="translate(-189.883 -646.305)"
                    fill="rgba(0,0,0,0)"
                    stroke="#b4b4b4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.3"
                  />
                </g>
              </g>
            </g>
          </svg>
        </button>
      </div>
    </div>
  </li>
</template>

<script>
import { getImageLink } from '@/helpers';

export default {
  name: 'CartCourseTile',
  props: {
    course: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      required: false,
      default: 'cart',
    },
  },
  computed: {
    imageLink() {
      return getImageLink(this.course.imageId);
    },
    promotionDuration() {
      if (this.course.promotionEnd == null || this.course.promotionValue == null || this.course.promotionValue === 0) return null;
      const local = new Date();
      const now = new Date(local.getTime() + local.getTimezoneOffset() * 60000);
      const promoEndDate = new Date(new Date(Date.parse(this.course.promotionEnd)).getTime() + local.getTimezoneOffset() * 60000);
      const dateDiff = (promoEndDate - now);
      if (dateDiff <= 0) return null;

      const diff = new Date((Date.parse(this.course.promotionEnd) - Date.now()));
      return `${diff.getHours() * diff.getDate()}h ${Math.floor(diff.getMinutes() - (diff.getHours() / 60))} min  ${Math.floor(diff.getSeconds() - (diff.getMinutes() / 60))} s`;
    },
  },
  methods: {
    deleteFromCart() {
      if (this.mode === 'cart' || this.mode === 'checkout') {
        this.$store.dispatch('cart/deleteCartItem', this.course.id);
      } else {
        this.$store.dispatch('wishlist/deleteWishlistItem', this.course.id);
      }
    },
    addToCart() {
      this.$store.dispatch('cart/addToCart', this.course);
      this.$store.dispatch('wishlist/deleteWishlistItem', this.course.id);
    },
  },
};
</script>

<style lang="scss" src="./CartCourseTile.scss"/>
