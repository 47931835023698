<template>
  <ul class="categories-selection">
    <li
      class="categories-selection__item"
      v-for="category in sortedCategories"
      :key="category.value"
    >
      <base-checkbox
        type="square"
        :disabled="disabled"
        @input="handleCheckbox($event, category)"
        :value="isChecked(category.value)"
      >
        {{ category.label }}
      </base-checkbox>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'CategoriesSelection',
  props: {
    categories: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
    course: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    categoryName: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    sortedCategories() {
      const localCategories = this.categories;

      return localCategories.sort((a, b) => {
        return a.label > b.label ? 1 : -1;
      });
    },
  },
  methods: {
    isChecked(category) {
      return this.value.map((element) => element.value).includes(category);
    },
    handleCheckbox(checked, category) {
      if (checked) {
        this.$emit('input', [...this.value, category]);
      } else {
        this.$emit(
          'input',
          this.value.filter((element) => element.value !== category.value),
        );
      }
    },
  },
};
</script>

<style lang="scss" src="./CategoriesSelection.scss" />
