<template>
  <div class="information-banner">
      <div class="information-banner__icon">
        <img
          :src="`/assets/icons/notification-new-${type}.svg`"
          alt=""
          class="information-banner__icon-inner"
        />
      </div>
    <div class="information-banner__row">
      <p class="information-banner__text"><slot /></p>
    </div>
    <button
      @click="$emit('close')"
      class="information-banner__close"
      type="button"
    >
      <img src="/assets/icons/close-new.svg" alt="">
    </button>
  </div>
</template>

<script>
export default {
  name: 'InformationBanner',
  props: {
    type: {
      type: String,
      required: false,
      default: 'info',
    },
  },
};
</script>
<style lang="scss" scoped src="./InformationBanner.scss" />
