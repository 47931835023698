import request from '@/api/';

export default {
  addCourse(payload) {
    return request.post('creator/courses', {
      ...payload,
    });
  },
  updateCourse(payload, id) {
    return request.put(`creator/courses/${id}`, {
      ...payload,
    });
  },
  getCourse(id) {
    return request.get(`creator/courses/${id}`);
  },
  deleteCourse(id) {
    return request.delete(`creator/courses/${id}`);
  },
  addLesson(payload, id) {
    return request.post(`creator/courses/${id}/lessons`, {
      ...payload,
    });
  },
  editLesson(payload, courseId, lessonId) {
    return request.put(`creator/courses/${courseId}/lessons/${lessonId}`, payload);
  },
  deleteLesson(courseId, lessonId) {
    return request.delete(`creator/courses/${courseId}/lessons/${lessonId}`);
  },
  getCourses(payload) {
    return request.get('courses', {
      params: {
        ...payload,
      },
    });
  },
  getSingleCourse(payload) {
    return request.get(`courses/${payload}`);
  },
  getCreatorCourses() {
    return request.get('creator/courses');
  },
  getNotOwnedCourses() {
    return request.get('courses', {
      params: {
        exclude_bought: true,
      },
    });
  },
  getOwnedCourses() {
    return request.get('courses/orders');
  },
  getExtraCourses() {
    return request.get('courses/extra');
  },
  getOwnedCourse(payload) {
    return request.get(`courses/orders/${payload}`);
  },
  setAsWatchedLesson({ courseId, lessonId }) {
    return request.post(`courses/${courseId}/lessons/${lessonId}`);
  },
  setLike(courseId, hasLike) {
    return request.post(`courses/${courseId}/react/${hasLike}`);
  },
  getCertificate(courseId, isPortalCertificate) {
    return request.get(`/courses/orders/${courseId}/certificate${isPortalCertificate ? '?is_portal_certificate=true' : ''}`, {
      responseType: 'arraybuffer',
    });
  },
  getCourseOrder(courseId) {
    return request.get(`/courses/orders/${courseId}/`);
  },
  getInstuctorSales(payload) {
    return request.get('creator/orders', {
      params: {
        ...payload,
      },
    });
  },
  getAuthorCourses(payload) {
    return request.get('/courses', {
      params: {
        author_id: payload,
      },
    });
  },
};
