import { render, staticRenderFns } from "./BaseTooltip.vue?vue&type=template&id=6dc8610d&scoped=true&"
import script from "./BaseTooltip.vue?vue&type=script&lang=js&"
export * from "./BaseTooltip.vue?vue&type=script&lang=js&"
import style0 from "./BaseTooltip.scss?vue&type=style&index=0&id=6dc8610d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.1_cache-loader@4.1.0_css-loader@3.6.0_webpack@4.46.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dc8610d",
  null
  
)

export default component.exports