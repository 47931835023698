<template>
  <div class="blog-author">
    <img
      class="blog-author__image"
      alt="Edunails team"
      src="/assets/images/small-logo.svg"
    />

    <span class="blog-author__text">
      {{ $t('blog.author') }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'BlogAuthor',
};
</script>

<style lang="scss" src="./BlogAuthor.scss" />
