<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.24"
    height="15.943"
    viewBox="0 0 25.24 15.943"
  >
    <g transform="translate(-4.791 -11.234)">
      <g transform="translate(5.791 12.234)">
        <path
          d="M557.228,591.721v-4.154a2.816,2.816,0,0,0-2.817-2.817H543.777a2.817,2.817,0,0,0-2.817,2.817v8.309a2.817,2.817,0,0,0,2.817,2.817h10.634a2.816,2.816,0,0,0,2.817-2.817Z"
          transform="translate(-540.96 -584.75)"
          fill="rgba(0,0,0,0)"
          stroke="#858585"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          class="dashboard-navigation__icon--stroke"
        />
        <path
          d="M557.629,586.859l-5.118,1.706v4.648l5.118,1.707a1.408,1.408,0,0,0,1.854-1.337V588.2A1.409,1.409,0,0,0,557.629,586.859Z"
          transform="translate(-536.242 -583.918)"
          fill="rgba(0,0,0,0)"
          stroke="#858585"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          class="dashboard-navigation__icon--stroke"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SvgCamera',
};
</script>
