<template>
  <div class="testimonial">
    <div class="testimonial__row">
      <img :src="`/assets/images/testimonials/user-avatar-${index}.jpg`" :alt="name" class="testimonial__image" />
      <div>
        <p class="testimonial__name">{{ name }}</p>
        <p class="testimonial__period">{{ period }}</p>
      </div>
    </div>
    <p class="testimonial__content">
      {{ content }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'Testimonial',
  props: {
    name: {
      type: String,
      required: true,
    },
    period: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped src="./Testimonial.scss" />
