<template>
  <base-card class="user-courses-progress">
    <template name="header-left">
      <h3 class="user-courses-progress__heading">
        {{ $t("dashboard.user.yourProgress") }}
      </h3>
    </template>
    <div v-if="courses.length > 0">
      <ul>
        <li
          class="user-courses-progress__course"
          v-for="course in courses"
          :key="course.id"
        >
          <course-thumbnail
            v-bind="course.course"
            :additionalDetails="`${$t('dashboard.user.last')}: ${course.last}`"
            class="user-courses-progress__course-thumbnail user-courses-progress__course-thumbnail--mobile"
          />
          <div class="user-courses-progress__course--inner">
            <course-thumbnail
              v-bind="course.course"
              :additionalDetails="`${$t('dashboard.user.last')}: ${course.last}`"
              class="user-courses-progress__course-thumbnail"
            />
            <progress-bar
              :percent="course.completed"
              class="user-courses-progress__progress-bar"
            />
            <play-button
              class="user-courses-progress__play"
              color="pink"
              border
              @click="$router.push(`/courses/${course.id}`)"
            />
          </div>
        </li>
      </ul>
      <base-button
        type="text"
        to="/courses"
        class="user-courses-progress__all-courses"
      >
        {{ $t("dashboard.user.allCourses") }}
      </base-button>
    </div>
    <div v-else class="user-courses-progress__no-courses">
      <p class="user-courses-progress__no-courses-text">
        {{ $t("dashboard.user.noCourses") }}
      </p>
      <base-button type="contrasting" to="/courses">
        {{ $t("dashboard.user.discover") }}
      </base-button>
    </div>
  </base-card>
</template>

<script>
export default {
  name: 'UserCoursesProgress',
  props: {
    courses: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped src="./UserCoursesProgress.scss" />
