<template>
  <p class="base-price">
    <template v-if="promoPrice!=null">
      <span class="base-price__old">{{ finalPrice }}</span>
      {{ finalPromoPrice }}
    </template>
    <template v-if="promoPrice==null">
      {{ finalPrice }}
    </template>
    <template v-if="originalPrice">
      <br/>
      <span
        class="base-price__original"
        :class="big ? 'base-price__original--big' : ''"
      >
        ({{ $t("originalPrice") }} {{ `${(price / 100).toFixed(2)} EUR)` }}
      </span>
    </template>
  </p>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'BasePrice',
  props: {
    price: {
      type: Number,
      required: true,
    },
    promoPrice: {
      type: Number,
      required: false,
    },
    originalPrice: {
      type: Boolean,
      required: false,
      default: false,
    },
    big: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    finalPrice() {
      if (!this.rates.rates[this.currentCurrency]) {
        return `${(this.price / 100).toFixed(2)} EUR`;
      }
      return `${(
        (this.price * this.rates.rates[this.currentCurrency])
        / 100
      ).toFixed(2)} ${this.currentCurrency}`;
    },
    finalPromoPrice() {
      if (!this.rates.rates[this.currentCurrency]) {
        return `${(this.price / 100).toFixed(2)} EUR`;
      }
      return `${(
        (this.promoPrice * this.rates.rates[this.currentCurrency])
        / 100
      ).toFixed(2)} ${this.currentCurrency}`;
    },
    ...mapState({
      currentCurrency: (state) => state.language.currentLanguage.currency,
      rates: (state) => (state.auth.currencies
        ? state.auth.currencies
        : JSON.parse(localStorage.getItem('currencies'))),
    }),
  },
};
</script>
<style lang="scss" scoped src="./BasePrice.scss"/>
