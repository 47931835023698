<template>
  <div class="main-notifications">
    <transition-group
      class="main-notifications__list"
      tag="ul"
      name="moving-fade-alerts"
    >
      <base-notification
        v-for="notification in notifications"
        :key="notification.id"
        :id="notification.id"
        :title="notification.title"
        :text="notification.text"
        :mode="notification.type"
      />
    </transition-group>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'MainNotifications',
  computed: {
    ...mapState({
      notifications: (state) => state.notification.list,
    }),
  },
};
</script>

<style lang="scss" src="./MainNotifications.scss" />
