<template>
  <base-card class="add-step-certificate">
    <h3 class="add-step-certificate__heading">{{ $t("certificate.add") }}</h3>
    <div class="add-step-certificate--desktop">
      <div class="add-step-certificate__content" ref="workspace">
        <transition name="fade" mode="out-in">
          <upload-thumbnail
            :image="null"
            :helpfulText="$t('certificate.add')"
            @upload="setCertficateImage($event)"
            class="add-step-certificate__content-upload"
            v-if="!course.certificateImageId"
            big
          />
          <div class="add-step-certificate__content-inner" v-else>
            <img
              class="add-step-certificate__content-img"
              alt
              :src="imageLink"
              @load="onImgLoad"
              ref="certificate"
            />

            <div
              class="add-step-certificate__content-item add-step-certificate__content-item--name"
              ref="name"
            >
              {{ name || $t("certificate.name") }}
            </div>

            <div
              class="add-step-certificate__content-item add-step-certificate__content-item--date"
              ref="date"
            >
              {{ date || $t("certificate.date") }}
            </div>
          </div>
        </transition>
      </div>
      <transition name="page-fade" mode="out-in">
        <information-banner
          v-if="helpBanner"
          @close="helpBanner = false"
          class="add-step-certificate__info-banner"
        >
          {{ $t("certificate.help") }}
        </information-banner>
      </transition>
      <base-button
        type="outline-dangerous"
        class="add-step-certificate__delete"
        v-if="course.certificateImageId"
        @click="deleteCertificate"
      >
        {{ $t("certificate.delete") }}
      </base-button>
    </div>
    <p class="add-step-certificate--mobile">
      Open on desktop to add certificate
    </p>
  </base-card>
</template>

<script>
import interact from 'interactjs';
import { getImageLink } from '@/helpers/index';
import resource from '@/api/services/resource';

const PADDING = 15;
export default {
  name: 'AddStepCertificate',
  data: () => ({
    position: {
      nameX: -100,
      nameY: 0,
      dateX: 100,
      dateY: 0,
      nameScale: 30,
      dateScale: 30,
    },
    helpBanner: true,
  }),
  props: {
    course: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
    date: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    imageLink() {
      return getImageLink(this.course.certificateImageId);
    },
    certificateDimensions() {
      if (!this.$refs.certificate) {
        return null;
      }
      const { offsetWidth, naturalWidth } = this.$refs.certificate;
      return { offsetWidth, naturalWidth };
    },
  },
  methods: {
    onImgLoad() {
      if (
        this.course.certificateNameBoxPositionX !== -100
        || this.course.certificateNameBoxPositionY
        || this.course.certificateDateBoxPositionX !== 100
        || this.course.certificateDateBoxPositionY
      ) {
        this.setDefaultPosition();
        this.position = {
          ...this.position,
          nameX: 0,
          dateX: 0,
        };
      } else {
        this.setDraggableElements();
      }
      const { name, date } = this.$refs;
      name.style.transform = `translate(${this.position.nameX}px, ${this.position.nameY}px)`;
      date.style.transform = `translate(${this.position.dateX}px, ${this.position.dateY}px)`;
    },
    setCertficateImage(image) {
      this.$emit('update-data', { certificateImageId: image });
    },
    calculateEndPosition(event, type) {
      const { workspace, certificate } = this.$refs;
      const { x, y } = workspace.getBoundingClientRect();
      const { offsetWidth, naturalWidth } = certificate;

      const {
        x: left,
        y: top,
        width,
        height,
      } = event.target.getBoundingClientRect();

      const imageRatio = naturalWidth / offsetWidth;
      const positionX = Math.round((left - x + width / 2) * imageRatio);
      const positionY = Math.round((top - y) * imageRatio) + PADDING;
      const scale = Math.round((height - PADDING * 2) * imageRatio);

      if (type === 'name') {
        this.$emit('update-data', {
          certificateNameBoxPositionX: positionX,
          certificateNameBoxPositionY: positionY,
          certificateNameBoxScale: scale,
        });
      }
      if (type === 'date') {
        this.$emit('update-data', {
          certificateDateBoxPositionX: positionX,
          certificateDateBoxPositionY: positionY,
          certificateDateBoxScale: scale,
        });
      }
    },
    setDraggableElements() {
      const that = this;
      const setResizableConfig = (type) => ({
        edges: {
          right: true,
          bottom: true,
        },
        listeners: {
          end(event) {
            that.calculateEndPosition(event, type);
          },
          move(event) {
            const { target } = event;
            // update the element's style
            target.style.fontSize = `${event.rect.height - PADDING * 2}px`;
            if (type === 'name') {
              that.position.nameScale = event.rect.height;
            }
            if (type === 'date') {
              that.position.dateScale = event.rect.height;
            }
          },
        },
        modifiers: [
          // keep the edges inside the parent
          interact.modifiers.restrictEdges({
            outer: 'parent',
          }),
          interact.modifiers.aspectRatio({ ratio: 'preserve' }),
          interact.modifiers.restrictSize({
            min: { height: 40 },
            max: { height: 150 },
          }),
        ],

        inertia: true,
      });

      const setDraggableConfig = (type) => ({
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: 'parent',
          }),
        ],
        listeners: {
          end(event) {
            that.calculateEndPosition(event, type);
          },
          move(event) {
            if (type === 'name') {
              that.position.nameX += event.dx;
              that.position.nameY += event.dy;
              event.target.style.transform = `translate(${that.position.nameX}px, ${that.position.nameY}px)`;
            }
            if (type === 'date') {
              that.position.dateX += event.dx;
              that.position.dateY += event.dy;
              event.target.style.transform = `translate(${that.position.dateX}px, ${that.position.dateY}px)`;
            }
          },
        },
      });

      // FullName object
      interact(this.$refs.name)
        .draggable(setDraggableConfig('name'))
        .resizable(setResizableConfig('name'));

      // Date object
      interact(this.$refs.date)
        .draggable(setDraggableConfig('date'))
        .resizable(setResizableConfig('date'));
    },
    setDefaultPosition() {
      const { name, date, certificate } = this.$refs;
      const { offsetWidth, naturalWidth } = certificate;
      const imageRatio = naturalWidth / offsetWidth;

      name.style.fontSize = `${this.course.certificateNameBoxScale
        / imageRatio}px`;
      date.style.fontSize = `${this.course.certificateDateBoxScale
        / imageRatio}px`;

      const { width: dateWidth } = date.getBoundingClientRect();
      const { width: nameWidth } = name.getBoundingClientRect();

      name.style.left = `${this.course.certificateNameBoxPositionX
        / imageRatio
        - nameWidth / 2}px`;
      name.style.top = `${(this.course.certificateNameBoxPositionY - PADDING)
        / imageRatio}px`;
      date.style.left = `${this.course.certificateDateBoxPositionX
        / imageRatio
        - dateWidth / 2}px`;
      date.style.top = `${(this.course.certificateDateBoxPositionY - PADDING)
        / imageRatio}px`;

      this.setDraggableElements();
    },
    async deleteCertificate() {
      try {
        await resource.deleteResource(this.course.certificateImageId);
      } catch (err) {
        console.error(err);
      } finally {
        this.$emit('update-data', {
          certificateNameBoxPositionX: -100,
          certificateNameBoxPositionY: 0,
          certificateDateBoxPositionX: 100,
          certificateDateBoxPositionY: 0,
          certificateNameBoxScale: 30,
          certificateDateBoxScale: 30,
          certificateImageId: '',
        });
      }
    },
  },
};
</script>

<style lang="scss" src="./AddStepCertificate.scss" />
