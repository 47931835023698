export default () => ({
  computed: {
    categoriesMethods() {
      return {
        name: 'methods',
        list: [
          {
            value: 'gel',
            label: this.$t('categories.methods.gel'),
          },
          {
            value: 'acrylic',
            label: this.$t('categories.methods.acrylic'),
          },
          {
            value: 'acylogel',
            label: this.$t('categories.methods.acrylogel'),
          },
          {
            value: 'manicure',
            label: this.$t('categories.methods.manicure'),
          },
          {
            value: 'beauty',
            label: 'Beauty',
          },
          {
            value: 'french_manicure',
            label: this.$t('categories.methods.french_manicure'),
          },
          {
            value: 'combined_manicure',
            label: this.$t('categories.methods.combined_manicure'),
          },
          {
            value: 'nipper_manicure',
            label: this.$t('categories.methods.nipper_manicure'),
          },
          {
            value: 'nipper_free_manicure',
            label: this.$t('categories.methods.nipper_free_manicure'),
          },
          {
            value: 'electricfile',
            label: this.$t('categories.methods.electricfile'),
          },
          {
            value: 'pedicure',
            label: this.$t('categories.methods.pedicure'),
          },
          {
            value: 'other',
            label: this.$t('categories.methods.other'),
          },
        ],
      };
    },
    categoriesShapes() {
      return {
        name: 'shapes',
        list: [
          {
            value: 'square',
            label: this.$t('categories.shapes.square'),
          },
          {
            value: 'almond',
            label: this.$t('categories.shapes.almond'),
          },
          {
            value: 'oval',
            label: this.$t('categories.shapes.oval'),
          },
          {
            value: 'butterfly',
            label: this.$t('categories.shapes.butterfly'),
          },
          {
            value: 'stitetto',
            label: this.$t('categories.shapes.stitetto'),
          },
          {
            value: 'coffin',
            label: this.$t('categories.shapes.coffin'),
          },
          {
            value: 'pipe',
            label: this.$t('categories.shapes.pipe'),
          },
          {
            value: 'russian_almond',
            label: this.$t('categories.shapes.russian_almond'),
          },
          {
            value: 'gothic_almond',
            label: this.$t('categories.shapes.gothic_almond'),
          },
          {
            value: 'gothic_pipe',
            label: this.$t('categories.shapes.gothic_pipe'),
          },
          {
            value: 'edge',
            label: this.$t('categories.shapes.edge'),
          },
          {
            value: 'bridge',
            label: this.$t('categories.shapes.bridge'),
          },
          {
            value: 'other',
            label: this.$t('categories.shapes.other'),
          },
        ],
      };
    },

    categoriesArt() {
      return {
        name: 'art',
        list: [
          {
            value: 'ombre',
            label: this.$t('categories.art.ombre'),
          },
          {
            value: 'stones',
            label: this.$t('categories.art.stones'),
          },
          {
            value: 'marble',
            label: this.$t('categories.art.marble'),
          },
          {
            value: 'ornaments',
            label: this.$t('categories.art.ornaments'),
          },
          {
            value: 'silk',
            label: this.$t('categories.art.silk'),
          },
          {
            value: 'zhostovo',
            label: this.$t('categories.art.zhostovo'),
          },
          {
            value: 'one_stroke',
            label: this.$t('categories.art.one_stroke'),
          },
          {
            value: 'thin_lines',
            label: this.$t('categories.art.thin_lines'),
          },
          {
            value: 'glitter',
            label: this.$t('categories.art.glitter'),
          },
          {
            value: 'jewellery',
            label: this.$t('categories.art.jewellery'),
          },
          {
            value: 'wedding',
            label: this.$t('categories.art.wedding'),
          },
          {
            value: 'iconic_arts',
            label: this.$t('categories.art.iconic_arts'),
          },
          {
            value: 'flowers',
            label: this.$t('categories.art.flowers'),
          },
          {
            value: 'butterfly',
            label: this.$t('categories.art.butterfly'),
          },
          {
            value: 'stylograph',
            label: this.$t('categories.art.stylograph'),
          },
          {
            value: 'airbrush',
            label: this.$t('categories.art.airbrush'),
          },
          {
            value: 'juicy_fruits',
            label: this.$t('categories.art.juicy_fruits'),
          },
          {
            value: 'animals',
            label: this.$t('categories.art.animals'),
          },
          {
            value: 'roses',
            label: this.$t('categories.art.roses'),
          },
          {
            value: 'animal_patterns',
            label: this.$t('categories.art.animal_patterns'),
          },
          {
            value: '3d',
            label: this.$t('categories.art.3d'),
          },
          {
            value: '2d',
            label: this.$t('categories.art.2d'),
          },
          {
            value: 'decorated_ring',
            label: this.$t('categories.art.decorated_ring'),
          },
          {
            value: 'watercolour',
            label: this.$t('categories.art.watercolour'),
          },
          {
            value: 'jungle',
            label: this.$t('categories.art.jungle'),
          },
          {
            value: 'mix_media',
            label: this.$t('categories.art.mix_media'),
          },
          {
            value: 'face_art',
            label: this.$t('categories.art.face_art'),
          },
          {
            value: 'other',
            label: this.$t('categories.art.other'),
          },
        ],
      };
    },
  },
});
