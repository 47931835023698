<template>
  <aside class="main-wishlist">
    <header class="main-wishlist__header">
      <h3 class="main-wishlist__header-title">
        {{ $t('wishlist.name') }}
      </h3>
      <base-close-icon
        @click="closeWishlist()"
      />
    </header>
    <div class="main-wishlist__content">
      <ul class="main-wishlist__list" v-if="wishlistItems.length > 0">
        <cart-course-tile
          v-for="course in wishlistItems"
          :key="course.id"
          :course="course"
          mode="wishlist"
          @click="closeWishlist()"
        />
      </ul>
      <p class="main-wishlist__list-empty" v-else>
        {{ $t('wishlist.empty') }}
      </p>
    </div>
    <footer class="main-wishlist__footer">
      <div class="main-wishlist__footer-row">
        <base-button
          @click="clearWishlist()"
          type="text"
        >
          {{ $t('wishlist.clear') }}
        </base-button>
        <base-button
          @click="moveToCart()"
        >
          {{ $t('wishlist.payment') }}
        </base-button>
      </div>
    </footer>
  </aside>
</template>

<script>
import { mapState } from 'vuex';
import { escKey } from '@/mixins/escKey';

export default {
  name: 'MainWishlist',
  mixins: [
    escKey(),
  ],
  computed: {
    ...mapState({
      wishlistItems: (state) => state.wishlist.list,
    }),
  },
  methods: {
    closeWishlist() {
      this.$store.dispatch('ui/setWishlist', false);
    },
    clearWishlist() {
      this.$store.dispatch('wishlist/clearWishlist');
    },
    moveToCart() {
      this.$store.dispatch('wishlist/moveToCart', this.wishlistItems);
    },
  },
};
</script>

<style lang="scss" scoped src="./MainWishlist.scss" />
