<template>
  <div class="main-preloader">
    <div class="main-preloader__inner">
      <loader />
      <p class="main-preloader__text">
        {{ loadingText }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'MainPreloader',
  computed: {
    ...mapState({
      loadingText: (state) => state.ui.preloaderText,
    }),
  },
};
</script>

<style lang="scss" src="./MainPreloader.scss" />
