<template>
  <section class="account-details">
    <account-details-form
      ref="form"
      v-bind="$t('accountCreation.form')"
      :userData="userData"
      @update-data="updateData($event)"
      @submit="handleSubmit()"
      :additionalSections="[userType === 'creator' ? 'PayPal' : '']"
      :submitButton="accountDetails.submit"
      :backButton="accountDetails.cancel"
      :is-loading="isLoading"
    >
      <template slot="PayPal">
        <base-input
          v-model="userData.payPalEmail"
          rules="required|email"
          :label="$t('become-author.paypal.label')"
          :name="$t('become-author.paypal.label')"
          @error="handleAddError"
          @clear-error="handleClearError"
        />
        <transition name="page-fade" mode="out-in">
          <information-banner
            v-if="payPalWarning"
            @close="payPalWarning = false"
          >
            {{ $t("become-author.paypal.info[0]") }}
            <base-button
              type="link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.paypal.com/businesswallet/money"
              to="https://www.paypal.com/businesswallet/money"
              nativeLink
              class="account-details__paypal-link"
            >
              {{ $t("become-author.paypal.info[1]") }}
            </base-button>
            {{ $t("become-author.paypal.info[2]") }}
          </information-banner>
        </transition>
      </template>
    </account-details-form>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'AccountDetails',
  data: () => ({
    userData: {
      avatarId: '',
      firstName: '',
      lastName: '',
      birthday: '',
      email: '',
      city: '',
      street: '',
      postalCode: '',
      country: '',
      companyName: '',
      companyTaxId: '',
      image: '',
      paymentMethod: '',
      isCompany: false,
      companyCity: '',
      companyStreet: '',
      companyPostalCode: '',
      companyCountry: '',
      payPalEmail: '',
    },
    isLoading: false,
    payPalWarning: true,
  }),
  props: {
    accountDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    profile() {
      return this.$t('accountCreation.form.profile');
    },
    ...mapGetters({
      userType: 'auth/userType',
    }),
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  created() {
    this.userData = { ...this.userData, ...this.user };
  },
  methods: {
    setAvatar(event) {
      this.userData.avatarId = event;
    },
    updateData(event) {
      this.userData = { ...this.userData, ...event };
    },
    handleAddError(event) {
      this.$refs.form.handleAddError(event);
    },
    handleClearError(event) {
      this.$refs.form.handleClearError(event);
    },
    async handleSubmit() {
      this.isLoading = true;
      await this.$store.dispatch('auth/updateUserData', this.userData);
      this.isLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped src="./AccountDetails.scss" />
