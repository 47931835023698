<template>
  <section class="featured-courses">
    <div class="container">
      <header class="featured-courses__header">
        <div class="featured-courses__header-column">
          <base-sub-heading class="featured-courses__header-sub-heading">
            <span v-html="subTitle"/>
          </base-sub-heading>

          <base-heading class="featured-courses__header-heading" bold="bold">
            <span v-html="title"/>
          </base-heading>
        </div>
      </header>

      <!-- <main class="featured-courses__inner" v-if="courses.length">
        <base-tabs
          :label="$t('featuredCourses.sort')"
          navigation-align="right"
          v-model="activeTab"
        >
          <base-tab-item
            :label="$t('featuredCourses.latest')"
            name="newst"
          >
            <courses-listing
              :courses="courses"
              class="featured-courses__list"
            />
          </base-tab-item>
        </base-tabs>
      </main> -->

      <main class="featured-courses__inner" v-if="!isLoading">
        <courses-listing
          :courses="courses"
          :ownedCoursesIds="ownedCoursesIds"
          class="featured-courses__list featured-courses__list--alone"
          v-if="courses.length"
        />

        <p class="featured-courses__no-data" v-else>
          {{ $t("no data") }}
        </p>
      </main>
      <div class="featured-courses__loading" v-else>
        <loader/>
        <p class="main-preloader__text">
          {{ $t('loading.text') }}
        </p>
      </div>

      <footer class="featured-courses__bottom">
        <p class="featured-courses__bottom-text">
          {{ bottomText }}
        </p>

        <base-button
          type="text"
          to="/courses"
          icon="arrow-right"
          class="featured-courses__bottom-button"
        >
          {{ $t("featuredCourses.allCourses") }}
        </base-button>
      </footer>
    </div>
  </section>
</template>

<script>
import course from '@/api/services/course';
import { mapGetters } from 'vuex';

export default {
  name: 'SectionFeaturedCourses',
  data: () => ({
    activeTab: 'newst',
    courses: [],
    isLoading: false,
    ownedCoursesIds: [],
  }),
  computed: {
    ...mapGetters({
      isLogged: 'auth/isLogged',
    }),
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    subTitle: {
      type: String,
      required: false,
    },
    bottomText: {
      type: String,
      required: false,
    },
  },
  async created() {
    this.isLoading = true;

    if (this.isLogged) {
      const ownedCoursesResponse = await course.getOwnedCourses();
      this.ownedCoursesIds = ownedCoursesResponse.data.filter((x) => x.paymentStatus === 'paid').map((x) => x.courseId);
    }

    try {
      const response = await this.$prismic.client.query(this.$prismic.Predicates.at('document.type', 'featured_course'));
      const slugs = [];
      for (let i = 1; i <= 6; i += 1) {
        // eslint-disable-next-line no-prototype-builtins
        if (response.results[0].data[`link_${i}`].hasOwnProperty('url')) slugs.push((response.results[0].data[`link_${i}`].url).toString().split('/courses/')[1]);
      }
      const featuredCourses = [];
      // eslint-disable-next-line no-await-in-loop,no-restricted-syntax
      for (const slug of slugs) {
        try {
          // eslint-disable-next-line no-await-in-loop
          featuredCourses.push((await course.getSingleCourse(slug)).data);
          // eslint-disable-next-line no-empty
        } catch (err) {
        }
      }
      const { data } = await course.getCourses({
        skip: 0,
        limit: 8,
      });
      const featuredCoursesIds = new Set(featuredCourses.map((d) => d.id));
      this.courses = featuredCourses.concat(data.filter((d) => !featuredCoursesIds.has(d.id))).splice(0, 8);
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss" src="./SectionFeaturedCourses.scss"/>
