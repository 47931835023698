<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="14" viewBox="0 0 19 14" class="svg-tick">
    <path
      class="svg-tick__path"
      :fill="fill"
      d="M15.35 1.01L7.057 9.32 3.814 6.059a1.76 1.76 0 1 0-2.49 2.49l4.488 4.488a1.753 1.753 0 0 0 2.49 0l9.537-9.538a1.76 1.76 0 1 0-2.49-2.49z"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgTick',
  props: {
    fill: {
      type: String,
      required: false,
      default: '#fff',
    },
  },
};
</script>
<style lang="scss" scoped src="./SvgTick.scss" />
