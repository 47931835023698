<template>
  <div>
    <validation-observer slim v-slot="{ handleSubmit }">
      <form class="join-designers-form"
            @submit.prevent="handleSubmit(onSubmit)">
        <div class="join-designers-form__name">
          <div>
            <base-input
              class="join-designers-form__input join-designers-form__input-name"
              v-model="user.firstName"
              :label="firstName.label"
              :placeholder="firstName.placeholder"
              rules="required"
              :name="firstName.label"
            />
          </div>

          <div>
            <base-input
              class="join-designers-form__input join-designers-form__input-name"
              v-model="user.lastName"
              :label="lastName.label"
              :placeholder="lastName.placeholder"
              rules="required"
              :name="lastName.label"
            />
          </div>
        </div>

        <div class="join-designers-form__name">
          <div>
            <base-input
              class="join-designers-form__input"
              v-model="user.email"
              :label="email.label"
              :placeholder="email.placeholder"
              rules="email|required"
              :name="email.label"
            />
          </div>
          <div>
            <base-input
              class="join-designers-form__input"
              v-model="user.instagram"
              label="Instagram"
              placeholder="Instagram"
              rules="required"
              name="Instagram"
            />
          </div>
        </div>

        <base-checkbox
          required
          v-model="isChecked"
          :name="agreements"
          center
          class="sign-up-form__checkbox"
        >
          <p style="font-size: 12px;" v-if="isConsentExpanded">
            I give my consent for sending commercial information by electronic
            means for direct marketing purposes. Your data will be processed by
            the company Happiness A&M Ltd, Third Floor, 12 Groveland Court,
            London EC4M 9EH, United Kingdom. You have the right to
            access their personal data and the right to rectify, delete, limit
            the processing, the right to data transfer, the right to object, the
            right to withdraw consent at any time. Additional explanations
            regarding the protection of personal data are contained in the
            “Privacy Policy”. <a href="#" @click="isConsentExpanded=!isConsentExpanded" style="color: blue; font-size: 12px;">← collapse</a></p>
          <p style="font-size: 12px;" v-if="!isConsentExpanded">
            I give my consent for sending commercial information by electronic
            means for direct marketing purposes. Your data will be processed by
            the company Happiness A&M Ltd, Third Floor, 12 Groveland Court,
            London, EC4M 9EH, United Kingdom. You have the right... <a href="#" @click="isConsentExpanded=!isConsentExpanded" style="color: blue; font-size: 12px;">expand →</a>
          </p>
        </base-checkbox>

        <base-button
          nativeType="submit"
          class="join-designers-form__submit"
          :class="centerButton && 'join-designers-form__submit--center'"
          :is-loading="isLoading"
        >
          {{ submit }}
        </base-button>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import support from '@/api/services/support';
import marketing from '@/api/services/marketing';

export default {
  name: 'JoinDesignersForm',
  data: () => ({
    user: {
      firstName: '',
      lastName: '',
      email: '',
      instagram: '',
    },
    isConsentExpanded: false,
    isChecked: false,
    isLoading: false,
  }),
  props: {
    firstName: {
      type: Object,
      required: true,
    },
    lastName: {
      type: Object,
      required: true,
    },
    email: {
      type: Object,
      required: true,
    },
    password: {
      type: Object,
      required: true,
    },
    terms: {
      type: Array,
      required: true,
    },
    adult: {
      type: String,
      required: true,
    },
    age: {
      type: String,
      required: true,
    },
    agreements: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    newsletter: {
      type: String,
      required: true,
    },
    submit: {
      type: String,
      required: true,
    },
    privacyPolicy: {
      type: String,
      required: true,
    },
    centerButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;

      try {
        const ticket = {
          request: {
            requester: {
              name: `${this.user.firstName} ${this.user.lastName}`,
              email: this.user.email.toLowerCase(),
            },
            subject: `Edunails Designers - ${this.user.firstName} ${this.user.lastName}`,
            comment: {
              body: `Edunails Designers request. Instagram name: ${this.user.instagram} Email: ${this.user.email}`,
            },
          },
        };

        if (this.isChecked) {
          marketing.subscribe(this.user.email.toLowerCase());
        }
        try {
          this.$analytics.fbq.event('DesignersRegistration');
          await support.sendTicket(ticket);
          await marketing.sendDesignersMailConfirmation(this.user.email, this.user.firstName);
          this.$store.dispatch('notification/spawn', {
            type: 'success',
            title: 'Your message has been sent',
            text: 'You\'ll be notified via email',
          });
        } catch (err) {
          this.$store.dispatch('notification/spawn', {
            errorMessage: err.response.data.message,
            type: 'error',
          });
        } finally {
          this.isLoading = false;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" src="./JoinDesignersForm.scss"/>
