<template>
  <li class="checkout-item">
    <div class="checkout-item__left">
      <p class="checkout-item__no">{{ item.index + 1 }}.</p>
      <course-thumbnail
        v-bind="item"
        :additionalDetails="`${$t('level')} ${item.difficulty}`"
        class="checkout-item__thumbnail"
      />
    </div>
    <div class="checkout-item__right">
      <base-price :price="item.price" :currency="item.currency"
                  class="checkout-item__price"
                  :promoPrice="item.extraPrice"/>
      <button
        class="base-add-cart"
        :title="$t('cart.add')"
        :aria-label="$t('cart.add')"
        @click="addToCart()"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="18.182" height="19.902" viewBox="0 0 18.182 19.902">
          <g id="Group_11" data-name="Group 11" transform="translate(1.004 1)">
            <path id="Path_8" data-name="Path 8" d="M545.012,479.214h9.663a3.255,3.255,0,0,0,3.23-3.659l-.89-7.12a3.255,3.255,0,0,0-3.23-2.85H545.9a3.254,3.254,0,0,0-3.23,2.85l-.89,7.12A3.254,3.254,0,0,0,545.012,479.214Z" transform="translate(-541.757 -461.312)" fill="rgba(0,0,0,0)" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <path id="Rectangle_9" data-name="Rectangle 9" d="M3.14,0h.534a3.14,3.14,0,0,1,3.14,3.14V4.273a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V3.14A3.14,3.14,0,0,1,3.14,0Z" transform="translate(4.679)" fill="rgba(0,0,0,0)" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
          </g>
        </svg>
      </button>
    </div>
  </li>
</template>

<script>
export default {
  name: 'CheckoutItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    cartList: [],
  },
  methods: {
    addToCart() {
      this.$store.dispatch('cart/addToCart', this.item);
    },
  },
};
</script>
<style lang="scss" scoped src="./ExtraItem.scss"/>
