<template>
  <div class="user-card">
    <figure class="user-card__avatar">
      <router-link :to="`/instructor/${slug}`">
        <img
          :src="imageLink"
          v-if="avatar"
          alt=""
          class="user-card__avatar-img"
        />
      </router-link>
    </figure>
    <router-link :to="`/instructor/${slug}`">
      <p class="user-card__name">{{ firstName }} {{ lastName }}</p>
    </router-link>
    <slot></slot>
  </div>
</template>

<script>
import { getImageLink } from '@/helpers/';

export default {
  name: 'UserCard',
  data: () => ({
    userName: '',
  }),
  props: {
    name: {
      type: String,
      required: false,
      default: '',
    },
    firstName: {
      type: String,
      required: false,
      default: '',
    },
    lastName: {
      type: String,
      required: false,
      default: '',
    },
    avatar: {
      type: String,
      required: false,
      default: '',
    },
    slug: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    imageLink() {
      return getImageLink(this.avatar);
    },
  },
  created() {
    if (!this.name) {
      this.userName = this.$t('sectionInfo.userName');
    } else {
      this.userName = this.name;
    }
  },
};
</script>

<style lang="scss" src="./UserCard.scss" />
