<template>
  <validation-provider
    v-slot="{ errors }"
    :rules="rules"
    :name="name"
    :vid="vid"
    tag="div"
    class="base-textarea"
    mode="eager"
  >
    <label v-if="label" class="base-textarea__label" :for="name">
      {{ label }}
    </label>
    <textarea
      class="base-textarea__inner"
      rules="required"
      :id="name"
      :value="value"
      :disabled="disabled"
      :placeholder="placeholder"
      :class="[
        errors[0] ? 'base-textarea__inner--error' : ''
      ]"
      @input="updateValue"
      @change="updateValue"
      @blur="$emit('blur')"
      @focus="$emit('focus')"
      :rows="rows"
      :cols="cols"
    />
    <transition name="page-fade">
      <span class="base-textarea__error" v-if="errors[0]">
        {{ errors[0] }}
      </span>
    </transition>
  </validation-provider>
</template>

<script>
export default {
  name: 'BaseTextarea',
  props: {
    value: {
      type: [String, Number],
      required: false,
    },
    name: {
      type: String,
      required: false,
      default: 'Field',
    },
    rules: {
      type: [String, Object],
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    vid: {
      type: String,
      required: false,
      default: '',
    },
    rows: {
      type: String,
      required: false,
      default: '',
    },
    cols: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    updateValue(event) {
      this.$emit('input', event.target.value);
    },
  },
};
</script>
<style lang="scss" scoped src="./BaseTextarea.scss" />
