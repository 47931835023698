<template>
  <div
    class="base-dropdown"
    :class="transparent ? 'base-dropdown--transparent' : ''"
    @keyup.esc="close()"
    @keydown.down.prevent="handleKeyDown()"
    @keydown.up.prevent="handleKeyDown()"
  >
    <label class="base-dropdown__label" :for="dropdownId">
      {{ label }}
    </label>
    <button
      type="button"
      class="base-dropdown__inner"
      :class="[
        transparent ? 'base-dropdown__inner--transparent' : '',
        isActive ? 'base-dropdown__inner--active' : ''
      ]"
      @click="(isActive = !isActive), $emit('click')"
      aria-controls="base-dropdown__content"
      :aria-label="helpfulText"
      :title="helpfulText"
      :aria-expanded="isActive ? 'true' : 'false'"
      aria-haspopup="listbox"
      :id="dropdownId"
    >
      <template v-if="hasHeaderSlot">
        <slot name="header"></slot>
      </template>
      <template v-else>
        {{ placeholder }}
      </template>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11.572"
        height="6.894"
        viewBox="0 0 11.572 6.894"
        class="base-dropdown__arrow"
        :style="{ transform }"
      >
        <path
          id="angle-right"
          d="M15.447,11.76,10.767,7.081A1.1,1.1,0,1,0,9.2,8.637l3.907,3.907L9.2,16.45a1.1,1.1,0,0,0,1.567,1.556l4.679-4.679a1.1,1.1,0,0,0,0-1.567Z"
          transform="translate(-6.754 15.773) rotate(-90)"
          fill="#132b50"
        />
      </svg>
    </button>
    <transition name="dropdown-fade">
      <ul
        class="base-dropdown__content"
        v-if="isActive && hasSlot"
        aria-label="Kontent listy"
        role="listbox"
        tabindex="-1"
      >
        <slot name="items"></slot>
      </ul>
      <ul
        class="base-dropdown__content"
        v-else-if="isActive && !hasSlot"
        aria-label="Kontent listy"
      >
        <base-dropdown-item @click="close()">
          {{ $t("noContent") }}
        </base-dropdown-item>
      </ul>
    </transition>
  </div>
</template>

<script>
import { uuid } from '@/helpers';

export default {
  name: 'BaseDropdown',
  provide() {
    return {
      dropdown: this,
    };
  },
  data: () => ({
    isActive: false,
  }),
  props: {
    text: {
      type: String,
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    hasBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    helpfulText: {
      type: String,
      required: false,
      default: 'Otwórz listę',
    },
    transparent: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    placeholder() {
      return this.text || this.$t('select');
    },
    dropdownId() {
      return uuid();
    },
    hasSlot() {
      if (this.$slots.items) {
        return this.$slots.items.length > 0;
      }

      return false;
    },
    hasHeaderSlot() {
      if (this.$slots) {
        if (this.$slots.header) {
          return true;
        }
      }

      return false;
    },
    transform() {
      return this.isActive ? 'rotate(180deg)' : 'rotate(0deg)';
    },
  },
  methods: {
    close() {
      this.isActive = false;
    },
    handleOutsideClick({ target }) {
      const isClickInside = this.$el.contains(target);

      if (!isClickInside) {
        this.close();
      }
    },
    handleKeyDown() {
      this.isActive ? this.$emit('keydown') : (this.isActive = true);
    },
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleOutsideClick);
  },
};
</script>

<style lang="scss" src="./BaseDropdown.scss" />
