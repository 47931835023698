import request from '@/api/';

export default {
  subscribe(email) {
    return request.post(`marketing/subscribe/${email}`);
  },
  unsubscribe(email) {
    return request.post(`marketing/subscribe/${email}`);
  },
  getSubscription(email) {
    return request.get(`marketing/subscribe/${email}`);
  },
  sendDesignersMailConfirmation(email, firstName) {
    return request.post(`marketing/subscribe/${email}/landing/${firstName}`);
  },
};
