<template>
  <aside class="main-cart">
    <header class="main-cart__header">
      <h3 class="main-cart__header-title">
        {{ $t('cart.name') }}
      </h3>
      <base-close-icon
        @click="closeCart()"
      />
    </header>
    <div class="main-cart__content">
      <ul class="main-cart__list" v-if="cartItems.length > 0">
        <cart-course-tile
          v-for="course in cartItems"
          :key="course.id"
          :course="course"
          @click="closeCart()"
        />
      </ul>
      <p class="main-cart__list-empty" v-else>
        {{ $t('cart.empty') }}
      </p>
    </div>
    <footer class="main-cart__footer">
      <div class="main-cart__footer-row main-cart__footer-row--info">
        <p class="main-cart__footer-cart-info">
          {{ $t('cart.value') }}
        </p>
        <base-price :price="cartTotal"/>
      </div>
      <div class="main-cart__footer-row">
        <base-button
          @click="clearCart()"
          type="text"
        >
          {{ $t('cart.clear') }}
        </base-button>
        <base-button
          @click.native="closeCart()"
          to="/checkout"
        >
          {{ $t('cart.payment') }}
        </base-button>
      </div>
    </footer>
  </aside>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { escKey } from '@/mixins/escKey';

export default {
  name: 'MainCart',
  mixins: [
    escKey(),
  ],
  computed: {
    ...mapState({
      cartItems: (state) => state.cart.cartList,
    }),
    ...mapGetters({
      cartTotal: 'cart/cartTotal',
    }),
  },
  methods: {
    closeCart() {
      this.$store.dispatch('ui/setCart', false);
    },
    clearCart() {
      this.$store.dispatch('cart/clearCart');
    },
  },
};
</script>

<style lang="scss" scoped src="./MainCart.scss" />
