<template>
  <button
    class="base-add-wish"
    :class="[
      isOnWishList ? 'base-add-wish--active' : '',
    ]"
    @click="$emit('click')"
    @focus="$emit('focus')"
  >
    <svg
      class="base-add-wish__inner"
      xmlns="http://www.w3.org/2000/svg"
      width="19.713"
      height="18.059"
      viewBox="0 0 19.713 18.059"
    >
      <g id="Group_15" data-name="Group 15" transform="translate(-3.389 -4.216)">
        <path
          id="Path_10"
          data-name="Path 10"
          d="M205.975,527.17c0,5.821-5.7,9.709-8.405,10.914-.266.119-.5.21-.7.294-.2-.084-.434-.174-.7-.294-2.7-1.205-8.407-5.093-8.407-10.914a5.145,5.145,0,0,1,4.9-5.351,4.828,4.828,0,0,1,4.2,2.6,4.831,4.831,0,0,1,4.2-2.6A5.14,5.14,0,0,1,205.975,527.17Z"
          transform="translate(-183.623 -516.853)"
          fill="rgba(0,0,0,0)"
          stroke="#b4b4b4"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
      </g>
    </svg>
  </button>
</template>

<script>
export default {
  name: 'BaseAddWish',
  props: {
    isOnWishList: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" src="./BaseAddWish.scss" />
