var render = function render(){var _vm=this,_c=_vm._self._c;return _c('upload-file-logic',{staticClass:"upload-avatar",attrs:{"image":_vm.image,"rules":_vm.rules,"name":_vm.name},on:{"upload":function($event){return _vm.$emit('upload', $event)},"error":function($event){return _vm.$emit('error')},"clear-error":function($event){return _vm.$emit('clear-error')}},scopedSlots:_vm._u([{key:"default",fn:function({
    handleUpload,
    isDragOver,
    computedImage,
    handleDelete,
    isUploading,
    isError
  }){return [_c('label',{staticClass:"upload-avatar__label",class:[
      computedImage ? 'upload-avatar__label--uploaded' : '',
      isDragOver ? 'upload-avatar__label--is-over' : ''
    ],attrs:{"for":_vm.name,"title":_vm.helpfulText}},[_c('user-avatar',{staticClass:"upload-avatar__image",attrs:{"user":_vm.user,"size":"large","localImage":computedImage}}),(isUploading)?_c('loader',{staticClass:"upload-avatar__loader",attrs:{"color":"blue","thin":""}}):_vm._e(),_c('input',{staticClass:"upload-avatar__upload",attrs:{"type":"file","id":_vm.name,"name":_vm.name,"accept":"image/png, image/jpeg","disabled":computedImage},on:{"change":function($event){return handleUpload($event.target)}}}),_c('transition',{attrs:{"mode":"out-in","name":"page-fade"}},[(!computedImage)?_c('p',{staticClass:"upload-avatar__help",class:isDragOver ? 'upload-avatar__help--is-over' : ''},[_vm._v(" "+_vm._s(_vm.label)+" "),_c('img',{staticClass:"upload-avatar__help-icon",attrs:{"src":"/assets/icons/upload.svg","alt":""}})]):_vm._e(),(computedImage)?_c('delete-button',{staticClass:"upload-avatar__delete",on:{"click":handleDelete}},[_c('span',[_vm._v(_vm._s(_vm.$t("uploadFile.label")))])]):_vm._e()],1),_c('transition',{attrs:{"mode":"out-in","name":"page-fade"}},[(isError)?_c('p',{staticClass:"upload-file__error upload-avatar__error"},[_vm._v(" "+_vm._s(_vm.$t("uploadFile.error"))+" ")]):_vm._e()])],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }