import request from '@/api/';

export default {
  addResource(payload) {
    return request.post('resources', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  getResource(id) {
    return request.get(`resources/${id}`);
  },
  deleteResource(id) {
    return request.delete(`resources/${id}`);
  },
};
