import i18n from '@/i18n';
import { setItemToLocalStorage } from '@/helpers';
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    cartList: [],
    promoCode: null,
  },
  getters: {
    lengthOfCart(state) {
      return state.cartList.length ? state.cartList.length : 0;
    },
    cartTotal(state) {
      let sum = 0;
      if (state.cartList.length === 0) {
        sum = 0;
      }

      if (state.cartList.length > 0) {
        sum = state.cartList
          .map((course) => {
            if (state.promoCode?.toLowerCase() === 'marcelina50' && course.authorId === '629c573ed9f77f0001227afc') {
              course.promotionValue = 50;
            }
            else if (course.promotionEnd == null || course.promotionValue == null || course.promotionValue === 0) {
              course.promotionValue = 0;
            } else {
              const local = new Date();
              const now = new Date(local.getTime() + local.getTimezoneOffset() * 60000);
              const diff = (Date.parse(course.promotionEnd) - now);
              if (diff <= 0) course.promotionValue = 0;
            }
            return course;
          })
          .reduce((accumulator, item) => accumulator + ((item.extra && state.cartList.filter((e) => !e.extra).length > 0) ? item.extraPrice : item.price * ((100 - item.promotionValue) / 100)), 0);
      }

      return sum;
    },
  },
  mutations: {
    ADD_TO_CART(state, payload) {
      if (state.cartList.filter((e) => e.id === payload.id).length > 0) {
        return;
      }
      state.cartList.push(payload);
      setItemToLocalStorage('cartItems', JSON.stringify(state.cartList));
      Vue.analytics.fbq.event('AddToCart');
    },
    SET_PROMO_CODE(state, payload) {
      state.promoCode = payload;
    },
    SET_CART(state) {
      const cartItems = JSON.parse(localStorage.getItem('cartItems')) ? JSON.parse(localStorage.getItem('cartItems')) : [];
      state.cartList = cartItems;
    },
    CLEAR_CART(state) {
      state.cartList = [];
      setItemToLocalStorage('cartItems', null);
    },
    DELETE_CART_ITEM(state, payload) {
      state.cartList = state.cartList.filter((item) => item.id !== payload);
      setItemToLocalStorage('cartItems', JSON.stringify(state.cartList));
    },
  },
  actions: {
    addToCart({ commit, dispatch }, payload) {
      commit('ADD_TO_CART', payload);

      dispatch('notification/spawn', {
        type: 'success',
        title: i18n.t('apiHandler.success.title'),
        text: i18n.t('cart.added'),
      }, { root: true });
    },
    getCartItems({ commit }) {
      commit('SET_CART');
    },
    clearCart({ commit }) {
      commit('CLEAR_CART');
    },
    deleteCartItem({ commit }, payload) {
      commit('DELETE_CART_ITEM', payload);
    },
    setPromoCode({ commit }, payload) {
      commit('SET_PROMO_CODE', payload);
    },
  },
};
