var render = function render(){var _vm=this,_c=_vm._self._c;return _c('upload-file-logic',{attrs:{"image":_vm.image,"rules":_vm.rules,"name":_vm.name,"veeValidate":_vm.veeValidate},on:{"upload":function($event){return _vm.$emit('upload', $event)},"uploaded":function($event){return _vm.$emit('uploaded')},"error":function($event){return _vm.$emit('error')},"clear-error":function($event){return _vm.$emit('clear-error')}},scopedSlots:_vm._u([{key:"default",fn:function({
    handleUpload,
    isDragOver,
    computedImage,
    handleDelete,
    errors,
    isUploading
  }){return [_c('div',{staticClass:"upload-portfolio-image"},[_c('label',{staticClass:"upload-portfolio-image__label",class:[
        computedImage ? 'upload-portfolio-image__label--uploaded' : '',
        isDragOver ? 'upload-portfolio-image__label--is-over' : ''
      ],attrs:{"for":_vm.name,"title":_vm.helpfulText}},[(!isUploading)?_c('img',{staticClass:"upload-portfolio-image__icon",attrs:{"src":'/assets/icons/image.svg',"alt":_vm.$t('uploadFile.addFiles'),"title":_vm.$t('uploadFile.addFiles')}}):_c('loader',{staticClass:"upload-portfolio-image__icon",attrs:{"color":"gray"}}),_c('input',{staticClass:"upload-portfolio-image__upload",attrs:{"type":"file","id":_vm.name,"name":_vm.name,"accept":"image/png, image/jpeg"},on:{"change":function($event){return handleUpload($event.target)}}}),(!computedImage)?_c('p',{staticClass:"upload-portfolio-image__help",class:isDragOver ? 'upload-portfolio-image__help--is-over' : ''},[_vm._v(" "+_vm._s(isDragOver ? _vm.dropText : _vm.label)+" ")]):_vm._e()],1),_c('transition',{attrs:{"mode":"out-in","name":"page-fade"}},[(computedImage)?_c('img',{staticClass:"upload-portfolio-image__image",attrs:{"src":computedImage,"alt":""}}):_vm._e()]),_c('transition',{attrs:{"mode":"out-in","name":"page-fade"}},[(computedImage)?_c('base-button',{staticClass:"upload-portfolio-image__delete",attrs:{"type":"text"},on:{"click":handleDelete}},[_c('span',[_vm._v(_vm._s(_vm.$t("uploadFile.label")))]),_c('svg-trash',{staticClass:"upload-portfolio-image__delete-icon",attrs:{"fill":"#A0A4A8"}})],1):_vm._e()],1),_c('transition',{attrs:{"name":"page-fade"},on:{"enter":function($event){return _vm.$emit('error', _vm.name)},"leave":function($event){return _vm.$emit('clear-error', _vm.name)}}},[(errors[0])?_c('span',{staticClass:"base-input__error upload-portfolio-image__error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }